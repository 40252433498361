<!-- Hero Start -->
<section
    class="bg-half-170 d-table w-100 background-image"
    id="home"
>
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h2 class="text-white title-dark">
                        Flucht- & Rettungsplan Rechner
                    </h2>
                    <p class="text-white-50 para-desc mb-0 mx-auto">
                        Bereit für maximale Sicherheit? Unser Flucht- und
                        Rettungsplan Rechner ist Ihr Schlüssel zur Vorbereitung
                        auf das Unerwartete. Egal welche Gefahr droht, mit
                        diesem innovativen Tool erstellen Sie im Handumdrehen
                        maßgeschneiderte Evakuierungsstrategien. Sicherheit hat
                        einen neuen Namen – Ihren.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav
                aria-label="breadcrumb"
                class="d-inline-block"
            >
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">BENSER GmbH</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/services">Leistungen</a>
                    </li>
                    <li
                        class="breadcrumb-item active"
                        aria-current="page"
                    >
                        Flucht- & Rettungsplan Rechner
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title pb-2 text-center">
                            <h4 class="title mb-4">
                                <img
                                    class="banner-new"
                                    src="../../../../assets/images/freepik/banner_354.png"
                                    alt="Banner-New"
                                />
                                Endecken Sie unseren neuen Rechner
                            </h4>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-10">
                <div class="row justify-content-center">
                    <div class="text-center">
                        <p class="text-muted mx-auto mb-0">
                            In einer Welt voller Unvorhersehbarkeit ist es
                            entscheidend, auf jede Situation vorbereitet zu
                            sein. Ein Flucht- und Rettungsplan kann der
                            Schlüssel zur Sicherheit in Notfällen sein. Unser
                            Flucht- und Rettungsplan Rechner ist ein
                            Kalkulations-Tool, das Ihnen dabei hilft, Ihre erste
                            Kosteneinschätzung für verschiedene Szenarien zu
                            erstellen.
                        </p>
                        <p class="text-muted mx-auto mb-0">
                            Ihre Sicherheit ist uns wichtig, und dieser Rechner
                            ist Ihr persönlicher Begleiter auf dem Weg zu einem
                            sicheren Ausweg.
                        </p>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row d-flex justify-content-center gap-3 my-5">
            <div
                class="card card-image-1"
                [ngClass]="styleCard1 ? 'card-image-active' : ''"
                (click)="showCalculator = true; toggleStyleCard(1)"
            >
                <div>Feuerwehrpläne</div>
            </div>
            <div
                class="card card-image-2"
                [ngClass]="styleCard2 ? 'card-image-active' : ''"
                (click)="showCalculator = true; toggleStyleCard(2)"
            >
                <div>Flucht- & Rettungspläne</div>
            </div>
        </div>
        <!--end row-->

        <div
            *ngIf="!showCalculator"
            class="row justify-content-center my-5 display-title"
        >
            Bitte wählen Sie die Art der Pläne aus, die Sie benötigen.
        </div>
        <!--end row-->

        <div *ngIf="showCalculator">
            <div class="row justify-content-center mb-4 rounded price-badge">
                <div
                    class="col-lg-8 col-md-10 rounded d-flex flex-direction justify-content-center"
                >
                    <div
                        class="display-4"
                        [countUp]="result"
                        [duration]="duration"
                    ></div>
                    <div class="display-4">€</div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <mat-stepper
                        orientation="vertical"
                        [linear]="isLinear"
                        #stepper
                    >
                        <mat-step
                            [stepControl]="firstFormGroup"
                            errorMessage="Grundfläche ist erforderlich."
                        >
                            <form [formGroup]="firstFormGroup">
                                <ng-template matStepLabel
                                    >Angabe der Grunddaten</ng-template
                                >
                                <div class="mt-4">
                                    Geben Sie zunächst die Objektfläche in
                                    Quadratmetern an?
                                </div>
                                <div class="form-icon position-relative mt-2">
                                    <i-feather class="fea icon-sm icons"
                                        >m²</i-feather
                                    >
                                    <input
                                        type="number"
                                        name="object-area"
                                        id="object-area"
                                        class="form-control ps-5 rounded"
                                        required
                                        formControlName="objectArea"
                                        (change)="calculateResult()"
                                        onclick="this.select()"
                                        min="1"
                                        max="1000000"
                                        placeholder="Objektfläche"
                                    />
                                </div>

                                <div class="mt-4">
                                    Wie viele Stockwerke hat das Gebäude?
                                </div>
                                <div class="form-icon position-relative my-2">
                                    <i-feather
                                        name="layers"
                                        class="fea icon-sm icons"
                                    ></i-feather>
                                    <input
                                        type="number"
                                        name="floor-number"
                                        id="floor-number"
                                        #floorNumberElement
                                        class="form-control ps-5 rounded"
                                        required
                                        formControlName="floorNumber"
                                        (change)="calculateResult()"
                                        onclick="this.select()"
                                        placeholder="Geschosszahl"
                                        min="1"
                                        max="100"
                                    />
                                </div>
                                <div class="mt-4">
                                    Sind digitale Bauwerkspläne vorhanden?
                                </div>
                                <div class="d-flex justify-content-center my-2">
                                    <div
                                        class="btn-group"
                                        role="group"
                                        aria-label="Digital building plans available group"
                                    >
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="btnradio"
                                            id="btnradio1"
                                            autocomplete="off"
                                            [checked]="
                                                showBuildingPlanGroup === true
                                            "
                                            (change)="
                                                showBuildingPlanGroup = true
                                            "
                                        />
                                        <label
                                            class="btn btn-outline-secondary"
                                            for="btnradio1"
                                            >Ja</label
                                        >

                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="btnradio"
                                            id="btnradio2"
                                            autocomplete="off"
                                            [checked]="
                                                showBuildingPlanGroup === false
                                            "
                                            (change)="
                                                showBuildingPlanGroup = false
                                            "
                                        />
                                        <label
                                            class="btn btn-outline-secondary"
                                            for="btnradio2"
                                            >Nein</label
                                        >
                                    </div>
                                </div>
                                <div *ngIf="showBuildingPlanGroup">
                                    <div class="mt-4">
                                        Welche Art von Plänen haben Sie?
                                    </div>
                                    <div
                                        class="d-flex justify-content-center my-2"
                                    >
                                        <div
                                            class="btn-group"
                                            role="group"
                                            aria-label="Digital building plans group"
                                        >
                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="btnradio1"
                                                id="btnradio3"
                                                autocomplete="off"
                                                [checked]="
                                                    kindOfDigitalPlan === 'DWG'
                                                "
                                                (change)="
                                                    selectDigitalPlan('DWG')
                                                "
                                            />
                                            <label
                                                class="btn btn-outline-secondary"
                                                for="btnradio3"
                                                >DWG</label
                                            >

                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="btnradio1"
                                                id="btnradio4"
                                                autocomplete="off"
                                                [checked]="
                                                    kindOfDigitalPlan === 'PDF'
                                                "
                                                (change)="
                                                    selectDigitalPlan('PDF')
                                                "
                                            />
                                            <label
                                                class="btn btn-outline-secondary"
                                                for="btnradio4"
                                                >PDF</label
                                            >

                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="btnradio1"
                                                id="btnradio5"
                                                autocomplete="off"
                                                [checked]="
                                                    kindOfDigitalPlan === 'Scan'
                                                "
                                                (change)="
                                                    selectDigitalPlan('Scan')
                                                "
                                            />
                                            <label
                                                class="btn btn-outline-secondary"
                                                for="btnradio5"
                                                >Scan</label
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showBuildingPlanGroup === false">
                                    <div
                                        class="alert alert-info"
                                        role="alert"
                                    >
                                        <h4 class="alert-heading">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="currentColor"
                                                class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                                viewBox="0 0 16 16"
                                                role="img"
                                                aria-label="Warning:"
                                            >
                                                <path
                                                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                                                />
                                            </svg>
                                            Sehr geehrte Kunden,
                                        </h4>
                                        <p>
                                            leider können wir Ihnen ohne
                                            digitale Bauwerkspläne keine Kosten
                                            für die Erstellung eines Flucht- und
                                            Rettungsplans nennen. Die Erstellung
                                            eines Flucht- und Rettungsplans ist
                                            ein komplexer Prozess, der von
                                            vielen Faktoren abhängt.
                                            <br /><br />
                                            Wir möchten Ihnen gerne ein
                                            individuelles Angebot erstellen, das
                                            auf Ihre Bedürfnisse zugeschnitten
                                            ist.
                                        </p>
                                        <hr />
                                        <p class="mb-0">
                                            Bitte kontaktieren Sie uns
                                            telefonisch oder per E-Mail, um ein
                                            Angebot zu erhalten.
                                        </p>
                                        <a
                                            routerLink="/contact-details"
                                            href="javascript: void(0)"
                                            class="btn btn-info my-4"
                                        >
                                            <i class="mdi mdi-phone"></i>
                                            Kontaktieren Sie uns
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        [disabled]="
                                            firstFormGroup.invalid ||
                                            showBuildingPlanGroup ===
                                                undefined ||
                                            showBuildingPlanGroup === false ||
                                            kindOfDigitalPlan === undefined
                                        "
                                        [ngClass]="
                                            firstFormGroup.invalid ||
                                            showBuildingPlanGroup ===
                                                undefined ||
                                            showBuildingPlanGroup === false ||
                                            kindOfDigitalPlan === undefined
                                                ? 'cursor-not-allowed'
                                                : ''
                                        "
                                        type="button"
                                        class="btn btn-outline-primary mt-4"
                                        matStepperNext
                                    >
                                        Weiter
                                    </button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step
                            [stepControl]="secondFormGroup"
                            errorMessage="Adresse ist required."
                        >
                            <form [formGroup]="secondFormGroup">
                                <ng-template matStepLabel
                                    >Auswahl der Zusatzleistungen</ng-template
                                >

                                <div class="mt-4">
                                    Wie viele Pläne je Geschoss werden benötigt?
                                </div>
                                <div class="form-icon position-relative my-2">
                                    <i-feather
                                        name="hash"
                                        class="fea icon-sm icons"
                                    ></i-feather>
                                    <input
                                        type="number"
                                        name="object-area"
                                        id="object-area"
                                        class="form-control ps-5 rounded"
                                        required
                                        formControlName="numberOfPlans"
                                        (change)="calculateResult()"
                                        onclick="this.select()"
                                        min="1"
                                        max="1000000"
                                        placeholder="Anzahl je Geschoss"
                                    />
                                </div>
                                <div
                                    class="alert alert-secondary mt-4"
                                    role="alert"
                                >
                                    <h4 class="alert-heading">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="currentColor"
                                            class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                            viewBox="0 0 16 16"
                                            role="img"
                                            aria-label="Warning:"
                                        >
                                            <path
                                                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                                            />
                                        </svg>
                                        Hinweis!
                                    </h4>
                                    <p>
                                        Zentrale Bereiche, Treppenräume und
                                        Bereiche mit viel Publikumsverkehr
                                        sollen einen Plan vorhalten.
                                        <br />
                                        (vgl. ArbstättV §4, ASR 2.3)
                                    </p>
                                </div>

                                <div class="mt-4">
                                    Benötigen Sie einen Abgleich der Daten?
                                </div>
                                <div class="d-flex justify-content-center my-2">
                                    <div
                                        class="btn-group"
                                        role="group"
                                        aria-label="Digital building plans group"
                                    >
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="btnradio2"
                                            id="btnradio6"
                                            autocomplete="off"
                                            [checked]="
                                                isDataSynchronizationDesired ===
                                                true
                                            "
                                            (change)="
                                                isDataSynchronizationDesired = true;
                                                calculateResult()
                                            "
                                        />
                                        <label
                                            class="btn btn-outline-secondary"
                                            for="btnradio6"
                                            >Ja</label
                                        >

                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="btnradio2"
                                            id="btnradio7"
                                            autocomplete="off"
                                            [checked]="
                                                isDataSynchronizationDesired ===
                                                false
                                            "
                                            (change)="
                                                isDataSynchronizationDesired = false;
                                                calculateResult()
                                            "
                                        />
                                        <label
                                            class="btn btn-outline-secondary"
                                            for="btnradio7"
                                            >Nein</label
                                        >
                                    </div>
                                </div>

                                <div
                                    *ngIf="typeOfPlan === 'rescue-plan'"
                                    class="mt-4"
                                >
                                    Ist ein örtlicher Termin zur Montage
                                    erwünscht? (Montageservice)
                                </div>
                                <div
                                    *ngIf="typeOfPlan === 'rescue-plan'"
                                    class="d-flex justify-content-center my-2"
                                >
                                    <div
                                        class="btn-group"
                                        role="group"
                                        aria-label="Digital building plans group"
                                    >
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="btnradio3"
                                            id="btnradio8"
                                            autocomplete="off"
                                            [checked]="
                                                isAssemblyDesired === true
                                            "
                                            (change)="
                                                isAssemblyDesired = true;
                                                calculateResult()
                                            "
                                        />
                                        <label
                                            class="btn btn-outline-secondary"
                                            for="btnradio8"
                                            >Ja</label
                                        >

                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="btnradio3"
                                            id="btnradio9"
                                            autocomplete="off"
                                            [checked]="
                                                isAssemblyDesired === false
                                            "
                                            (change)="
                                                isAssemblyDesired = false;
                                                calculateResult()
                                            "
                                        />
                                        <label
                                            class="btn btn-outline-secondary"
                                            for="btnradio9"
                                            >Nein</label
                                        >
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-outline-secundary mt-4"
                                        matStepperPrevious
                                    >
                                        Zurück
                                    </button>
                                    <button
                                        [disabled]="
                                            secondFormGroup.invalid ||
                                            isDataSynchronizationDesired ===
                                                undefined ||
                                            (typeOfPlan === 'rescue-plan' &&
                                                isAssemblyDesired === undefined)
                                        "
                                        [ngClass]="
                                            secondFormGroup.invalid ||
                                            isDataSynchronizationDesired ===
                                                undefined ||
                                            (typeOfPlan === 'rescue-plan' &&
                                                isAssemblyDesired === undefined)
                                                ? 'cursor-not-allowed'
                                                : ''
                                        "
                                        type="button"
                                        class="btn btn-outline-primary mt-4"
                                        matStepperNext
                                    >
                                        Weiter
                                    </button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Fertig!</ng-template>
                            <div class="my-4">
                                Vielen Dank für Ihre Angaben.
                            </div>
                            <div
                                *ngIf="
                                    isDataSynchronizationDesired ||
                                    isAssemblyDesired
                                "
                                class="alert alert-secondary"
                                role="alert"
                            >
                                <h4 class="alert-heading">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="currentColor"
                                        class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                        viewBox="0 0 16 16"
                                        role="img"
                                        aria-label="Warning:"
                                    >
                                        <path
                                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                                        />
                                    </svg>
                                    Hinweis!
                                </h4>
                                <p class="font-size-12">
                                    Für den Abgleich der Daten vor Ort oder der
                                    Montage der Pläne vor Ort, werden zzgl.
                                    Kosten für die An- & Abfahrt berechnet.
                                </p>
                            </div>
                            <div
                                class="alert"
                                role="alert"
                            >
                                <h4 class="alert-heading">
                                    Nicht zögern, jetzt Angebot anfordern!
                                </h4>
                                <p>
                                    Die vorläufigen Kosten für die Erstellung
                                    eines Flucht- und Rettungsplans belaufen
                                    sich auf:
                                    {{
                                        result
                                            | currency
                                                : 'EUR'
                                                : 'symbol'
                                                : '1.0-0'
                                    }}
                                </p>
                                <a
                                    routerLink="/contact-details"
                                    href="javascript: void(0)"
                                    class="btn btn-info my-4"
                                >
                                    <i class="mdi mdi-phone"></i>
                                    individuelles Angebot anfordern
                                </a>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-outline-secundary mt-4"
                                    matStepperPrevious
                                >
                                    Zurück
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-outline-primary mt-4"
                                    (click)="stepper.reset(); reset()"
                                >
                                    Zurücksetzen
                                </button>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </div>
            </div>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a
    href="javascript: void(0);"
    class="btn btn-icon btn-primary back-to-top"
    id="back-to-top"
    [ngxScrollTo]="'#home'"
>
    <i-feather
        name="arrow-up"
        class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
