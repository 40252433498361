<!-- Hero Start -->
<section
    class="bg-half-170 bg-dark d-table w-100"
    id="home"
>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title text-color-white mb-0">Impressum</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav
                aria-label="breadcrumb"
                class="d-inline-block"
            >
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">BENSER GmbH</a>
                    </li>
                    <!-- <li class="breadcrumb-item">
                        <a href="javascript:void(0)">Seiten</a>
                    </li> -->
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0)">Impressum</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Angaben gemäß § 5 TMG :</h5>
                        <p class="text-muted">
                            BENSER GmbH <br />
                            Sergej Sinner <br />
                            Siedlerstr. 26 <br />
                            58285 Gevelsberg <br />
                        </p>

                        <h5 class="card-title">Vertreten durch :</h5>
                        <p class="text-muted">Sergej Sinner</p>

                        <h5 class="card-title">Kontakt :</h5>
                        <p class="text-muted">
                            Telefon: +49 (0) 2332-1718844<br />
                            E-Mail:
                            <a
                                href="mailto:info@benser-gmbh.de"
                                >info@benser-gmbh.de</a
                            >
                        </p>

                        <h5 class="card-title">Umsatzsteuer-ID :</h5>
                        <p class="text-muted">
                            Umsatzsteuer-Identifikationsnummer gemäß §27a
                            Umsatzsteuergesetz: DE352797639
                        </p>

                        <h5 class="card-title">Aufsichtsbehörde :</h5>
                        <p class="text-muted">
                            Amtsgericht Hagen <br />
                            Handels- und Genossenschaftsregister <br />
                            HRB 12369 <br>
                            Heinitzstraße 42/44 <br />
                            58097 Hagen
                        </p>

                        <h5 class="card-title">Industrie und Handelkammer :</h5>
                        <p class="text-muted">
                            Südwestfälische Industrie- und Handelskammer zu
                            Hagen <br />
                            Körperschaft des öffentlichen Rechtes <br />
                            Bahnhofstr. 18 <br />
                            58095 Hagen
                        </p>

                        <p class="text-muted">
                            Die Europäische Kommission stellt eine Plattform zur
                            Online-Streitschlichtung (OS) bereit: <br />
                            <a
                                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
                                >Online-Streitbeilegung</a
                            >
                        </p>

                        <p class="text-muted">
                            Anmerkung: Wir sind nicht bereit oder verpflichtet,
                            an Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle teilzunehmen!
                        </p>

                        <h5 class="card-title">Haftungsausschluss :</h5>
                        <p class="text-muted">
                            <strong>Haftung für Links</strong> <br />
                            Unser Angebot enthält Links zu externen Webseiten
                            Dritter, auf deren Inhalte wir keinen Einfluss
                            haben. Deshalb können wir für diese fremden Inhalte
                            auch keine Gewähr übernehmen. Für die Inhalte der
                            verlinkten Seiten ist stets der jeweilige Anbieter
                            oder Betreiber der Seiten verantwortlich. Die
                            verlinkten Seiten wurden zum Zeitpunkt der
                            Verlinkung auf mögliche Rechtsverstöße überprüft.
                            Rechtswidrige Inhalte waren zum Zeitpunkt der
                            Verlinkung nicht erkennbar. Eine permanente
                            inhaltliche Kontrolle der verlinkten Seiten ist
                            jedoch ohne konkrete Anhaltspunkte einer
                            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
                            von Rechtsverletzungen werden wir derartige Links
                            umgehend entfernen.<br /><br />
                            <strong>Datenschutz</strong> <br />
                            Die Nutzung unserer Webseite ist in der Regel ohne
                            Angabe personenbezogener Daten möglich. Soweit auf
                            unseren Seiten personenbezogene Daten
                            (beispielsweise Name, Anschrift oder eMail-Adressen)
                            erhoben werden, erfolgt dies, soweit möglich, stets
                            auf freiwilliger Basis. Diese Daten werden ohne Ihre
                            ausdrückliche Zustimmung nicht an Dritte
                            weitergegeben. <br />
                            Wir weisen darauf hin, dass die Datenübertragung im
                            Internet (z.B. bei der Kommunikation per E-Mail)
                            Sicherheitslücken aufweisen kann. Ein lückenloser
                            Schutz der Daten vor dem Zugriff durch Dritte ist
                            nicht möglich.
                            <br />
                            Der Nutzung von im Rahmen der Impressumspflicht
                            veröffentlichten Kontaktdaten durch Dritte zur
                            Übersendung von nicht ausdrücklich angeforderter
                            Werbung und Informationsmaterialien wird hiermit
                            ausdrücklich widersprochen. Die Betreiber der Seiten
                            behalten sich ausdrücklich rechtliche Schritte im
                            Falle der unverlangten Zusendung von
                            Werbeinformationen, etwa durch Spam-Mails, vor.<br /><br />
                            <strong>Urheberrecht</strong><br />
                            Die durch die Seitenbetreiber erstellten Inhalte und
                            Werke auf diesen Seiten unterliegen dem deutschen
                            Urheberrecht. Die Vervielfältigung, Bearbeitung,
                            Verbreitung und jede Art der Verwertung außerhalb
                            der Grenzen des Urheberrechtes bedürfen der
                            schriftlichen Zustimmung des jeweiligen Autors bzw.
                            Erstellers. Downloads und Kopien dieser Seite sind
                            nur für den privaten, nicht kommerziellen Gebrauch
                            gestattet. Soweit die Inhalte auf dieser Seite nicht
                            vom Betreiber erstellt wurden, werden die
                            Urheberrechte Dritter beachtet. Insbesondere werden
                            Inhalte Dritter als solche gekennzeichnet. Sollten
                            Sie trotzdem auf eine Urheberrechtsverletzung
                            aufmerksam werden, bitten wir um einen
                            entsprechenden Hinweis. Bei Bekanntwerden von
                            Rechtsverletzungen werden wir derartige Inhalte
                            umgehend entfernen.
                        </p>
                        <h5 class='card-title'>Bildquellen</h5>
                        <p>
                            <a href="https://www.flaticon.com/de/kostenlose-stickers/treffen" title="treffen Sticker">Treffen Sticker erstellt von inipagistudio - Flaticon</a>
                        </p>
                        <p>
                            <a href="https://www.freepik.com/free-photo/green-emergency-exit-sign-ceiling_16496780.htm#query=evacuation%20plan&position=2&from_view=search&track=ais&uuid=e136bad0-46ff-454a-bc69-71b4b5c393ec">Image by rawpixel.com</a> on Freepik
                        </p>
                        <p>
                            Image by <a href="https://www.freepik.com/free-photo/side-view-man-taking-notes-ambulance-car_28476384.htm#page=3&query=fluchtplan&position=10&from_view=search&track=ais&uuid=fceb07f4-dd5d-40eb-a9d6-7df16c55fa3d">Freepik</a>
                        </p>
                        <p>
                            <a href="https://www.freepik.com/free-vector/new-tag-3d-vector-ribbon-banner-new-collection-badge-digital-marketing-web_30137989.htm#query=new&position=2&from_view=search&track=sph&uuid=89c58538-3ffd-4aae-9cee-eb7deeb443d2">Image by felicities</a> on Freepik
                        </p>
                        <p>
                            <a href="https://de.freepik.com/fotos-kostenlos/draufsicht-von-zwei-industriearbeitern-die-schutzhelme-und-reflektierende-jacken-tragen-die-tablette-und-checkliste-auf-grauem-betonboden-halten_11451189.htm#query=Arbeitssicherheit&position=4&from_view=search&track=sph&uuid=b71fb3c8-2309-42f0-987b-a3c4bff3c910">Bild von aleksandarlittlewolf</a> auf Freepik
                        </p>
                        <p>
                            Bild von <a href="https://de.freepik.com/fotos-kostenlos/kollegen-mit-sicherheitsausruestung-die-mit-bauplaenen-arbeiten_13308643.htm#page=4&query=Leistungen%20Arbeitsschutz&position=37&from_view=search&track=ais&uuid=91c345e4-fea2-4814-b964-d31e3ea209cb">Freepik</a>
                        </p>
                        <p>
                            <a href="https://www.freepik.com/free-photo/group-diverse-people-cpr-training-class_2991563.htm#query=Ersthelferschulung&position=0&from_view=search&track=ais&uuid=2a880757-47de-4e94-9af8-f55929d8e3bf">Image by rawpixel.com</a> on Freepik
                        </p>
                        <p>
                            <a href="https://www.freepik.com/free-photo/team-job-photo-young-businessmans-working-with-new-project-office_8488298.htm#query=F%C3%BChrungskr%C3%A4fteschulung&position=2&from_view=search&track=ais&uuid=0003b788-afb6-49e9-a16d-dc18894b3e2a">Image by master1305</a> on Freepik
                        </p>
                        <p>
                            <a href="https://www.freepik.com/free-photo/happy-manual-worker-presenting-results-business-development-while-giving-presentation-company-leaders-his-team-factory_25856081.htm#query=mid-adult-businessman-giving-presentation-group-industrial-workers-factory&position=0&from_view=search&track=sph&uuid=a08c373e-f4e6-4f1e-b696-0ed6b3c4a75f">Image by Drazen Zigic</a> on Freepik
                        </p>
                        <p>
                            <a href="https://www.freepik.com/free-photo/accident-warehouse-man-floor_5578215.htm#query=accident-warehouse-man-floor&position=1&from_view=search&track=sph&uuid=3fc9de5b-e44d-45a4-a68d-a416a0f850ff">Image by senivpetro</a> on Freepik
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a
    href="javascript: void(0);"
    class="btn btn-icon btn-primary back-to-top"
    id="back-to-top"
    [ngxScrollTo]="'#home'"
>
    <i-feather
        name="arrow-up"
        class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
