import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-page-comingsoon',
  templateUrl: './page-comingsoon.component.html',
  styleUrls: ['./page-comingsoon.component.css']
})

/**
 * Page ComingSoon Component
 */
export class PageComingsoonComponent implements OnInit {
  private _trialEndsAt: string;
  private _diff: number;
  _days: number|undefined;
  _hours: number|undefined;
  _minutes: number|undefined;
  _seconds: number|undefined;

  constructor() {
    this._trialEndsAt = "2099-01-01";
    this._diff = Date.parse(this._trialEndsAt) - Date.parse(new Date().toString());
  }
  
  ngOnInit(): void {
    interval(3000).pipe(
      map((x) => {
        this._diff = Date.parse(this._trialEndsAt) - Date.parse(new Date().toString());
      })).subscribe((x) => {
        this._days = this.getDays(this._diff);
        this._hours = this.getHours(this._diff);
        this._minutes = this.getMinutes(this._diff);
        this._seconds = this.getSeconds(this._diff);
      });
  }

  /**
   * Get Date & Time
   */
  getDays(t: number) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t: number) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t: number) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t: number) {
    return Math.floor((t / 1000) % 60);
  }

}
