<!-- Hero Start -->
<section
    class="vh-100 d-flex align-items-center"
    style="background: url('assets/images/contact-detail.jpg') center center"
    id="home"
>
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
                <div class="title-heading mt-5 pt-4">
                    <h1 class="heading">Sprechen wir über Ihr Anliegen</h1>
                    <p class="text-dark">
                        Kontaktieren Sie uns einfach telefonisch, per E-Mail
                        oder mit Hilfe des Kontaktformulars und lassen Sie uns
                        gemeinsam Ihre Ziele erreichen. <br><br>
                        Wir freuen uns auf Ihre Nachricht!
                    </p>

                    <div class="d-flex contact-detail align-items-center mt-3">
                        <div class="icon">
                            <i-feather
                                name="mail"
                                class="fea icon-m-md text-dark me-3"
                            ></i-feather>
                        </div>
                        <div class="flex-1 content">
                            <h5 class="title fw-bold mb-0">Email</h5>
                            <a
                                href="mailto:info@benser-gmbh.de"
                                class="text-primary"
                                >info@benser-gmbh.de</a
                            >
                        </div>
                    </div>

                    <div class="d-flex contact-detail align-items-center mt-3">
                        <div class="icon">
                            <i-feather
                                name="phone"
                                class="fea icon-m-md text-dark me-3"
                            ></i-feather>
                        </div>
                        <div class="flex-1 content">
                            <h5 class="title fw-bold mb-0">Telefon</h5>
                            <a
                                href="tel:+152534-468-854"
                                class="text-primary"
                                >+49 (0)2332 1718844</a
                            >
                        </div>
                    </div>

                    <div class="d-flex contact-detail align-items-center mt-3">
                        <div class="icon">
                            <i-feather
                                name="map-pin"
                                class="fea icon-m-md text-dark me-3"
                            ></i-feather>
                        </div>
                        <div class="flex-1 content">
                            <h5 class="title fw-bold mb-0">Standort</h5>
                            <a
                                (click)="mapView(content)"
                                data-type="iframe"
                                class="video-play-icon text-primary"
                                >Auf Google map anzeigen</a
                            >
                            <ng-template
                                #content
                                let-modal
                            >
                                <div class="modal-header">
                                    <button
                                        type="button"
                                        class="close"
                                        aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5122761.026198915!2d10.4541194!3d51.1758057!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86b6569b921e9b43%3A0xd7ae5954b5a7309c!2sSIB-Brandschutz%20GmbH!5e0!3m2!1sde!2sde!4v1700483725937!5m2!1sde!2sde" width="750" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <ul class="list-unstyled social-icon mb-0 mt-4">
                        <li class="list-inline-item">
                            <a
                                href="javascript:void(0)"
                                class="rounded"
                            >
                                <i-feather
                                    name="facebook"
                                    class="fea icon-sm fea-social"
                                ></i-feather>
                            </a>
                        </li>
                        <li class="list-inline-item ms-1">
                            <a
                                href="javascript:void(0)"
                                class="rounded"
                            >
                                <i-feather
                                    name="instagram"
                                    class="fea icon-sm fea-social"
                                ></i-feather>
                            </a>
                        </li>
                        <li class="list-inline-item ms-1">
                            <a
                                href="javascript:void(0)"
                                class="rounded"
                            >
                                <i-feather
                                    name="twitter"
                                    class="fea icon-sm fea-social"
                                ></i-feather>
                            </a>
                        </li>
                        <li class="list-inline-item ms-1">
                            <a
                                href="javascript:void(0)"
                                class="rounded"
                            >
                                <i-feather
                                    name="linkedin"
                                    class="fea icon-sm fea-social"
                                ></i-feather>
                            </a>
                        </li>
                        <li class="list-inline-item ms-1">
                            <a
                                href="javascript:void(0)"
                                class="rounded"
                            >
                                <i-feather
                                    name="github"
                                    class="fea icon-sm fea-social"
                                ></i-feather>
                            </a>
                        </li>
                        <li class="list-inline-item ms-1">
                            <a
                                href="javascript:void(0)"
                                class="rounded"
                            >
                                <i-feather
                                    name="youtube"
                                    class="fea icon-sm fea-social"
                                ></i-feather>
                            </a>
                        </li>
                        <li class="list-inline-item ms-1">
                            <a
                                href="javascript:void(0)"
                                class="rounded"
                            >
                                <i-feather
                                    name="gitlab"
                                    class="fea icon-sm fea-social"
                                ></i-feather>
                            </a>
                        </li>
                    </ul>
                    <!--end icon-->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Back to top -->
<a
    href="javascript: void(0);"
    class="btn btn-icon btn-primary back-to-top"
    id="back-to-top"
    [ngxScrollTo]="'#home'"
>
    <i-feather
        name="arrow-up"
        class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
