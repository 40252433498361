<!-- Hero Start -->
<section class="bg-half-260 d-table w-100 background-image" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 mt-md-4">
            <div class="col-12">
                <div class="title-heading">
                    <h4 class="display-4 mb-4 fw-bold text-white title-dark">
                        Willkommen bei<br/>
                        der Firma <span class="text-primary">BENSER GmbH</span>
                    </h4>
                    <p class="para-desc text-white">
                        Herzlich willkommen auf der offiziellen Website der Benser GmbH – Ihrem zuverlässigen Partner
                        für Themen rund um Sicherheit.
                    </p>
                    <p class="para-desc text-white">
                        Wir legen großen Wert auf Transparenz, Zuverlässigkeit und langfristige Partnerschaften.
                    </p>
                    <p class="para-desc text-white">
                        Entdecken Sie auf unserer Website unser umfangreiches Angebot an Dienstleistungen
                        und erfahren Sie mehr darüber, wie wir Ihnen helfen können, Ihre Ziele zu erreichen.
                    </p>
                    <!-- <div class="mt-4 pt-2">
            <a
              href="javascript:void(0)"
              class="btn btn-primary"
              routerLink="/aboutus"
              >Erfahren Sie mehr</a
            >
          </div> -->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<section class="section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card features-absolute rounded shadow">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 my-4">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex features feature-primary p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-heart align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">Arbeitssicherheit und Arbeitsmedizin</h4>
                                            <p class="text-muted para mb-0">
                                                Identifizierung von potenziellen Gefahrenquellen
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-12">
                                    <div class="d-flex features feature-primary p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-constructor align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">Maschinen- und Anlagensicherheit</h4>
                                            <p class="text-muted para mb-0">
                                                Rund um Maschinenrichtlinien und Betriebssicherheitsverordnungen
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-12">
                                    <div class="d-flex features feature-primary p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-no-entry align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">
                                                Gefahrstoffmanagement
                                            </h4>
                                            <p class="text-muted para mb-0">
                                                Optimierung der Gefahrstoffmanagementsysteme
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-12">
                                    <div class="d-flex features feature-primary p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-ruler-combined align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">Schulungen und Unterweisungen</h4>
                                            <p class="text-muted para mb-0">
                                                Aus- und Weiterbildungen für Ihre Mitarbeiter
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                        <!--end col-->

                        <div class="col-lg-4 d-none d-lg-block position-relative">
                            <img src="assets/images/construction/serveices.png"
                                 class="img-fluid mx-auto d-block construction-img"
                                 alt=""/>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
            </div>
            <!--end col-->

            <div class="col-12 mt-4 pt-2 text-center">
                <p class="text-muted mb-0">
                    Unsere Liste der Dienstleistungen ist hier noch nicht zu
                    Ende.
                    <a
                            routerLink="/services"
                            href="javascript: void(0)"
                            class="text-primary h6"
                    >Hier klicken</a
                    >, und wir finden eine weitere Lösung für Sie!
                </p>
            </div>
            <!--end col-->

            <div class="col-12 mt-4 pt-2 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">
                        Interessieren Sie sich für <br/>
                        Feuerwehr-, Flucht- oder Rettungspläne?
                    </h4>
                    <p class="text-muted para-desc mx-auto">
                        Endecken Sie unseren neuen Planungsrechner und lassen
                        Sie sich ein unverbindliches Angebot erstellen.
                    </p>
                    <a
                            routerLink="/plan-calculator"
                            class="btn btn-primary mt-4"
                    ><i class="mdi mdi-phone"></i> Zum Planungsrechner</a
                    >
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 col-12">
                <div class="position-relative">
                    <video
                            loop
                            autoplay
                            mited
                            playsinline
                            class="rounded img-fluid mx-auto d-block"
                            src="assets/videos/intro.mp4"
                            alt=""
                    ></video>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ms-lg-5 ms-md-4">
                    <div class="section-title">
                        <span class="badge rounded-pill bg-soft-primary"
                        >Über uns</span
                        >
                        <h4 class="title mt-3 mb-4">
                            Ihr Vorteil: <br/>
                            zertifiziert und kosteneffizient mit <br/>
                            <span class="text-primary">BENSER GmbH</span>
                        </h4>
                        <p class="text-muted para-desc mx-auto">
                            Das TEAM hinter der
                            <span class="text-primary">BENSER GmbH</span>
                            besteht aus Experten die sich schon seit Jahrzehnten
                            für die Bereiche des vorbeugenden und abwehrenden
                            Brandschutzes begeistern. Die oberste Priorität
                            jedes Einzelnen im Team ist das vorhandene Wissen
                            und die Erfahrung an unsere Kunden weiterzugeben.
                        </p>
                        <p class="text-muted para-desc mx-auto mb-0">
                            Gemeinsam mit Ihnen entwickeln wir
                            praxisorientierte, effektive und effiziente
                            Lösungen, welche speziell auf Ihre betrieblichen
                            Gegebenheiten zugeschnitten.
                        </p>
                        <!-- <div class="mt-4">
              <a href="/aboutus" class="btn btn-primary">Erfahren Sie mehr</a>
            </div> -->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Experten Team-Mitglieder</h4>
                    <p class="text-muted mx-auto para-desc mb-0">
                        Erfahren Sie mehr über unser Experten-Team und lernen
                        Sie die Mitglieder kennen, die hinter unserem Erfolg und
                        unserer Kompetenz stehen - hier auf unserer Homepage.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row d-flex justify-content-center">
            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img
                                src="assets/images/construction/t6.png"
                                class="img-fluid"
                                alt=""
                        />
                        <ul
                                class="list-unstyled social-icon team-icon mb-0 mt-4"
                        >
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="facebook"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="instagram"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="twitter"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="linkedin"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0">
                            <a
                                    href="javascript:void(0)"
                                    class="name text-dark"
                            >Sergej Sinner</a
                            >
                        </h5>
                        <small class="designation text-muted"
                        >Geschäftsführer</small
                        >
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img
                                src="assets/images/construction/t6.png"
                                class="img-fluid"
                                alt=""
                        />
                        <ul
                                class="list-unstyled social-icon team-icon mb-0 mt-4"
                        >
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="facebook"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="instagram"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="twitter"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                            <li class="list-inline-item ms-1">
                                <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                >
                                    <i-feather
                                            name="linkedin"
                                            class="fea icon-sm fea-social"
                                    ></i-feather>
                                </a>
                            </li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0">
                            <a
                                    href="javascript:void(0)"
                                    class="name text-dark"
                            >Micheal Ratke</a
                            >
                        </h5>
                        <small class="designation text-muted"
                        >Softwareentwickler und Designer</small
                        >
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- FAQ n Contact Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex">
                    <i-feather
                            name="help-circle"
                            class="fea icon-ex-md text-primary me-2 mt-1"
                    ></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">
                            Ich baue im Bestand und das Bauamt fordert einen
                            Nachweis für den Brandschutz nach §64 BauO NRW an,
                            was soll ich tun?
                        </h5>
                        <p class="answer text-muted mb-0">
                            Wir arbeiten mit renommierten staatlich anerkannten
                            Sachverständigen für die Prüfung des Brandschutzes
                            zusammen. Diese können im Verfahren den Brandschutz
                            prüfen. Kurz gesagt: Der Sachverständige prüft für
                            die Baubehörde den Brandschutz an Ihrem Bauvorhaben.
                            Wir können Sie im Verfahren beraten und
                            Stellungnahmen erstellen. Was benötigen wir:
                            Bauantrag und Architektenpläne.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12">
                <div class="d-flex">
                    <i-feather
                            name="help-circle"
                            class="fea icon-ex-md text-primary me-2 mt-1"
                    ></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">
                            Welche Maßnahmen kann ein Unternehmen ergreifen, um die Arbeitssicherheit zu verbessern?
                        </h5>
                        <p class="answer text-muted mb-0">
                            Es gibt verschiedene Maßnahmen, die ein Unternehmen ergreifen kann, um die Arbeitssicherheit
                            zu verbessern. Dazu gehören regelmäßige Sicherheitsschulungen für Mitarbeiter, die
                            Bereitstellung angemessener persönlicher Schutzausrüstung, die Implementierung von
                            Notfallplänen, regelmäßige Inspektionen von Arbeitsbereichen und Maschinen sowie die
                            Förderung einer Sicherheitskultur, in der Mitarbeiter aktiv zur Identifizierung und Meldung
                            von Sicherheitsrisiken ermutigt werden.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather
                            name="help-circle"
                            class="fea icon-ex-md text-primary me-2 mt-1"
                    ></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">
                            Die Feuerwehr hat Fragen bezüglich meines
                            Umbaus/Bau. Ich weiß aber nicht, was genau die
                            Feuerwehr verlangt.
                        </h5>
                        <p class="answer text-muted mb-0">
                            Wir nehmen gerne jede Anfrage an und unterstützen
                            Sie als Bauherr. Kurze Anfrage mit eventuellen
                            vorhandenen Dokumenten über unsere Anfragenseite.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather
                            name="help-circle"
                            class="fea icon-ex-md text-primary me-2 mt-1"
                    ></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">
                            Welche Maßnahmen sollten Unternehmen ergreifen, um die Sicherheit von Maschinen und Anlagen
                            zu gewährleisten?
                        </h5>
                        <p class="answer text-muted mb-0">
                            Unternehmen sollten eine umfassende Risikobewertung für ihre Maschinen und Anlagen
                            durchführen, um potenzielle Gefahren zu identifizieren. Dies sollte von qualifiziertem
                            Personal durchgeführt werden. Es ist wichtig, alle Mitarbeiter in sicherheitsrelevanten
                            Verfahren zu schulen und die notwendige persönliche Schutzausrüstung bereitzustellen. Zudem
                            sollten regelmäßige Wartungs- und Inspektionspläne implementiert werden, um die
                            ordnungsgemäße Funktionalität von Maschinen sicherzustellen. Eine klare Kennzeichnung von
                            Gefahrenbereichen sowie die Integration von Schutzeinrichtungen und Notausschaltern sind
                            ebenfalls entscheidend für die Sicherheit von Maschinen und Anlagen.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather
                            name="help-circle"
                            class="fea icon-ex-md text-primary me-2 mt-1"
                    ></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">
                            Welche Schritte sollten Unternehmen im Rahmen des Gefahrstoffmanagements unternehmen?
                        </h5>
                        <p class="answer text-muted mb-0">
                            Zunächst ist es wichtig, alle im Unternehmen vorhandenen Gefahrstoffe zu identifizieren und
                            zu dokumentieren. Eine umfassende Risikobewertung sollte durchgeführt werden, um potenzielle
                            Gefahren zu erkennen. Unternehmen müssen sicherstellen, dass Mitarbeiter angemessen geschult
                            werden, um sicher mit Gefahrstoffen umzugehen, und dass geeignete persönliche
                            Schutzausrüstung bereitgestellt wird. Die ordnungsgemäße Lagerung, Kennzeichnung und
                            Entsorgung von Gefahrstoffen sind ebenfalls entscheidende Aspekte des
                            Gefahrstoffmanagements.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather
                            name="help-circle"
                            class="fea icon-ex-md text-primary me-2 mt-1"
                    ></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">
                            Welche gesetzlichen Vorschriften regeln das Gefahrstoffmanagement?
                        </h5>
                        <p class="answer text-muted mb-0">
                            Das Gefahrstoffmanagement unterliegt verschiedenen gesetzlichen Vorschriften, wie
                            beispielsweise der Gefahrstoffverordnung (GefStoffV) in Deutschland oder vergleichbaren
                            Regelungen in anderen Ländern. Diese Vorschriften legen Richtlinien für den Umgang mit
                            gefährlichen Stoffen fest, einschließlich Kennzeichnung, Dokumentation, Schulung und
                            Maßnahmen zur Risikominimierung.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="d-flex">
                <i-feather
                        name="help-circle"
                        class="fea icon-ex-md text-primary me-2 mt-1"
                ></i-feather>
                <div class="flex-1">
                    <h5 class="mt-0">
                        Was ist der Zweck von Arbeitsmedizin in einem Unternehmen?
                    </h5>
                    <p class="answer text-muted mb-0">
                        Arbeitsmedizin in einem Unternehmen dient dazu, die Gesundheit der Mitarbeiter zu schützen und
                        zu fördern. Arbeitsmediziner überwachen die gesundheitlichen Auswirkungen von
                        Arbeitsbedingungen, bieten Präventionsmaßnahmen an, unterstützen bei der Wiedereingliederung von
                        Mitarbeitern nach Krankheitsphasen und tragen dazu bei, arbeitsbedingte Gesundheitsrisiken zu
                        minimieren.
                    </p>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">
                        Haben Sie Fragen ? Kontakt aufnehmen!
                    </h4>
                    <p class="text-muted para-desc mx-auto">
                        Auf unserer Homepage finden Sie alle notwendigen
                        Informationen und Möglichkeiten, um uns zu erreichen und
                        Ihre Anliegen mit uns zu besprechen.
                    </p>
                    <a
                            routerLink="/contact-details"
                            href="javascript: void(0)"
                            class="btn btn-primary mt-4"
                    ><i class="mdi mdi-phone"></i> Kontaktieren Sie uns</a
                    >
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg
                viewBox="0 0 2880 250"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
        >
            <path
                    d="M720 125L2160 0H2880V250H0V125H720Z"
                    fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!-- FAQ n Contact End -->

<!-- Back to top -->
<a
        href="javascript: void(0);"
        class="btn btn-icon btn-primary back-to-top"
        id="back-to-top"
        [ngxScrollTo]="'#home'"
>
    <i-feather
            name="arrow-up"
            class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
