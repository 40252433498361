<!-- Hero Start -->
<section
    class="bg-half-170 d-table w-100"
    style="background: url('assets/images/corporate/pages.jpg') top"
    id="home"
>
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h2 class="text-white title-dark">Business Preise</h2>
                    <p class="text-white-50 para-desc mb-0 mx-auto">
                        Wir bieten Ihnen faire und transparente Preise, die auf
                        Ihre individuellen Bedürfnisse und Anforderungen
                        abgestimmt sind. Wir arbeiten mit Ihnen zusammen, um die
                        beste Lösung für Ihr Unternehmen zu finden und dabei Ihr
                        Budget im Blick zu behalten. Unsere Preise sind
                        konkurrenzfähig und bieten Ihnen ein optimales
                        Preis-Leistungs-Verhältnis. Bei uns gibt es keine
                        versteckten Kosten oder Gebühren - Sie wissen jederzeit,
                        was Sie für unsere Dienstleistungen bezahlen.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav
                aria-label="breadcrumb"
                class="d-inline-block"
            >
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">SIB Brandschutz</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/index-corporate">Business</a>
                    </li>
                    <li
                        class="breadcrumb-item active"
                        aria-current="page"
                    >
                        Preise
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div
            class="row align-items-center"
            id="counter"
        >
            <div class="col-md-6">
                <img
                    src="assets/images/company/about2.png"
                    class="img-fluid"
                    alt=""
                />
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <div class="d-flex mb-4">
                        <span class="text-primary h1 mb-0"
                            ><span
                                class="counter-value display-1 fw-bold"
                                data-target="15"
                                >0</span
                            >+</span
                        >
                        <span class="h6 align-self-end ms-2"
                            >Years <br />
                            Experience</span
                        >
                    </div>
                    <div class="section-title">
                        <h4 class="title mb-4">Who we are ?</h4>
                        <p class="text-muted">
                            Start working with
                            <span class="text-primary fw-bold">Landrick</span>
                            that can provide everything you need to generate
                            awareness, drive traffic, connect. Dummy text is
                            text that is used in the publishing industry or by
                            web designers to occupy the space which will later
                            be filled with 'real' content. This is required
                            when, for example, the final text is not yet
                            available. Dummy texts have been in use by
                            typesetters since the 16th century.
                        </p>
                        <a
                            href="javascript:void(0)"
                            class="btn btn-primary mt-3"
                            >Contact us</a
                        >
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-4">
        <div class="row justify-content-center">
            <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                <img
                    src="assets/images/client/amazon.svg"
                    class="avatar avatar-ex-sm"
                    alt=""
                />
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                <img
                    src="assets/images/client/google.svg"
                    class="avatar avatar-ex-sm"
                    alt=""
                />
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                <img
                    src="assets/images/client/lenovo.svg"
                    class="avatar avatar-ex-sm"
                    alt=""
                />
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                <img
                    src="assets/images/client/paypal.svg"
                    class="avatar avatar-ex-sm"
                    alt=""
                />
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                <img
                    src="assets/images/client/shopify.svg"
                    class="avatar avatar-ex-sm"
                    alt=""
                />
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                <img
                    src="assets/images/client/spotify.svg"
                    class="avatar avatar-ex-sm"
                    alt=""
                />
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h6 class="text-primary">Work Process</h6>
                    <h4 class="title mb-4">How do we works ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        Start working with
                        <span class="text-primary fw-bold">Landrick</span> that
                        can provide everything you need to generate awareness,
                        drive traffic, connect.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div
                    class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded"
                >
                    <div class="card-body">
                        <h6 class="title name fw-bold text-uppercase mb-4">
                            Free
                        </h6>
                        <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">0</span>
                            <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Full Access
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Source Files
                            </li>
                        </ul>
                        <a
                            href="javascript:void(0)"
                            class="btn btn-primary mt-4"
                            >Buy Now</a
                        >
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div
                    class="card pricing pricing-primary business-rate shadow border-0 rounded"
                >
                    <div
                        class="ribbon ribbon-right ribbon-warning overflow-hidden"
                    >
                        <span class="text-center d-block shadow small h6"
                            >Best</span
                        >
                    </div>
                    <div class="card-body">
                        <h6 class="title name fw-bold text-uppercase mb-4">
                            Starter
                        </h6>
                        <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">39</span>
                            <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Full Access
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Source Files
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Free Appointments
                            </li>
                        </ul>
                        <a
                            href="javascript:void(0)"
                            class="btn btn-primary mt-4"
                            >Get Started</a
                        >
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div
                    class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded"
                >
                    <div class="card-body">
                        <h6 class="title name fw-bold text-uppercase mb-4">
                            Professional
                        </h6>
                        <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">59</span>
                            <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Full Access
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Source Files
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >1 Domain Free
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Enhanced Security
                            </li>
                        </ul>
                        <a
                            href="javascript:void(0)"
                            class="btn btn-primary mt-4"
                            >Try It Now</a
                        >
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div
                    class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded"
                >
                    <div class="card-body">
                        <h6 class="title name fw-bold text-uppercase mb-4">
                            Ultimate
                        </h6>
                        <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">79</span>
                            <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 ps-0">
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Full Access
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Enhanced Security
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Source Files
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >1 Domain Free
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="icon h5 me-2"
                                    ><i
                                        class="uil uil-check-circle align-middle"
                                    ></i></span
                                >Free Installment
                            </li>
                        </ul>
                        <a
                            href="javascript:void(0)"
                            class="btn btn-primary mt-4"
                            >Started Now</a
                        >
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Our Clients Said</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        Start working with
                        <span class="text-primary fw-bold">Landrick</span> that
                        can provide everything you need to generate awareness,
                        drive traffic, connect.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <owl-carousel-o
                    id="customer-testi"
                    [options]="customOptions"
                >
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/01.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " It seems that only fragments of the
                                        original text remain in the Lorem Ipsum
                                        texts used today. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Thomas Israel
                                        <small class="text-muted">C.E.O</small>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/02.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star-half text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " One disadvantage of Lorum Ipsum is
                                        that in Latin certain letters appear
                                        more frequently than others. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Barbara McIntosh
                                        <small class="text-muted">M.D</small>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/03.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " The most well-known dummy text is the
                                        'Lorem Ipsum', which is said to have
                                        originated in the 16th century. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Carl Oliver
                                        <small class="text-muted">P.A</small>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/04.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " According to most sources, Lorum Ipsum
                                        can be traced back to a text composed by
                                        Cicero. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Christa Smith
                                        <small class="text-muted"
                                            >Manager</small
                                        >
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/05.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " There is now an abundance of readable
                                        dummy texts. These are usually used when
                                        a text is required. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Dean Tolle
                                        <small class="text-muted"
                                            >Developer</small
                                        >
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/06.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " Thus, Lorem Ipsum has only limited
                                        suitability as a visual filler for
                                        German texts. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Jill Webb
                                        <small class="text-muted"
                                            >Designer</small
                                        >
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center mb-4 pb-2">
            <div class="col-lg-6">
                <div class="section-title text-center text-lg-start">
                    <h6 class="text-primary">Blog</h6>
                    <h4 class="title mb-4 mb-lg-0">
                        Reads Our Latest <br />
                        News & Blog
                    </h4>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6">
                <div class="section-title text-center text-lg-start">
                    <p class="text-muted mb-0 mx-auto para-desc">
                        Start working with
                        <span class="text-primary fw-bold">Landrick</span> that
                        can provide everything you need to generate awareness,
                        drive traffic, connect.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog blog-primary rounded border-0 shadow">
                    <div class="position-relative">
                        <img
                            src="assets/images/blog/01.jpg"
                            class="card-img-top rounded-top"
                            alt="..."
                        />
                        <div class="overlay rounded-top"></div>
                    </div>
                    <div class="card-body content">
                        <h5>
                            <a
                                href="javascript:void(0)"
                                class="card-title title text-dark"
                                >Design your apps in your own way</a
                            >
                        </h5>
                        <div
                            class="post-meta d-flex justify-content-between mt-3"
                        >
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0">
                                    <a
                                        href="javascript:void(0)"
                                        class="text-muted like"
                                        ><i class="uil uil-heart me-1"></i>33</a
                                    >
                                </li>
                                <li class="list-inline-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="text-muted comments"
                                        ><i class="uil uil-comment me-1"></i
                                        >08</a
                                    >
                                </li>
                            </ul>
                            <a
                                routerLink="/blog-detail"
                                class="text-muted readmore"
                                >Read More
                                <i
                                    class="uil uil-angle-right-b align-middle"
                                ></i
                            ></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="user d-block"
                            ><i class="uil uil-user"></i> Calvin Carlo</small
                        >
                        <small class="date"
                            ><i class="uil uil-calendar-alt"></i> 25th June
                            2021</small
                        >
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog blog-primary rounded border-0 shadow">
                    <div class="position-relative">
                        <img
                            src="assets/images/blog/02.jpg"
                            class="card-img-top rounded-top"
                            alt="..."
                        />
                        <div class="overlay rounded-top"></div>
                    </div>
                    <div class="card-body content">
                        <h5>
                            <a
                                href="javascript:void(0)"
                                class="card-title title text-dark"
                                >How apps is changing the IT world</a
                            >
                        </h5>
                        <div
                            class="post-meta d-flex justify-content-between mt-3"
                        >
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0">
                                    <a
                                        href="javascript:void(0)"
                                        class="text-muted like"
                                        ><i class="uil uil-heart me-1"></i>33</a
                                    >
                                </li>
                                <li class="list-inline-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="text-muted comments"
                                        ><i class="uil uil-comment me-1"></i
                                        >08</a
                                    >
                                </li>
                            </ul>
                            <a
                                routerLink="/blog-detail"
                                class="text-muted readmore"
                                >Read More
                                <i
                                    class="uil uil-angle-right-b align-middle"
                                ></i
                            ></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="user d-block"
                            ><i class="uil uil-user"></i> Calvin Carlo</small
                        >
                        <small class="date"
                            ><i class="uil uil-calendar-alt"></i> 25th June
                            2021</small
                        >
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog blog-primary rounded border-0 shadow">
                    <div class="position-relative">
                        <img
                            src="assets/images/blog/03.jpg"
                            class="card-img-top rounded-top"
                            alt="..."
                        />
                        <div class="overlay rounded-top"></div>
                    </div>
                    <div class="card-body content">
                        <h5>
                            <a
                                href="javascript:void(0)"
                                class="card-title title text-dark"
                                >Smartest Applications for Business</a
                            >
                        </h5>
                        <div
                            class="post-meta d-flex justify-content-between mt-3"
                        >
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0">
                                    <a
                                        href="javascript:void(0)"
                                        class="text-muted like"
                                        ><i class="uil uil-heart me-1"></i>33</a
                                    >
                                </li>
                                <li class="list-inline-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="text-muted comments"
                                        ><i class="uil uil-comment me-1"></i
                                        >08</a
                                    >
                                </li>
                            </ul>
                            <a
                                routerLink="/blog-detail"
                                class="text-muted readmore"
                                >Read More
                                <i
                                    class="uil uil-angle-right-b align-middle"
                                ></i
                            ></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="user d-block"
                            ><i class="uil uil-user"></i> Calvin Carlo</small
                        >
                        <small class="date"
                            ><i class="uil uil-calendar-alt"></i> 25th June
                            2021</small
                        >
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a
    href="javascript: void(0);"
    class="btn btn-icon btn-primary back-to-top"
    id="back-to-top"
    [ngxScrollTo]="'#home'"
>
    <i-feather
        name="arrow-up"
        class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
