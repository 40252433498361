<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 background-image" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h2 class="text-white title-dark">Leistungen</h2>
                    <p class="text-white-50 para-desc mb-0 mx-auto">
                        Entdecken Sie auf unserer Homepage, welche umfassenden
                        Leistungen wir Ihnen anbieten und wie wir Sie bei Ihren
                        individuellen Bedürfnissen unterstützen können.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">BENSER GmbH</a>
                    </li>
                    <!-- <li class="breadcrumb-item">
                        <a routerLink="/index-corporate">Business</a>
                    </li> -->
                    <li class="breadcrumb-item active" aria-current="page">
                        Leistungen
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-4">
                <div
                        class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-heart d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="services#servicePanel" class="title h5 text-dark">Arbeitssicherheit und
                            Arbeitsmedizin</a>
                        <p class="text-muted mt-2"></p>
                        <a href="services#servicePanel" class="read-more">Mehr erfahren <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-heart full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-4">
                <div
                        class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-constructor d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="services#servicePanel" class="title h5 text-dark">Maschinen- und Anlagensicherheit</a>
                        <p class="text-muted mt-2"></p>
                        <a href="services#servicePanel" class="read-more">Mehr erfahren <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-constructor full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-4">
                <div
                        class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-no-entry d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="services#servicePanel" class="title h5 text-dark">Gefahrstoff-<br>management</a>
                        <p class="text-muted mt-2"></p>
                        <a href="services#servicePanel" class="read-more">Mehr erfahren <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-no-entry full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-4">
                <div
                        class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                    <div class="icons text-center">
                        <i class="uil uil-fire d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="services#servicePanel" class="title h5 text-dark">Brandschutz</a>
                        <p class="text-muted mt-2"></p>
                        <a href="services#servicePanel" class="read-more">Mehr erfahren <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-fire full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-3 mt-md-4 mt-lg-5">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h6 class="text-primary">Arbeitsprozess</h6>
                    <h4 class="title mb-4">Wie arbeiten wir? ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        Unsere Arbeitsweise zeichnet sich durch
                        Professionalität, Effizienz und Kundenorientierung aus.
                        <br/><br/>
                        Wir arbeiten eng mit Ihnen zusammen, um Ihre Bedürfnisse
                        und Anforderungen zu verstehen und maßgeschneiderte
                        Lösungen für Ihr Unternehmen zu entwickeln. Dabei legen
                        wir Wert auf transparente Kommunikation und eine
                        partnerschaftliche Zusammenarbeit. Wir sind erst
                        zufrieden, wenn Sie es sind!
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div
                        class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Besprechung</h5>
                        <p class="text-muted mb-0">
                            Für uns ist es von großer Bedeutung, uns Zeit für
                            Sie und Ihre Anliegen zu nehmen.
                            <br/><br/>
                            In unseren Kundenbesprechungen hören wir Ihnen
                            aufmerksam zu und erarbeiten gemeinsam mit Ihnen
                            individuelle Lösungen für Ihre Herausforderungen.
                            Dabei legen wir großen Wert auf einen offenen und
                            konstruktiven Dialog, um sicherzustellen, dass wir
                            Ihre Bedürfnisse vollständig verstehen und optimal
                            darauf eingehen können.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div
                        class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Strategie & Planung</h5>
                        <p class="text-muted mb-0">
                            Eine umfassende Strategie und Planung ist der
                            Schlüssel zum Erfolg. Deshalb legen wir großen Wert
                            auf eine gründliche Analyse Ihrer Bedürfnisse und
                            Anforderungen sowie auf die Entwicklung einer
                            maßgeschneiderten Strategie für Ihr Unternehmen.
                            <br/><br/>
                            Gemeinsam mit Ihnen setzen wir klare Ziele und
                            definieren realistische Meilensteine, um
                            sicherzustellen, dass wir Ihre Erwartungen erfüllen
                            oder übertreffen können.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div
                        class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">Berichterstattung</h5>
                        <p class="text-muted mb-0">
                            Wir legen großen Wert auf eine transparente und
                            regelmäßige Berichterstattung gegenüber unseren
                            Kunden. Sie werden stets über den Fortschritt und
                            die Ergebnisse unserer Arbeit informiert und haben
                            jederzeit einen Überblick über den Status Ihrer
                            Projekte.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container-fluid mt-100 mt-60">
        <div class="bg-cta shadow rounded card overflow-hidden background-image2" id="cta">
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title">
                            <h4 class="title title-dark text-white mb-4">
                                Wir sind ein dynamisches Team von kreativen
                                Köpfen und innovativen Denkern
                            </h4>
                            <p class="text-white-50 para-dark para-desc mx-auto">
                                Unsere Erfahrung und unser fundiertes Know-how
                                machen uns zu einem starken Partner für Ihr
                                Unternehmen. Wir setzen alles daran, Ihnen stets
                                höchste Qualität und Zufriedenheit zu bieten und
                                stehen Ihnen mit Rat und Tat zur Seite.
                                Gemeinsam erreichen wir Ihre Ziele und sorgen
                                für ein sicheres Umfeld. Wir freuen uns auf eine
                                erfolgreiche Zusammenarbeit mit Ihnen!
                            </p>
                            <!-- <a
                                href="javascript:void(0);"
                                class="play-btn mt-4 video-play-icon"
                            >
                                <i-feather
                                    name="play"
                                    class="fea icon-ex-md text-white title-dark"
                                ></i-feather>
                            </a> -->
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </div>
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" name='servicePanel'>
        <div class="row align-items-end mb-4 pb-4">
            <div class="col-md-8">
                <div class="section-title text-center text-md-start">
                    <h6 class="text-primary">Leistungen</h6>
                    <h4 class="title mb-4">Was wir tun ?</h4>
                    <p class="text-muted mb-0 para-desc">
                        Arbeiten Sie mit
                        <span class="text-primary fw-bold">BENSER GmbH</span>
                        zusammen, das Ihnen alles bietet, was Sie brauchen, um
                        Ihr Unternehmen gesetzeskonform gegen mögliche
                        Sicherheitsrisiken abszusichern.
                    </p>
                </div>
            </div>
            <!--end col-->

            <!-- <div class="col-md-4 mt-4 mt-sm-0">
                <div class="text-center text-md-end">
                    <a
                        href="javascript:void(0)"
                        class="text-primary h6"
                        >See More
                        <i class="uil uil-angle-right-b align-middle"></i
                    ></a>
                </div>
            </div> -->
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <ul class="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar" id="pills-tab"
                    role="tablist">
                    <li class="nav-item">
                        <a class="nav-link rounded active" id="occupationalsafetylink" data-bs-toggle="pill"
                           href="#occupationalsafety" role="tab" aria-controls="occupationalsafetylink"
                           aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Arbeitssicherheit und Arbeitsmedizin</h6>
                            </div>
                        </a><!--end nav link-->
                    </li>
                    <!--end nav item-->

                    <li class="nav-item mt-2">
                        <a class="nav-link rounded" id="machineandplantsafetylink" data-bs-toggle="pill"
                           href="#machineandplantsafety" role="tab" aria-controls="machineandplantsafetylink"
                           aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Maschinen- und Anlagensicherheit</h6>
                            </div>
                        </a><!--end nav link-->
                    </li>
                    <!--end nav item-->

                    <li class="nav-item mt-2">
                        <a class="nav-link rounded" id="hazmatmanagementlink" data-bs-toggle="pill"
                           href="#hazmatmanagement" role="tab" aria-controls="hazmatmanagementlink"
                           aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Gefahrstoffmanagement</h6>
                            </div>
                        </a><!--end nav link-->
                    </li>
                    <!--end nav item-->

                    <li class="nav-item mt-2">
                        <a class="nav-link rounded" id="fireprotectionlink" data-bs-toggle="pill"
                           href="#fireprotection" role="tab" aria-controls="fireprotectionlink"
                           aria-selected="false">
                            <div class="text-center py-1">
                                <h6 class="mb-0">Brandschutz</h6>
                            </div>
                        </a><!--end nav link-->
                    </li>
                    <!--end nav item-->
                </ul>
                <!--end nav pills-->
            </div>
            <!--end col-->

            <!-- Arbeitssicherheit und Arbeitsmedizin -->
            <div class="col-md-8 col-12 mt-4 pt-2">
                <div class="tab-content" id="pills-tabContent">

                    <div class="tab-pane fade show active p-4 rounded shadow" id="occupationalsafety" role="tabpanel"
                         aria-labelledby="occupationalsafety">
                        <img src="assets/images/services/safetyconceptspanel.jpg" class="img-fluid rounded shadow"
                             alt=""/>
                        <div class="mt-4">
                            <h5>Arbeitssicherheit und Arbeitsmedizin</h5>
                            <p class="text-muted">
                                Die Fachkräfte für Arbeitssicherheit und Sicherheitsingenieure der BENSER GmbH
                                unterstützen Sie bei der Planung, Umsetzung und Überwachung von Sicherheitsmaßnahmen in
                                Ihrem Unternehmen. Wir analysieren Arbeitsprozesse, identifizieren potenzielle
                                Gefahrenquellen und entwickeln mit Ihnen zusammen entsprechende Schutzmaßnahmen.
                            </p>
                            <p class="text-muted">
                                Durch ihre Fachkenntnisse und Erfahrung gewährleisten sie die Einhaltung gesetzlicher
                                Vorschriften und Standards in Bezug auf Arbeitssicherheit. Ihr Ziel ist es, Unfälle zu
                                vermeiden, Gesundheitsrisiken zu minimieren und das Wohlbefinden der Mitarbeiter zu
                                fördern.
                            </p>
                            <p class="text-muted">
                                Durch die Zusammenarbeit mit unseren Arbeitsmedizinern bekommen Sie alles aus einer
                                Hand. Die Arbeitsmedizinische Betreuung erfolgt Hand in Hand mit der Fachkraft für
                                Arbeitssicherheit, die Vorsorgeuntersuchungen finden auf Wunsch in einer mobilen Praxis,
                                bei Ihnen vor Ort statt.
                            </p>
                            <ul>
                                <li>Einführung von Arbeitsschutzmanagementsystemen inkl. Erstellung einer normkonformen
                                    Grunddokumentation und von Prozessbeschreibungen
                                </li>
                                <li>Durchführung von Audits zur Prozessoptimierung und zur Vorbereitung auf die
                                    Zertifizierung
                                </li>
                                <li>Stellung eines externen Sicherheitsingenieurs / einer externen Sicherheitsfachkraft
                                    gemäß Arbeitssicherheitsgesetz und DGUV Vorschrift 2
                                </li>
                                <li>Unterstützung bei der Erstellung von Gefährdungs- und Belastungsbeurteilungen
                                    (Ermittlung u.a. von psychischen, physischen Gefährdungen)
                                </li>
                                <li>Unterstützung bei der Erstellung von Betriebsanweisungen</li>
                                <li>Koordination von Partner- und Fremdfirmen</li>
                                <li>Arbeitsmedizinische Betreuung</li>
                                <li>Vorsorgeuntersuchungen vor Ort</li>
                            </ul>
                        </div>
                    </div>
                    <!--end teb pane-->

                    <!-- Maschinen- und Anlagensicherheit -->
                    <div class="tab-pane fade p-4 rounded shadow" id="machineandplantsafety" role="tabpanel"
                         aria-labelledby="machineandplantsafety">
                        <img src="assets/images/services/side-view-man-taking-notes-ambulance-car.jpg"
                             class="img-fluid rounded shadow"
                             alt=""/>
                        <div class="mt-4">
                            <h5>Maschinen- und Anlagensicherheit</h5>
                            <p class="text-muted">
                                Die BENSER GmbH bietet umfassende Beratungsdienstleistungen im Bereich Maschinen- und
                                Anlagensicherheit an. Unser erfahrenes Team von Experten unterstützt Sie gern dabei,
                                Anforderungen der Maschinenrichtlinie 2006/42/EG sowie der Betriebssicherheitsverordnung
                                in Ihrem Betrieb umzusetzen.
                            </p>
                            <p class="text-muted">
                                Wir begleiten Sie bei der Überprüfung der Einhaltung der Betriebssicherheitsverordnung
                                für Ihre Altmaschinen und entwickeln mit Ihnen gemeinsam praxisorientierte und
                                effiziente Lösungen für die Einhaltung der Sicherheit Ihrer Arbeitsplätze und
                                unterstützen Sie dabei den rechtlichen Anforderungen gerecht zu werden.
                            </p>
                            <p class="text-muted">
                                Bei Neubau von Maschinen, Umbaumaßnahmen sowie Zusammenschlüssen von Maschinen zu einer
                                Einheit ist man schnell im Bereich der Schaffung einer neuen Maschine. Wir unterstützen
                                Sie bei der Bewertung bereits in der Planungsphase und Überprüfen die Notwendigkeit
                                einer Einstufung als Neuanlage.
                            </p>
                            <p class='text-muted'>
                                Als „Inverkehrbringer“ ist der Unternehmer in diesem Fall dazu verpflichtet ein
                                Konformitätsbewertungsverfahren nach Anforderungen der Maschinenrichtlinie 2006/42/EG
                                für die neu geschaffene Maschine durchzuführen. Hierbei steht Ihnen die BENSER GmbH mit
                                Ihrer Expertise zur Seite und entwickelt für Sie die gesamten
                                Konformitätsbewertungsunterlagen inclusive einer Risikobeurteilung sowie
                                Betriebsanleitung für die neu erschaffene Maschine.
                            </p>
                            <ul>
                                <li>Beratung zur Einhaltung der Anforderungen aus der Maschinenrichtlinie und der
                                    Betriebssicherheitsverordnung
                                </li>
                                <li>Zusammenstellung / Überprüfung der technischen Dokumentation gemäß Anhang VII der
                                    MRL
                                </li>
                                <li>Erstellung von Betriebsanleitungen gemäß Maschinenrichtlinie 2006/42/EG / DIN EN ISO
                                    20607
                                </li>
                                <li>Durchführung von Risikobewertungen und Konformitätsbewertungsverfahren gemäß der
                                    Maschinenrichtlinie 2006/42/EG / DIN EN ISO 12100
                                </li>
                                <li>Hilfestellung bei der Beurteilung überwachungsbedürftigen Anlagen im Sinne der
                                    BetrSichV
                                </li>
                                <li>Beurteilung von Altmaschinen nach Anhang 1 der BetrSichV und Begleitung bei der
                                    Umsetzung von Maßnahmen
                                </li>
                                <li>Unterstützung und Dokumentation bei Änderungen von Maschinen und Anlagen (Bewertung
                                    wesentliche / nichtwesentliche Veränderung sowie neue Gesamtheiten von Maschinen)
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--end teb pane-->

                    <!-- Gefahrstoffmanagement -->
                    <div class="tab-pane fade p-4 rounded shadow" id="hazmatmanagement" role="tabpanel"
                         aria-labelledby="hazmatmanagement">
                        <img src="assets/images/services/fireprotectionregulationspanel.jpg"
                             class="img-fluid rounded shadow"
                             alt=""/>
                        <div class="mt-4">
                            <h6 class="text-dark">Gefahrstoffmanagement</h6>
                            <p class="text-muted">
                                Die BENSER GmbH bietet Ihrem Unternehmen durch ihre langjährige Erfahrung eine
                                professionelle Unterstützung bei der Umsetzung und Optimierung ihrer
                                Gefahrstoffmanagementsysteme.
                            </p>
                            <p class="text-muted">
                                Die Beratungsdienstleistungen der BENSER GmbH umfassen unter anderem die Analyse und
                                Bewertung der bestehenden Gefahrstoffmanagementpraktiken eines Unternehmens. Dabei
                                werden potenzielle Risiken identifiziert und geeignete Maßnahmen zur Risikominimierung
                                vorgeschlagen.
                            </p>
                            <p class="text-muted">
                                Des Weiteren unterstützt die BENSER GmbH bei der Erstellung und Aktualisierung von
                                Gefahrstoffverzeichnissen sowie bei der korrekten Kennzeichnung und Klassifizierung von
                                gefährlichen Stoffen gemäß den geltenden Vorschriften und Standards.
                            </p>
                            <p class="text-muted">
                                Wir unterstützen Sie gern bei der Weiterentwicklung Ihres bereits bestehenden
                                Gefahrstoffmanagementsystems.
                            </p>
                            <ul>
                                <li>Erstellung von Gefährdungsbeurteilungen nach Gefahrstoffverordnung</li>
                                <li>Erstellung von Betriebsanweisungen nach Gefahrstoffverordnung</li>
                                <li>Erstellung und Pflege von Gefahrstoffverzeichnissen</li>
                                <li>Einführung eines Gefahrstofffreigabesystems inkl. Begleitung / Organisation und
                                    Pflege
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--end teb pane-->

                    <!-- Brandschutz -->
                    <div class="tab-pane fade p-4 rounded shadow" id="fireprotection" role="tabpanel"
                         aria-labelledby="fireprotection">
                        <img src="assets/images/services/green-emergency-exit-sign-ceiling.jpg"
                             class="img-fluid rounded shadow" alt=""/>
                        <div class="mt-4">
                            <h6 class="text-dark">Brandschutz</h6>
                            <p class="text-muted">
                                „Es entspricht der Lebenserfahrung, dass mit der Entstehung eines Brandes praktisch
                                jederzeit gerechnet werden muss. Der Umstand, dass in vielen Gebäuden jahrzehntelang
                                kein Brand ausbricht, beweist nicht, dass keine Gefahr besteht, sondern stellt für die
                                Betroffenen einen Glücksfall dar, mit dessen Ende jederzeit gerechnet werden muss."
                                <br><br>
                                (OVG Münster, 10A 363/86 vom 11.12.1987)
                            </p>
                            <p class="text-center fw-bold">
                                Überlassen Sie den Brandschutz in Ihrem Betrieb nicht dem Zufall!
                            </p>
                            <p>
                                Die TEAM BENSER GmbH besteht aus Experten die sich schon seit Jahrzehnten für die
                                Bereiche des vorbeugenden und abwehrenden Brandschutzes begeistern. Die oberste
                                Priorität jedes Einzelnen im Team ist das vorhandene Wissen und die Erfahrung an unsere
                                Kunden weiterzugeben. Gemeinsam mit Ihnen entwickeln wir praxisorientierte, effektive
                                und effiziente Lösungen, welche speziell auf Ihre betrieblichen Gegebenheiten
                                zugeschnitten sind.
                            </p>
                            <ul>
                                <li>Stellung eines Brandschutzbeauftragten</li>
                                <li>Erstellung einer Brandschutzordnung (Teil A-C)</li>
                                <li>Erstellung von Flucht- und Rettungsplänen</li>
                                <li>Erstellung von Alarm- und Gefahrenabwehrplänen</li>
                                <li>Erstellung von Feuerwehrplänen</li>
                                <li>Erstellung und Einführung einer Evakuierungsorganisation</li>
                                <li>Erstellung der Gefährdungsbeurteilung im Rahmen des Brandschutzes</li>
                                <li>Erstellung, Überprüfung und Aktualisierung der Explosionsschutzdokumentation</li>
                                <li>Erstellung eines Katasters von explosions- und brandgefährdeten Bereichen</li>
                                <li>Auswahl von geeigneter persönlicher (Schutzausrüstung) Ausrüstung für den Einsatz in
                                    Explosionsgefährdeten Bereichen
                                </li>
                                <li>Planung von brandschutztechnischen Einrichtungen im Betrieb</li>
                            </ul>
                            <a routerLink="/plan-calculator" class="text-primary">Unverbindliche Kostenkalkulation
                                <i class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                    <!--end teb pane-->
                </div>
                <!--end tab content-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>