<!-- Hero Start -->
<section
        class="bg-half-170 d-table w-100"
        id="home"
        style="background: url('assets/images/2.jpg') center center"
>
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title text-white title-dark mb-0">
                        Kurse & Schulungen
                    </h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav
                    aria-label="breadcrumb"
                    class="d-inline-block"
            >
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">BENSER GmbH</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/services">Leistungen</a>
                    </li>
                    <li
                            class="breadcrumb-item active"
                            aria-current="page"
                    >
                        Kurse & Schulungen
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->

<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg
                viewBox="0 0 2880 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
        >
            <path
                    d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                    fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="section-title text-center mb-4 pb-2">
                    <h6 class="text-primary">Entfalte dein Potenzial:</h6>
                    <h4 class="title mb-4">Bildung als Schlüssel zur persönlichen und beruflichen
                        Weiterentwicklung.</h4>
                </div>
                <div class="d-flex flex-column align-items-center mx-5">
                    <p class="text-muted text-center mx-auto mb-0">
                        Die BENSER GmbH bietet Schulungen und Unterweisungen für Ihre Mitarbeiter an, um Sie
                        dabei zu unterstützen, die Sicherheit am Arbeitsplatz zu gewährleisten und gesetzliche
                        Vorschriften einzuhalten. Unsere Schulungen sind darauf ausgerichtet, Mitarbeitern das
                        erforderliche Wissen und die Fähigkeiten zu vermitteln, um potenzielle Gefahren zu
                        erkennen, angemessene Schutzmaßnahmen zu ergreifen und Unfälle zu vermeiden.
                        <br/><br/>
                        Die Schulungen werden an die spezifischen Anforderungen und Bedürfnisse des Unternehmens
                        angepasst, um einen maximalen Lernerfolg zu gewährleisten. Unsere erfahrenen Trainer
                        führen speziell auf Ihr Unternehmen zugeschnittene, praxisorientierte Schulungen zu
                        verschiedenen Themen durch.
                        <br><br>
                        Unser Ziel ist es Unternehmen dabei zu unterstützen ein sicheres Arbeitsumfeld zu
                        schaffen und das Sicherheitsbewusstsein Ihrer Mitarbeiter zu fördern.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row mt-5">
            <ul
                    class="container-filter list-inline mb-0 filter-options text-center"
                    id="filter"
            >
                <li class="list-inline-item categories-name border text-dark rounded"
                    (click)="activeCategory('all')"
                    [ngClass]="{ active: galleryFilter === 'all' }"
                >
                    Alle
                </li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                    (click)="activeCategory('Aus- & Weiterbildung')"
                    [ngClass]="{ active: galleryFilter === 'Aus- & Weiterbildung' }"
                >
                    Aus- & Weiterbildungen
                </li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                    (click)="activeCategory('Schulung')"
                    [ngClass]="{ active: galleryFilter === 'Schulung' }"
                >
                    Schulungen
                </li>
                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                    (click)="activeCategory('Ausbildung')"
                    [ngClass]="{ active: galleryFilter === 'Ausbildung' }"
                >
                    Ausbildungen
                </li>
            </ul>
        </div>
        <!--end row-->

        <div class="row projects-wrapper">
            <div
                    class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business"
                    *ngFor="let item of filterredImages"
            >
                <div
                        class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden"
                >
                    <img
                            src="{{ item.image }}"
                            class="img-fluid work-image"
                            alt=""
                    />
                    <div class="card-body">
                        <div class="content">
                            <a
                                    href="javascript:void(0)"
                                    class="badge badge-link bg-{{ item.variant }}"
                            >{{ item.type }}</a
                            >
                            <h5 class="mt-3">
                                <a
                                        routerLink="/page-case-detail"
                                        class="text-dark title"
                                >{{ item.title }}</a
                                >
                            </h5>
                            <p class="text-muted">{{ item.content }}</p>
                            <a
                                    routerLink="/contact-details"
                                    class="text-primary h6"
                            >Zum Angebot
                                <i
                                        class="uil uil-angle-right-b align-middle"
                                ></i
                                ></a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row mt-5">
            <div>
                Viele weitere Schulungen in Zusammenarbeit mit der <a href="https://www.eisenwerk-akademie.de">EISENWERK AKADEMIE</a>
            </div>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a
        href="javascript: void(0);"
        class="btn btn-icon btn-primary back-to-top"
        id="back-to-top"
        [ngxScrollTo]="'#home'"
>
    <i-feather
            name="arrow-up"
            class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
