import { Component } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ceil } from 'lodash';

@Component({
    selector: 'app-page-plan-calculator',
    templateUrl: './page-plan-calculator.component.html',
    styleUrls: ['./page-plan-calculator.component.css'],
})
export class PagePlanCalculatorComponent {
    private durationInMilliseconds = 2000;

    get duration(): number {
        return this.durationInMilliseconds;
    }

    // Set Topbar Option
    Menuoption = 'corporate';
    Settingicon = false;
    navClass = 'nav-light';

    // Tsepper settings
    firstFormGroup: FormGroup = this._formBuilder.group({
        objectArea: [0, Validators.required],
        floorNumber: [1, Validators.required],
    });
    secondFormGroup = this._formBuilder.group({
        numberOfPlans: [1, Validators.required],
    });
    showCalculator: boolean = false;
    styleCard1: boolean = false;
    styleCard2: boolean = false;
    isEditable = false;
    isLinear = true;
    typeOfPlan: string | undefined = undefined;
    showBuildingPlanGroup: boolean | undefined = undefined;
    kindOfDigitalPlan: string | undefined = undefined;      // DWG, PDF or Scan
    isDataSynchronizationDesired: boolean | undefined = undefined;
    isAssemblyDesired: boolean | undefined = undefined;
    result = 0;

    constructor(private _formBuilder: FormBuilder) {}

    reset() {
        this.result = 0;
        this.showBuildingPlanGroup = undefined;
        this.kindOfDigitalPlan = undefined;
        this.isDataSynchronizationDesired = undefined;
        this.isAssemblyDesired = undefined;
    }

    toggleStyleCard(index: number) {
        if(index === 1) {
            this.styleCard1 = false;
            this.styleCard2 = true;
            this.typeOfPlan = 'fire-department-plan';
            this.isAssemblyDesired = undefined;
        } else if(index === 2) {
            this.styleCard1 = true;
            this.styleCard2 = false;
            this.typeOfPlan = 'rescue-plan';
        }
    }

    calculateResult() {
        const objectArea = this.firstFormGroup.get('objectArea');
        const floorNumber = this.firstFormGroup.get('floorNumber');
        const numberOfPlans = this.secondFormGroup.get('numberOfPlans');

        if (!objectArea || !floorNumber || !numberOfPlans || !this.kindOfDigitalPlan) {
            return;
        }
        const objectAreaValue = objectArea.value;
        const floorNumberValue = floorNumber.value;
        const numberOfPlansValue = numberOfPlans.value;
        let basePrice: number = 119;

        switch (this.kindOfDigitalPlan) {
            case 'DWG':
                basePrice = 119;
                break;
            case 'PDF':
                basePrice = 139;
                break;
            case 'Scan':
                basePrice = 189;
                break;

            default:
                break;
        }

        if(objectAreaValue <= 0 || floorNumberValue <= 0) {
            this.result = 0;
            return;
        } else if(objectAreaValue <= 1000) {
            this.result = basePrice * floorNumberValue;
        } else {
            this.result = (basePrice + (ceil(((objectAreaValue - 1000) / 500)) * 25)) * floorNumberValue;
        }

        if(floorNumberValue > 0) {
            this.result += floorNumberValue * 99;
        }

        if(numberOfPlansValue && numberOfPlansValue > 1) {
            this.result += (numberOfPlansValue - 1) * 49;
        }

        if(this.isDataSynchronizationDesired) {
            this.result += 4 * 65;
        }

        if(this.isAssemblyDesired) {
            this.result += 2 * 55;
        }
    }

    selectDigitalPlan(value: string) {
        this.kindOfDigitalPlan = value;
        this.calculateResult();
    }
}
