<!-- Hero Start -->
<section
    class="bg-half-170 bg-light d-table w-100"
    id="home"
>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0">Footer</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav
                aria-label="breadcrumb"
                class="d-inline-block"
            >
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">Landrick</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0)">Page</a>
                    </li>
                    <li
                        class="breadcrumb-item active"
                        aria-current="page"
                    >
                        Footer
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #1</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer></app-footer>
<!-- Footer End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #2</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer [footerVariant]="footerTwo"></app-footer>
<!-- Footer End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #3</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer [footerVariant]="footerThree"></app-footer>
<!-- Footer End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #4</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer [footerVariant]="footerFour"></app-footer>
<!-- Footer End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #5</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer [footerVariant]="footerFive"></app-footer>
<!-- Footer End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #Light</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer [footerVariant]="footerSix"></app-footer>
<!-- Footer End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #7</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer [footerVariant]="footerSeven"></app-footer>
<!-- Footer End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-0">Footer #8</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer Start -->
<app-footer [footerVariant]="footerEight"></app-footer>
<!-- Footer End -->

<!-- Back to top -->
<a
    href="javascript: void(0);"
    class="btn btn-icon btn-primary back-to-top"
    id="back-to-top"
    [ngxScrollTo]="'#home'"
>
    <i-feather
        name="arrow-up"
        class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
