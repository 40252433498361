<div class="back-to-home rounded d-none d-sm-block">
    <a
        routerLink="/index"
        class="btn btn-icon btn-primary"
    >
        <i-feather
            name="arrow-left"
            class="icons"
        ></i-feather>
    </a>
</div>

<!-- MAINTENANCE PAGE -->
<section
    class="bg-home d-flex align-items-center"
    data-jarallax='{"speed": 0.5}'
    style="background-image: url('assets/images/maintenance.jpg')"
>
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 text-center">
                <a
                    href="javascript:void(0)"
                    class="logo h5"
                    ><img
                        src="assets/images/logo-light.png"
                        height="24"
                        alt=""
                /></a>
                <div
                    class="text-uppercase text-white title-dark mt-2 mb-4 maintenance"
                >
                    DIE SEITE WIRD GERADE GEWARTET
                </div>
                <p class="text-white-50 para-desc mx-auto para-dark">
                    Schauen Sie doch in der Zwischenzeit auf unserer Hauptseite
                    vorbei.
                </p>
                <p class='text-white-50 para-desc mx-auto para-dark'>
                    Oder kontaktieren Sie uns direkt auf unserer
                    <a
                        class="bg-white px-4 rounded"
                        href=""
                        routerLink="/contact-details"
                        >Kontaktseite</a
                    >
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <a
                    routerLink="/index"
                    class="btn btn-primary mt-4"
                    ><i class="mdi mdi-backup-restore"></i>Zurück zur
                    Hauptseite</a
                >
            </div>
        </div>
    </div>
    <!-- end container -->
</section>
<!--end section-->
<!-- MAINTENANCE PAGE -->
