import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css'],
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {
    // Set Topbar Option
    Menuoption = 'corporate';
    Settingicon = false;
    footerVariant = undefined;
    navClass = 'nav-light';

    /*** Filter Tab */
    filterredImages: any[] = [];
    galleryFilter = 'all';
    images = [
        {
            image: 'assets/images/construction/o1.jpg',
            category: 'offices',
            text: 'Iphone mockup',
            sub_text: 'offices',
        },
        {
            image: 'assets/images/construction/b1.jpg',
            category: 'buildings',
            text: 'Mockup Collection',
            sub_text: 'offices',
        },
        {
            image: 'assets/images/construction/r1.jpg',
            category: 'roads',
            text: 'Abstract images',
            sub_text: 'Abstract',
        },
        {
            image: 'assets/images/construction/b2.jpg',
            category: 'buildings',
            text: 'Yellow bg with Books',
            sub_text: 'Books',
        },
        {
            image: 'assets/images/construction/r2.jpg',
            category: 'roads',
            text: 'Company V-card',
            sub_text: 'V-card',
        },
        {
            image: 'assets/images/construction/o2.jpg',
            category: 'offices',
            text: 'Mockup box with paints',
            sub_text: 'Photography',
        },
    ];

    /**
     * Blog Data
     */
    blogData = [
        {
            image: 'assets/images/construction/o1.jpg',
            title: 'High quality work for demand our customer.',
            like: '33',
            message: '08',
            name: 'Calvin Carlo',
            date: '13th August, 2019',
        },
        {
            image: 'assets/images/construction/b1.jpg',
            title: 'Building public support for a severige work bond',
            like: '33',
            message: '08',
            name: 'Calvin Carlo',
            date: '13th August, 2019',
        },
        {
            image: 'assets/images/construction/r1.jpg',
            title: 'Satisfection for the customer our first parity.',
            like: '33',
            message: '08',
            name: 'Calvin Carlo',
            date: '13th August, 2019',
        },
    ];
    developmentForm!: UntypedFormGroup;
    submitted = false;

    /**
     * Partners slider
     */
    customOptions: OwlOptions = {
        // loop: true,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        // autoplay: true,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            740: {
                items: 3,
            },
            940: {
                items: 3,
            },
        },
        nav: false,
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalService: NgbModal,
        private router: Router
    ) {}

    ngOnInit(): void {
        /**
         * Form Validatyion
         */
        this.developmentForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
        this.filterredImages = this.images;
    }

    // convenience getter for easy access to form fields
    get form() {
        return this.developmentForm.controls;
    }

    /**
     * On submit form
     */
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.developmentForm.invalid) {
            return;
        }
    }

    /**
     * Open modal for show the video
     * @param content content of modal
     */
    openWindowCustomClass(content: any) {
        this.modalService.open(content, {
            windowClass: 'dark-modal',
            size: 'lg',
            centered: true,
        });
    }

    /***
     * Filter Active Class Add
     */
    activeCategory(category: string) {
        this.galleryFilter = category;
        if (this.galleryFilter === 'all') {
            this.filterredImages = this.images;
        } else {
            this.filterredImages = this.images.filter(
                (x) => x.category === this.galleryFilter
            );
        }
    }
}
