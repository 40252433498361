import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgxTypedJsModule } from 'ngx-typed-js';
// import { FlatpickrModule } from 'angularx-flatpickr';
// import { NgxMasonryModule } from 'ngx-masonry';
// import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from './shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { AccountMembersComponent } from './core/components/account-members/account-members.component';
// import { AccountMessagesComponent } from './core/components/account-messages/account-messages.component';
// import { LoginComponent } from './auth/login/login.component';
// import { RegistrationComponent } from './auth/registration/registration.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
// import { AccountPaymentsComponent } from './core/components/account-payments/account-payments.component';
import { AccountProfileComponent } from './core/components/account-profile/account-profile.component';
// import { AccountSettingComponent } from './core/components/account-setting/account-setting.component';
// import { AccountWorksComponent } from './core/components/account-works/account-works.component';
// import { AuthLoginThreeComponent } from './auth/auth-login-three/auth-login-three.component';
// import { AuthRePasswordThreeComponent } from './auth/auth-re-password-three/auth-re-password-three.component';
// import { AuthSignupThreeComponent } from './auth/auth-signup-three/auth-signup-three.component';
// import { ChangelogComponent } from './core/components/changelog/changelog.component';
import { EmailAlertComponent } from './email/email-alert/email-alert.component';
import { EmailConfirmationComponent } from './email/email-confirmation/email-confirmation.component';
import { EmailInvoiceComponent } from './email/email-invoice/email-invoice.component';
// import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
import { IndexComponent } from './core/components/index/index.component';
import { PageComingsoonComponent } from './core/components/page-comingsoon/page-comingsoon.component';
import { PageContactDetailComponent } from './core/components/page-contact-detail/page-contact-detail.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { PageHistoryComponent } from './core/components/page-history/page-history.component';
import { PageInvoiceComponent } from './core/components/page-invoice/page-invoice.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { FeatherModule } from 'angular-feather';

// Apex chart
// import { NgApexchartsModule } from 'ng-apexcharts';
import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageThankyouComponent } from './core/components/page-thankyou/page-thankyou.component';
import { CorporateAboutComponent } from './core/components/page-about/corporate-about.component';
import { CorporateServicesComponent } from './core/components/page-services/corporate-services.component';
import { CorporateTeamComponent } from './core/components/page-team/corporate-team.component';
import { CorporatePricingComponent } from './core/components/page-pricing/corporate-pricing.component';
// import { CorporateBlogComponent } from './core/components/page-blog/corporate-blog.component';
// import { CorporateBlogDetailComponent } from './core/components/page-blog-detail/corporate-blog-detail.component';
import { PageFooterComponent } from './core/components/page-footer/page-footer.component';
// import { AccountChatComponent } from './core/components/account-chat/account-chat.component';
import { PageImpressumComponent } from './core/components/page-impressum/page-impressum.component';
import { PageCasesComponent } from './core/components/page-cases/page-cases.component';
// import { PageCaseDetailComponent } from './core/components/page-case-detail/page-case-detail.component';
import { PagePlanCalculatorComponent } from './core/components/page-plan-calculator/page-plan-calculator.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { CountUpDirective } from './core/directives/count-up.directive';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        // AccountMembersComponent,
        // AccountMessagesComponent,
        // LoginComponent,
        // RegistrationComponent,
        MasterPageComponent,
        // AccountPaymentsComponent,
        AccountProfileComponent,
        // AccountSettingComponent,
        // AccountWorksComponent,
        // AuthLoginThreeComponent,
        // AuthRePasswordThreeComponent,
        // AuthSignupThreeComponent,
        // ChangelogComponent,
        EmailAlertComponent,
        EmailConfirmationComponent,
        EmailInvoiceComponent,
        // EmailPasswordResetComponent,
        IndexComponent,
        PageComingsoonComponent,
        PageContactDetailComponent,
        PageErrorComponent,
        PageHistoryComponent,
        PageInvoiceComponent,
        PageMaintenanceComponent,
        PagePrivacyComponent,
        PageTermsComponent,
        PageThankyouComponent,
        CorporateAboutComponent,
        CorporateServicesComponent,
        CorporateTeamComponent,
        CorporatePricingComponent,
        // CorporateBlogComponent,
        // CorporateBlogDetailComponent,
        PageFooterComponent,
        // AccountChatComponent,
        PageImpressumComponent,
        PageCasesComponent,
        // PageCaseDetailComponent,
        PagePlanCalculatorComponent,
        CountUpDirective,
    ],
    imports: [
        // NgApexchartsModule,
        // NgxTypedJsModule,
        // FlatpickrModule.forRoot(),
        // NgxMasonryModule,
        // SimplebarAngularModule,
        ScrollToModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        RouterModule,
        CarouselModule,
        FeatherModule.pick(allIcons),
        RouterModule.forRoot([], { anchorScrolling: 'enabled' }),
        NgbDropdownModule,
        NgbModule,
        NgbNavModule,
        FormsModule,
        ReactiveFormsModule,
        LightboxModule,
        SharedModule,
        MatStepperModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [FeatherModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
