import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-cases',
    templateUrl: './page-cases.component.html',
    styleUrls: ['./page-cases.component.css'],
})

/**
 * Page Cases Component
 */
export class PageCasesComponent implements OnInit {
    // Set Topbar Option
    Menuoption = 'corporate';
    Settingicon = false;
    /**
     * Nav Light Class Add
     */
    navClass = 'nav-light';
    filterredImages: any;
    galleryFilter = 'all';

    caseList = [
        {
            image: 'assets/images/course/firesafetyhelpers.jpg',
            title: 'Brandschutz- und Evakuierungshelfer',
            variant: 'primary',
            type: 'Aus- & Weiterbildung',
            content:
                'Was tust du, wenn es brennt? Schon Brandschutzhelfer?',
        },
        {
            image: 'assets/images/freepik/group-diverse-people-cpr-training-class-min.jpg',
            title: 'Ersthelfer',
            variant: 'primary',
            type: 'Ausbildung',
            content:
                'Im Notfall entscheidet Wissen, Erste Hilfe zu leisten – sei der Unterschied, der Leben rettet!',
        },
        {
            image: 'assets/images/freepik/mid-adult-businessman-giving-presentation-group-industrial-workers-factory-min.jpg',
            title: 'Sicherheitsbeauftragte',
            variant: 'primary',
            type: 'Aus- & Weiterbildung',
            content:
                'Sicherheit gestalten, Risiken minimieren: Werde zum Hüter der Prävention als Sicherheitsbeauftragter!',
        },
        {
            image: 'assets/images/freepik/accident-warehouse-man-floor-min.jpg',
            title: 'Unterweisungen nach DGUV V1 (bisher BGV A1)',
            variant: 'primary',
            type: 'Unterweisung',
            content:
                'Unterweisung schon aktualisiert und wiederholt?',
        },
        {
            image: 'assets/images/freepik/team-job-photo-young-businessmans-working-with-new-project-office-min.jpg',
            title: 'Führungskräfteschulung',
            variant: 'primary',
            type: 'Schulung',
            content:
                'Erfolg beginnt mit Führung. Entfalte dein Führungspotenzial in unserer Schulung und gestalte eine inspirierende Zukunft für dein Team und Unternehmen.',
        }
    ];

    constructor() {}

    ngOnInit(): void {
        this.filterredImages = this.caseList;
    }

    /**
     * Filtering All Record Get
     */
    activeCategory(category: string) {
        this.galleryFilter = category;
        if (this.galleryFilter === 'all') {
            this.filterredImages = this.caseList;
        } else {
            this.filterredImages = this.caseList.filter(
                (x) => x.type === this.galleryFilter
            );
        }
    }
}
