<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
        [ngClass]="{'tagline-height': sliderTopbar == true}">
    <div class="container">
        <!-- Logo container-->
        <div>
            <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
                <!--                ****** dark logo ******-->
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 40 40"
                     height="40"
                     width="40"
                     id="svg3"
                     class="l-dark"
                >
                    <g transform="matrix(0.3333333,0,0,-0.3333333,0,39.333333)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 588.75,1180 c -79.84,0 -156.25,-15.43 -229.18,-46.25 -39.84,-16.87 -77.07,-37.62 -111.761,-62.3 h 44.031 c 24.488,15.15 50.23,28.43 77.219,39.84 69.921,29.57 143.132,44.33 219.691,44.33 76.559,0 149.77,-14.76 219.691,-44.33 26.989,-11.41 52.731,-24.69 77.219,-39.84 h 44.031 C 895,1096.13 857.73,1116.88 817.93,1133.75 745,1164.57 668.59,1180 588.75,1180 Z"/>
                            <path id="path16"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 135.738,138.238 H 1041.76 V 1044.26 H 135.738 V 961.879 H 959.379 V 220.621 H 135.738 Z"/>
                            <path id="path18"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 1177.5,591.25 c 0,79.84 -15.43,156.25 -46.29,229.18 -16.83,39.8 -37.58,77.07 -62.26,111.761 V 888.16 c 15.15,-24.488 28.43,-50.23 39.84,-77.219 29.57,-69.921 44.33,-143.132 44.33,-219.691 0,-76.559 -14.76,-149.77 -44.33,-219.691 -11.41,-26.989 -24.69,-52.731 -39.84,-77.219 v -44.031 c 24.68,34.691 45.43,71.961 62.26,111.761 30.86,72.93 46.29,149.34 46.29,229.18 z"/>
                            <path id="path20"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 0,591.25 C 0,511.41 15.4297,435 46.25,362.07 63.1211,322.27 83.8711,285 108.551,250.309 V 294.34 C 93.3984,318.828 80.1211,344.57 68.7109,371.559 39.1406,441.48 24.3398,514.691 24.3398,591.25 c 0,76.559 14.8008,149.77 44.3711,219.691 11.4102,26.989 24.6875,52.688 39.8401,77.219 v 44.031 C 83.8711,897.5 63.1211,860.23 46.25,820.43 15.4297,747.5 0,671.09 0,591.25 Z"/>
                            <path id="path22"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,632.34 c 30.242,0.121 709.184,0.121 741.211,0.121 V 879.488 H 135.738 V 797.109 H 794.609 V 714.73 H 135.738 Z"/>
                            <path id="path24"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,302.93 c 30.242,0.082 709.184,0.082 741.211,0.082 V 550.039 H 135.738 v -82.34 H 794.609 V 385.27 H 135.738 Z"/>
                            <path id="path26"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 817.93,48.7891 C 745,17.9297 668.59,2.5 588.75,2.5 508.91,2.5 432.5,17.9297 359.57,48.7891 319.73,65.6211 282.5,86.3711 247.809,111.051 h 44.031 c 24.488,-15.1526 50.23,-28.4299 77.219,-39.8401 69.921,-29.5703 143.132,-44.332 219.691,-44.332 76.559,0 149.77,14.7617 219.691,44.332 26.989,11.4102 52.731,24.6875 77.219,39.8401 h 44.031 C 895,86.3711 857.73,65.6211 817.93,48.7891 Z"/>
                        </g>
                    </g>
                </svg>
                <svg xmlns:svg="http://www.w3.org/2000/svg"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 253 22"
                     height="22"
                     width="253"
                     id="svg4"
                     class="l-dark ml-2"
                >
                    <g transform="matrix(0.8,0,0,-0.8,0,23.5)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 262.578,71.2109 c 0,-5.8593 -1.019,-12.1523 -3.008,-18.8711 -2.031,-6.7187 -5.308,-12.9609 -9.922,-18.7109 -4.57,-5.7383 -10.507,-10.5078 -17.847,-14.3672 -7.352,-3.832 -16.332,-5.7812 -26.961,-5.7812 H 23.9805 c -3.3907,0 -6.5586,0.6211 -9.4922,1.8281 -2.9297,1.2109 -5.46877,2.8906 -7.60939,5.043 C 4.73047,22.5 3.05078,25.0391 1.83984,27.9688 0.621094,30.8984 0,34.0586 0,37.5 v 214.73 c 0,3.321 0.621094,6.442 1.83984,9.372 1.21094,2.929 2.89063,5.468 5.03907,7.699 2.14062,2.179 4.67969,3.898 7.60939,5.109 2.9336,1.211 6.1015,1.84 9.4922,1.84 H 183.441 c 5.86,0 12.149,-1.051 18.86,-3.129 6.719,-2.07 12.969,-5.391 18.75,-9.961 5.828,-4.609 10.668,-10.539 14.5,-17.89 3.859,-7.301 5.777,-16.29 5.777,-26.911 v -8.98 c 0,-8.438 -1.406,-17.5 -4.219,-27.231 -2.808,-9.687 -7.339,-18.91 -13.558,-27.578 5.359,-3.281 10.398,-7.269 15.121,-11.91 4.687,-4.648 8.828,-10 12.348,-16.101 3.55,-6.129 6.359,-13.039 8.429,-20.739 2.071,-7.6989 3.129,-16.1716 3.129,-25.4684 z m -47.656,9.1407 c 0,6.25 -0.973,11.8398 -2.934,16.7968 -1.949,4.9216 -4.687,9.1796 -8.238,12.7306 -3.52,3.519 -7.809,6.25 -12.809,8.133 -5,1.91 -10.632,2.847 -16.882,2.847 H 66.6797 v 48.012 h 85.9413 c 6.25,0 11.867,0.93 16.867,2.848 5,1.871 9.301,4.609 12.813,8.16 3.558,3.519 6.289,7.781 8.168,12.742 1.91,4.918 2.851,10.5 2.851,16.758 v 8.98 c 0,6.84 -3.32,10.231 -9.879,10.231 H 47.6602 V 61.1289 H 204.84 c 0.859,0 1.922,0.043 3.129,0.1992 1.211,0.1211 2.34,0.4727 3.32,1.0938 0.981,0.5898 1.801,1.6367 2.539,3.0898 0.742,1.4766 1.094,3.5078 1.094,6.0469 z" />
                            <path id="path16"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="M 520.039,120.859 H 369.57 v 48.0117 H 520.039 Z M 541.449,13.4805 H 369.57 c -6.558,0 -13.789,1.1679 -21.601,3.4687 -7.809,2.3516 -15.078,6.1719 -21.719,11.4922 -6.641,5.3086 -12.23,12.1797 -16.68,20.6992 -4.449,8.4805 -6.679,18.9102 -6.679,31.211 V 252.23 c 0,3.321 0.629,6.442 1.839,9.372 1.211,2.929 2.891,5.468 5.04,7.699 2.14,2.179 4.64,3.898 7.609,5.109 2.93,1.211 6.101,1.84 9.5,1.84 h 214.57 V 228.59 H 350.551 V 80.3516 c 0,-6.211 1.64,-10.9727 4.918,-14.2618 3.32,-3.3203 8.121,-4.9609 14.492,-4.9609 h 171.488 z" />
                            <path id="path18"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 850.039,33.8281 c 0,-3.4375 -0.66,-6.5976 -1.949,-9.5273 -1.25,-2.9297 -2.969,-5.4727 -5.121,-7.6211 -2.149,-2.1094 -4.649,-3.7891 -7.617,-5.0391 -2.93,-1.2109 -6.012,-1.83982 -9.332,-1.83982 -2.93,0 -5.899,0.58982 -8.872,1.67972 -3.007,1.0976 -5.668,2.8593 -7.968,5.3203 L 635.078,198.551 V 13.4805 H 587.461 V 255.898 c 0,4.883 1.367,9.332 4.098,13.282 2.781,3.98 6.293,6.949 10.55,8.91 4.532,1.832 9.18,2.262 13.942,1.359 4.769,-0.898 8.828,-3.16 12.269,-6.679 L 802.379,91.1719 V 276.25 h 47.66 z" />
                            <path id="path20"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1157.85,90.8203 c 0,-9.4101 -1.17,-17.8906 -3.55,-25.3906 -2.39,-7.5 -5.55,-14.1406 -9.46,-19.8789 -3.9,-5.75 -8.47,-10.6289 -13.75,-14.6524 -5.23,-4.0195 -10.66,-7.3476 -16.29,-9.8789 -5.62,-2.5781 -11.32,-4.5 -17.14,-5.7109 -5.78,-1.207 -11.25,-1.8281 -16.37,-1.8281 H 897.5 v 47.6484 h 183.79 c 9.14,0 16.25,2.6992 21.33,8.0508 5.08,5.3906 7.61,12.582 7.61,21.6406 0,4.3789 -0.66,8.4414 -2.03,12.0707 -1.32,3.668 -3.28,6.879 -5.78,9.531 -2.5,2.699 -5.54,4.769 -9.14,6.25 -3.59,1.449 -7.62,2.187 -11.99,2.187 H 971.68 c -7.7,0 -15.981,1.371 -24.918,4.141 -8.91,2.73 -17.192,7.148 -24.813,13.281 -7.648,6.098 -13.98,14.098 -19.058,23.989 -5.082,9.921 -7.621,21.992 -7.621,36.281 0,14.301 2.539,26.371 7.621,36.179 5.078,9.84 11.41,17.848 19.058,24.02 7.621,6.172 15.903,10.629 24.813,13.359 8.937,2.77 17.218,4.141 24.918,4.141 h 162.19 V 228.59 H 971.68 c -9.02,0 -16.09,-2.731 -21.129,-8.238 -5.082,-5.473 -7.621,-12.774 -7.621,-21.801 0,-9.172 2.539,-16.403 7.621,-21.711 5.039,-5.32 12.109,-7.969 21.129,-7.969 h 109.96 c 5.12,-0.121 10.55,-0.82 16.29,-2.109 5.74,-1.293 11.48,-3.242 17.15,-5.942 5.66,-2.699 11.09,-6.09 16.21,-10.199 5.16,-4.062 9.69,-8.98 13.67,-14.723 3.95,-5.738 7.11,-12.347 9.42,-19.808 2.34,-7.461 3.47,-15.86 3.47,-25.2697 z" />
                            <path id="path22"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1413.48,120.859 h -150.43 v 48.0117 h 150.43 z m 21.44,-107.3785 h -171.87 c -6.6,0 -13.83,1.1679 -21.64,3.4687 -7.82,2.3516 -15.04,6.1719 -21.72,11.4922 -6.64,5.3086 -12.19,12.1797 -16.64,20.6992 -4.5,8.4805 -6.72,18.9102 -6.72,31.211 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 214.57 V 228.59 H 1243.98 V 80.3516 c 0,-6.211 1.64,-10.9727 4.97,-14.2618 3.28,-3.3203 8.12,-4.9609 14.45,-4.9609 h 171.52 z" />
                            <path id="path24"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1743.48,187.93 c 0,-10.86 -1.33,-20.59 -4.03,-29.141 -2.69,-8.559 -6.33,-16.09 -10.9,-22.617 -4.57,-6.563 -9.88,-12.152 -15.85,-16.762 -5.98,-4.64 -12.23,-8.469 -18.79,-11.48 -6.53,-2.969 -13.13,-5.16 -19.77,-6.481 -6.68,-1.371 -12.93,-2.0388 -18.79,-2.0388 l 99.49,-85.9297 h -73.67 l -99.29,85.9297 h -34.26 V 147.07 h 107.73 c 5.98,0.469 11.45,1.719 16.41,3.75 4.92,2.032 9.22,4.731 12.81,8.16 3.59,3.399 6.41,7.54 8.36,12.348 1.95,4.844 2.93,10.352 2.93,16.602 v 30.039 c 0,2.691 -0.35,4.73 -1.02,6.133 -0.66,1.41 -1.52,2.457 -2.57,3.128 -1.02,0.661 -2.15,1.051 -3.29,1.211 -1.17,0.11 -2.22,0.149 -3.2,0.149 H 1528.55 V 13.4805 H 1480.9 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.22,2.929 2.89,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 180.86 c 10.63,0 19.61,-1.91 26.92,-5.781 7.34,-3.828 13.28,-8.668 17.89,-14.449 4.57,-5.821 7.85,-12.071 9.88,-18.79 1.99,-6.718 3.01,-13.011 3.01,-18.871 z" />
                            <path id="path26"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2172.62,37.5 c 0,-3.4414 -0.63,-6.6016 -1.84,-9.5312 -1.21,-2.9297 -2.89,-5.4688 -5.04,-7.6172 -2.15,-2.1524 -4.65,-3.8321 -7.58,-5.043 -2.93,-1.207 -6.13,-1.8281 -9.53,-1.8281 h -171.87 c -4.42,0 -9.14,0.539 -14.22,1.5586 -5.08,1.0507 -10.08,2.6601 -15.04,4.8398 -4.92,2.2305 -9.65,5.043 -14.18,8.5625 -4.53,3.4688 -8.52,7.6875 -11.99,12.6094 -3.48,4.9687 -6.29,10.7109 -8.36,17.2305 -2.07,6.5195 -3.13,13.9101 -3.13,22.0703 V 209.379 c 0,4.371 0.55,9.141 1.57,14.172 1.05,5.078 2.65,10.078 4.88,15.039 2.19,4.961 5.04,9.691 8.59,14.18 3.56,4.531 7.78,8.558 12.74,12.031 4.96,3.48 10.66,6.25 17.15,8.32 6.44,2.078 13.78,3.129 21.99,3.129 h 193.67 v -47.66 h -193.67 c -6.25,0 -11.02,-1.641 -14.3,-4.918 -3.32,-3.32 -4.96,-8.203 -4.96,-14.692 V 80.3516 c 0,-6.0899 1.68,-10.8203 5.04,-14.1797 3.36,-3.3633 8.08,-5.043 14.22,-5.043 H 2125 v 59.7301 h -126.84 v 48.012 h 150.47 c 3.4,0 6.6,-0.633 9.53,-1.922 2.93,-1.289 5.43,-3.039 7.58,-5.23 2.15,-2.188 3.83,-4.731 5.04,-7.617 1.21,-2.852 1.84,-5.942 1.84,-9.223 z" />
                            <path id="path28"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2528.09,13.4805 h -48.01 V 169.961 L 2395.62,21.3711 c -2.07,-3.793 -5,-6.6406 -8.71,-8.6016 -3.71,-1.9882 -7.73,-2.96872 -11.99,-2.96872 -4.18,0 -8.04,0.98052 -11.64,2.96872 -3.59,1.961 -6.44,4.8086 -8.51,8.6016 L 2269.92,169.961 V 13.4805 h -47.65 V 255.898 c 0,5.512 1.64,10.391 4.88,14.692 3.24,4.25 7.46,7.109 12.73,8.59 2.54,0.621 5.12,0.82 7.7,0.621 2.54,-0.153 5.04,-0.699 7.42,-1.641 2.38,-0.89 4.53,-2.18 6.48,-3.82 1.96,-1.68 3.6,-3.641 4.97,-5.981 L 2374.92,79.8008 2483.4,268.359 c 2.81,4.653 6.68,7.93 11.64,9.922 4.92,1.949 10.08,2.231 15.47,0.899 5.11,-1.481 9.33,-4.34 12.65,-8.59 3.29,-4.301 4.93,-9.18 4.93,-14.692 z" />
                            <path id="path30"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2845.66,71.2109 c 0,-5.8593 -1.01,-12.1523 -3.04,-18.8711 -2,-6.7187 -5.32,-12.9609 -9.89,-18.7109 -4.57,-5.7383 -10.54,-10.5078 -17.89,-14.3672 -7.3,-3.832 -16.29,-5.7812 -26.91,-5.7812 h -180.86 c -3.4,0 -6.6,0.6211 -9.53,1.8281 -2.93,1.2109 -5.47,2.8906 -7.62,5.043 -2.11,2.1484 -3.79,4.6875 -5.04,7.6172 -1.21,2.9296 -1.79,6.0898 -1.79,9.5312 v 214.73 c 0,3.321 0.58,6.442 1.79,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.13,1.84 9.53,1.84 h 159.41 c 5.86,0 12.15,-1.051 18.87,-3.129 6.72,-2.07 13.01,-5.391 18.79,-9.961 5.82,-4.609 10.63,-10.539 14.49,-17.89 3.83,-7.301 5.75,-16.29 5.75,-26.911 v -8.98 c 0,-8.438 -1.41,-17.5 -4.18,-27.231 -2.82,-9.687 -7.35,-18.91 -13.6,-27.578 5.39,-3.281 10.43,-7.269 15.12,-11.91 4.73,-4.648 8.83,-10 12.38,-16.101 3.56,-6.129 6.37,-13.039 8.44,-20.739 2.07,-7.6989 3.12,-16.1716 3.12,-25.4684 z m -47.65,9.1407 c 0,6.25 -0.98,11.8398 -2.93,16.7968 -1.96,4.9216 -4.73,9.1796 -8.24,12.7306 -3.56,3.519 -7.82,6.25 -12.86,8.133 -5,1.91 -10.62,2.847 -16.83,2.847 h -107.38 v 48.012 h 85.93 c 6.25,0 11.84,0.93 16.88,2.848 5,1.871 9.26,4.609 12.81,8.16 3.56,3.519 6.25,7.781 8.16,12.742 1.88,4.918 2.82,10.5 2.82,16.758 v 8.98 c 0,6.84 -3.28,10.231 -9.89,10.231 H 2630.7 V 61.1289 h 157.23 c 0.86,0 1.87,0.043 3.12,0.1992 1.22,0.1211 2.31,0.4727 3.29,1.0938 0.97,0.5898 1.83,1.6367 2.57,3.0898 0.75,1.4766 1.1,3.5078 1.1,6.0469 z" />
                            <path id="path32"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2935.08,168.871 h 167.26 V 276.25 H 3150 V 13.4805 h -47.66 V 120.859 H 2935.08 V 13.4805 h -47.66 V 276.25 h 47.66 z" />
                        </g>
                    </g>
                </svg>

                <!--                ****** light logo ******-->
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 40 40"
                     height="40"
                     width="40"
                     id="svg3"
                     class="l-light"
                >
                    <g transform="matrix(0.3333333,0,0,-0.3333333,0,39.333333)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 588.75,1180 c -79.84,0 -156.25,-15.43 -229.18,-46.25 -39.84,-16.87 -77.07,-37.62 -111.761,-62.3 h 44.031 c 24.488,15.15 50.23,28.43 77.219,39.84 69.921,29.57 143.132,44.33 219.691,44.33 76.559,0 149.77,-14.76 219.691,-44.33 26.989,-11.41 52.731,-24.69 77.219,-39.84 h 44.031 C 895,1096.13 857.73,1116.88 817.93,1133.75 745,1164.57 668.59,1180 588.75,1180 Z"/>
                            <path id="path16"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 135.738,138.238 H 1041.76 V 1044.26 H 135.738 V 961.879 H 959.379 V 220.621 H 135.738 Z"/>
                            <path id="path18"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 1177.5,591.25 c 0,79.84 -15.43,156.25 -46.29,229.18 -16.83,39.8 -37.58,77.07 -62.26,111.761 V 888.16 c 15.15,-24.488 28.43,-50.23 39.84,-77.219 29.57,-69.921 44.33,-143.132 44.33,-219.691 0,-76.559 -14.76,-149.77 -44.33,-219.691 -11.41,-26.989 -24.69,-52.731 -39.84,-77.219 v -44.031 c 24.68,34.691 45.43,71.961 62.26,111.761 30.86,72.93 46.29,149.34 46.29,229.18 z"/>
                            <path id="path20"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 0,591.25 C 0,511.41 15.4297,435 46.25,362.07 63.1211,322.27 83.8711,285 108.551,250.309 V 294.34 C 93.3984,318.828 80.1211,344.57 68.7109,371.559 39.1406,441.48 24.3398,514.691 24.3398,591.25 c 0,76.559 14.8008,149.77 44.3711,219.691 11.4102,26.989 24.6875,52.688 39.8401,77.219 v 44.031 C 83.8711,897.5 63.1211,860.23 46.25,820.43 15.4297,747.5 0,671.09 0,591.25 Z"/>
                            <path id="path22"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,632.34 c 30.242,0.121 709.184,0.121 741.211,0.121 V 879.488 H 135.738 V 797.109 H 794.609 V 714.73 H 135.738 Z"/>
                            <path id="path24"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,302.93 c 30.242,0.082 709.184,0.082 741.211,0.082 V 550.039 H 135.738 v -82.34 H 794.609 V 385.27 H 135.738 Z"/>
                            <path id="path26"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 817.93,48.7891 C 745,17.9297 668.59,2.5 588.75,2.5 508.91,2.5 432.5,17.9297 359.57,48.7891 319.73,65.6211 282.5,86.3711 247.809,111.051 h 44.031 c 24.488,-15.1526 50.23,-28.4299 77.219,-39.8401 69.921,-29.5703 143.132,-44.332 219.691,-44.332 76.559,0 149.77,14.7617 219.691,44.332 26.989,11.4102 52.731,24.6875 77.219,39.8401 h 44.031 C 895,86.3711 857.73,65.6211 817.93,48.7891 Z"/>
                        </g>
                    </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 253 22"
                     height="22"
                     width="253"
                     id="svg4"
                     class="l-light ml-2"
                >
                    <g transform="matrix(0.8,0,0,-0.8,0,23.5)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 262.578,71.2109 c 0,-5.8593 -1.019,-12.1523 -3.008,-18.8711 -2.031,-6.7187 -5.308,-12.9609 -9.922,-18.7109 -4.57,-5.7383 -10.507,-10.5078 -17.847,-14.3672 -7.352,-3.832 -16.332,-5.7812 -26.961,-5.7812 H 23.9805 c -3.3907,0 -6.5586,0.6211 -9.4922,1.8281 -2.9297,1.2109 -5.46877,2.8906 -7.60939,5.043 C 4.73047,22.5 3.05078,25.0391 1.83984,27.9688 0.621094,30.8984 0,34.0586 0,37.5 v 214.73 c 0,3.321 0.621094,6.442 1.83984,9.372 1.21094,2.929 2.89063,5.468 5.03907,7.699 2.14062,2.179 4.67969,3.898 7.60939,5.109 2.9336,1.211 6.1015,1.84 9.4922,1.84 H 183.441 c 5.86,0 12.149,-1.051 18.86,-3.129 6.719,-2.07 12.969,-5.391 18.75,-9.961 5.828,-4.609 10.668,-10.539 14.5,-17.89 3.859,-7.301 5.777,-16.29 5.777,-26.911 v -8.98 c 0,-8.438 -1.406,-17.5 -4.219,-27.231 -2.808,-9.687 -7.339,-18.91 -13.558,-27.578 5.359,-3.281 10.398,-7.269 15.121,-11.91 4.687,-4.648 8.828,-10 12.348,-16.101 3.55,-6.129 6.359,-13.039 8.429,-20.739 2.071,-7.6989 3.129,-16.1716 3.129,-25.4684 z m -47.656,9.1407 c 0,6.25 -0.973,11.8398 -2.934,16.7968 -1.949,4.9216 -4.687,9.1796 -8.238,12.7306 -3.52,3.519 -7.809,6.25 -12.809,8.133 -5,1.91 -10.632,2.847 -16.882,2.847 H 66.6797 v 48.012 h 85.9413 c 6.25,0 11.867,0.93 16.867,2.848 5,1.871 9.301,4.609 12.813,8.16 3.558,3.519 6.289,7.781 8.168,12.742 1.91,4.918 2.851,10.5 2.851,16.758 v 8.98 c 0,6.84 -3.32,10.231 -9.879,10.231 H 47.6602 V 61.1289 H 204.84 c 0.859,0 1.922,0.043 3.129,0.1992 1.211,0.1211 2.34,0.4727 3.32,1.0938 0.981,0.5898 1.801,1.6367 2.539,3.0898 0.742,1.4766 1.094,3.5078 1.094,6.0469 z" />
                            <path id="path16"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="M 520.039,120.859 H 369.57 v 48.0117 H 520.039 Z M 541.449,13.4805 H 369.57 c -6.558,0 -13.789,1.1679 -21.601,3.4687 -7.809,2.3516 -15.078,6.1719 -21.719,11.4922 -6.641,5.3086 -12.23,12.1797 -16.68,20.6992 -4.449,8.4805 -6.679,18.9102 -6.679,31.211 V 252.23 c 0,3.321 0.629,6.442 1.839,9.372 1.211,2.929 2.891,5.468 5.04,7.699 2.14,2.179 4.64,3.898 7.609,5.109 2.93,1.211 6.101,1.84 9.5,1.84 h 214.57 V 228.59 H 350.551 V 80.3516 c 0,-6.211 1.64,-10.9727 4.918,-14.2618 3.32,-3.3203 8.121,-4.9609 14.492,-4.9609 h 171.488 z" />
                            <path id="path18"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 850.039,33.8281 c 0,-3.4375 -0.66,-6.5976 -1.949,-9.5273 -1.25,-2.9297 -2.969,-5.4727 -5.121,-7.6211 -2.149,-2.1094 -4.649,-3.7891 -7.617,-5.0391 -2.93,-1.2109 -6.012,-1.83982 -9.332,-1.83982 -2.93,0 -5.899,0.58982 -8.872,1.67972 -3.007,1.0976 -5.668,2.8593 -7.968,5.3203 L 635.078,198.551 V 13.4805 H 587.461 V 255.898 c 0,4.883 1.367,9.332 4.098,13.282 2.781,3.98 6.293,6.949 10.55,8.91 4.532,1.832 9.18,2.262 13.942,1.359 4.769,-0.898 8.828,-3.16 12.269,-6.679 L 802.379,91.1719 V 276.25 h 47.66 z" />
                            <path id="path20"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1157.85,90.8203 c 0,-9.4101 -1.17,-17.8906 -3.55,-25.3906 -2.39,-7.5 -5.55,-14.1406 -9.46,-19.8789 -3.9,-5.75 -8.47,-10.6289 -13.75,-14.6524 -5.23,-4.0195 -10.66,-7.3476 -16.29,-9.8789 -5.62,-2.5781 -11.32,-4.5 -17.14,-5.7109 -5.78,-1.207 -11.25,-1.8281 -16.37,-1.8281 H 897.5 v 47.6484 h 183.79 c 9.14,0 16.25,2.6992 21.33,8.0508 5.08,5.3906 7.61,12.582 7.61,21.6406 0,4.3789 -0.66,8.4414 -2.03,12.0707 -1.32,3.668 -3.28,6.879 -5.78,9.531 -2.5,2.699 -5.54,4.769 -9.14,6.25 -3.59,1.449 -7.62,2.187 -11.99,2.187 H 971.68 c -7.7,0 -15.981,1.371 -24.918,4.141 -8.91,2.73 -17.192,7.148 -24.813,13.281 -7.648,6.098 -13.98,14.098 -19.058,23.989 -5.082,9.921 -7.621,21.992 -7.621,36.281 0,14.301 2.539,26.371 7.621,36.179 5.078,9.84 11.41,17.848 19.058,24.02 7.621,6.172 15.903,10.629 24.813,13.359 8.937,2.77 17.218,4.141 24.918,4.141 h 162.19 V 228.59 H 971.68 c -9.02,0 -16.09,-2.731 -21.129,-8.238 -5.082,-5.473 -7.621,-12.774 -7.621,-21.801 0,-9.172 2.539,-16.403 7.621,-21.711 5.039,-5.32 12.109,-7.969 21.129,-7.969 h 109.96 c 5.12,-0.121 10.55,-0.82 16.29,-2.109 5.74,-1.293 11.48,-3.242 17.15,-5.942 5.66,-2.699 11.09,-6.09 16.21,-10.199 5.16,-4.062 9.69,-8.98 13.67,-14.723 3.95,-5.738 7.11,-12.347 9.42,-19.808 2.34,-7.461 3.47,-15.86 3.47,-25.2697 z" />
                            <path id="path22"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1413.48,120.859 h -150.43 v 48.0117 h 150.43 z m 21.44,-107.3785 h -171.87 c -6.6,0 -13.83,1.1679 -21.64,3.4687 -7.82,2.3516 -15.04,6.1719 -21.72,11.4922 -6.64,5.3086 -12.19,12.1797 -16.64,20.6992 -4.5,8.4805 -6.72,18.9102 -6.72,31.211 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 214.57 V 228.59 H 1243.98 V 80.3516 c 0,-6.211 1.64,-10.9727 4.97,-14.2618 3.28,-3.3203 8.12,-4.9609 14.45,-4.9609 h 171.52 z" />
                            <path id="path24"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1743.48,187.93 c 0,-10.86 -1.33,-20.59 -4.03,-29.141 -2.69,-8.559 -6.33,-16.09 -10.9,-22.617 -4.57,-6.563 -9.88,-12.152 -15.85,-16.762 -5.98,-4.64 -12.23,-8.469 -18.79,-11.48 -6.53,-2.969 -13.13,-5.16 -19.77,-6.481 -6.68,-1.371 -12.93,-2.0388 -18.79,-2.0388 l 99.49,-85.9297 h -73.67 l -99.29,85.9297 h -34.26 V 147.07 h 107.73 c 5.98,0.469 11.45,1.719 16.41,3.75 4.92,2.032 9.22,4.731 12.81,8.16 3.59,3.399 6.41,7.54 8.36,12.348 1.95,4.844 2.93,10.352 2.93,16.602 v 30.039 c 0,2.691 -0.35,4.73 -1.02,6.133 -0.66,1.41 -1.52,2.457 -2.57,3.128 -1.02,0.661 -2.15,1.051 -3.29,1.211 -1.17,0.11 -2.22,0.149 -3.2,0.149 H 1528.55 V 13.4805 H 1480.9 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.22,2.929 2.89,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 180.86 c 10.63,0 19.61,-1.91 26.92,-5.781 7.34,-3.828 13.28,-8.668 17.89,-14.449 4.57,-5.821 7.85,-12.071 9.88,-18.79 1.99,-6.718 3.01,-13.011 3.01,-18.871 z" />
                            <path id="path26"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2172.62,37.5 c 0,-3.4414 -0.63,-6.6016 -1.84,-9.5312 -1.21,-2.9297 -2.89,-5.4688 -5.04,-7.6172 -2.15,-2.1524 -4.65,-3.8321 -7.58,-5.043 -2.93,-1.207 -6.13,-1.8281 -9.53,-1.8281 h -171.87 c -4.42,0 -9.14,0.539 -14.22,1.5586 -5.08,1.0507 -10.08,2.6601 -15.04,4.8398 -4.92,2.2305 -9.65,5.043 -14.18,8.5625 -4.53,3.4688 -8.52,7.6875 -11.99,12.6094 -3.48,4.9687 -6.29,10.7109 -8.36,17.2305 -2.07,6.5195 -3.13,13.9101 -3.13,22.0703 V 209.379 c 0,4.371 0.55,9.141 1.57,14.172 1.05,5.078 2.65,10.078 4.88,15.039 2.19,4.961 5.04,9.691 8.59,14.18 3.56,4.531 7.78,8.558 12.74,12.031 4.96,3.48 10.66,6.25 17.15,8.32 6.44,2.078 13.78,3.129 21.99,3.129 h 193.67 v -47.66 h -193.67 c -6.25,0 -11.02,-1.641 -14.3,-4.918 -3.32,-3.32 -4.96,-8.203 -4.96,-14.692 V 80.3516 c 0,-6.0899 1.68,-10.8203 5.04,-14.1797 3.36,-3.3633 8.08,-5.043 14.22,-5.043 H 2125 v 59.7301 h -126.84 v 48.012 h 150.47 c 3.4,0 6.6,-0.633 9.53,-1.922 2.93,-1.289 5.43,-3.039 7.58,-5.23 2.15,-2.188 3.83,-4.731 5.04,-7.617 1.21,-2.852 1.84,-5.942 1.84,-9.223 z" />
                            <path id="path28"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2528.09,13.4805 h -48.01 V 169.961 L 2395.62,21.3711 c -2.07,-3.793 -5,-6.6406 -8.71,-8.6016 -3.71,-1.9882 -7.73,-2.96872 -11.99,-2.96872 -4.18,0 -8.04,0.98052 -11.64,2.96872 -3.59,1.961 -6.44,4.8086 -8.51,8.6016 L 2269.92,169.961 V 13.4805 h -47.65 V 255.898 c 0,5.512 1.64,10.391 4.88,14.692 3.24,4.25 7.46,7.109 12.73,8.59 2.54,0.621 5.12,0.82 7.7,0.621 2.54,-0.153 5.04,-0.699 7.42,-1.641 2.38,-0.89 4.53,-2.18 6.48,-3.82 1.96,-1.68 3.6,-3.641 4.97,-5.981 L 2374.92,79.8008 2483.4,268.359 c 2.81,4.653 6.68,7.93 11.64,9.922 4.92,1.949 10.08,2.231 15.47,0.899 5.11,-1.481 9.33,-4.34 12.65,-8.59 3.29,-4.301 4.93,-9.18 4.93,-14.692 z" />
                            <path id="path30"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2845.66,71.2109 c 0,-5.8593 -1.01,-12.1523 -3.04,-18.8711 -2,-6.7187 -5.32,-12.9609 -9.89,-18.7109 -4.57,-5.7383 -10.54,-10.5078 -17.89,-14.3672 -7.3,-3.832 -16.29,-5.7812 -26.91,-5.7812 h -180.86 c -3.4,0 -6.6,0.6211 -9.53,1.8281 -2.93,1.2109 -5.47,2.8906 -7.62,5.043 -2.11,2.1484 -3.79,4.6875 -5.04,7.6172 -1.21,2.9296 -1.79,6.0898 -1.79,9.5312 v 214.73 c 0,3.321 0.58,6.442 1.79,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.13,1.84 9.53,1.84 h 159.41 c 5.86,0 12.15,-1.051 18.87,-3.129 6.72,-2.07 13.01,-5.391 18.79,-9.961 5.82,-4.609 10.63,-10.539 14.49,-17.89 3.83,-7.301 5.75,-16.29 5.75,-26.911 v -8.98 c 0,-8.438 -1.41,-17.5 -4.18,-27.231 -2.82,-9.687 -7.35,-18.91 -13.6,-27.578 5.39,-3.281 10.43,-7.269 15.12,-11.91 4.73,-4.648 8.83,-10 12.38,-16.101 3.56,-6.129 6.37,-13.039 8.44,-20.739 2.07,-7.6989 3.12,-16.1716 3.12,-25.4684 z m -47.65,9.1407 c 0,6.25 -0.98,11.8398 -2.93,16.7968 -1.96,4.9216 -4.73,9.1796 -8.24,12.7306 -3.56,3.519 -7.82,6.25 -12.86,8.133 -5,1.91 -10.62,2.847 -16.83,2.847 h -107.38 v 48.012 h 85.93 c 6.25,0 11.84,0.93 16.88,2.848 5,1.871 9.26,4.609 12.81,8.16 3.56,3.519 6.25,7.781 8.16,12.742 1.88,4.918 2.82,10.5 2.82,16.758 v 8.98 c 0,6.84 -3.28,10.231 -9.89,10.231 H 2630.7 V 61.1289 h 157.23 c 0.86,0 1.87,0.043 3.12,0.1992 1.22,0.1211 2.31,0.4727 3.29,1.0938 0.97,0.5898 1.83,1.6367 2.57,3.0898 0.75,1.4766 1.1,3.5078 1.1,6.0469 z" />
                            <path id="path32"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2935.08,168.871 h 167.26 V 276.25 H 3150 V 13.4805 h -47.66 V 120.859 H 2935.08 V 13.4805 h -47.66 V 276.25 h 47.66 z" />
                        </g>
                    </g>
                </svg>
            </a>

            <div class="menu-extras">
                <div class="menu-item">
                    <!-- Mobile menu toggle-->
                    <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()"
                       [ngClass]="{'open': isCondensed === true}">
                        <div class="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                    <!-- End mobile menu toggle-->
                </div>
            </div>
            <ul class="buy-button list-inline mb-0" *ngIf="shopPages == true">
                <li class="list-inline-item mb-0 pe-1">
                    <div class="dropdown" ngbDropdown>
                        <button type="button" class="btn dropdown-toggle p-0" ngbDropdownToggle
                                data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            <i class="uil uil-search text-dark fs-5 align-middle"></i>
                        </button>
                        <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
                             [style]="{'width': '300px'}" ngbDropdownMenu>
                            <!-- <div class="search-bar">
                              <div id="itemSearch" class="menu-search mb-0"> -->
                            <form class="searchform">
                                <input type="text" id="text" name="name" class="form-control border rounded"
                                       placeholder="Search...">
                                <input type="submit" id="searchItemsubmit" value="Search">
                            </form>
                            <!-- </div>
                            </div> -->
                        </div>
                    </div>
                </li>
                <li class="list-inline-item mb-0 pe-1">
                    <div class="dropdown" ngbDropdown>
                        <button type="button" class="btn btn-icon btn-pills btn-primary dropdown-toggle"
                                ngbDropdownToggle
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                class="uil uil-shopping-cart align-middle icons"></i></button>
                        <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-4"
                             ngbDropdownMenu [style]="{'width': '300px'}">
                            <div class="pb-4">
                                <a href="javascript:void(0)" class="d-flex align-items-center">
                                    <img src="assets/images/shop/product/s-1.jpg" class="shadow rounded"
                                         [style]="{'max-height': '64px'}" alt="">
                                    <div class="flex-1 text-start ms-3">
                                        <h6 class="text-dark mb-0">T-shirt (M)</h6>
                                        <p class="text-muted mb-0">$320 X 2</p>
                                    </div>
                                    <h6 class="text-dark mb-0">$640</h6>
                                </a>

                                <a href="javascript:void(0)" class="d-flex align-items-center mt-4">
                                    <img src="assets/images/shop/product/s-2.jpg" class="shadow rounded"
                                         [style]="{'max-height': '64px'}" alt="">
                                    <div class="flex-1 text-start ms-3">
                                        <h6 class="text-dark mb-0">Bag</h6>
                                        <p class="text-muted mb-0">$50 X 5</p>
                                    </div>
                                    <h6 class="text-dark mb-0">$250</h6>
                                </a>

                                <a href="javascript:void(0)" class="d-flex align-items-center mt-4">
                                    <img src="assets/images/shop/product/s-3.jpg" class="shadow rounded"
                                         [style]="{'max-height': '64px'}" alt="">
                                    <div class="flex-1 text-start ms-3">
                                        <h6 class="text-dark mb-0">Watch (Men)</h6>
                                        <p class="text-muted mb-0">$800 X 1</p>
                                    </div>
                                    <h6 class="text-dark mb-0">$800</h6>
                                </a>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pt-4 border-top">
                                <h6 class="text-dark mb-0">Total($):</h6>
                                <h6 class="text-dark mb-0">$1690</h6>
                            </div>

                            <div class="mt-3 text-center">
                                <a href="javascript:void(0)" class="btn btn-primary me-2">View Cart</a>
                                <a href="javascript:void(0)" class="btn btn-primary">Checkout</a>
                            </div>
                            <p class="text-muted text-start mt-1 mb-0">*T&amp;C Apply</p>
                        </div>
                    </div>
                </li>
                <li class="list-inline-item mb-0 pe-1">
                    <a href="javascript:void(0);" class="btn btn-icon btn-pills btn-primary"
                       (click)="wishListModal(content)"><i
                            class="uil uil-heart align-middle icons"></i></a>
                </li>

                <ng-template #content>
                    <div class="modal-content rounded shadow-lg border-0 overflow-hidden">
                        <div class="modal-body py-5">
                            <div class="text-center">
                                <div class="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                                     [style]="{'height': '95px', 'width':'95px'}">
                                    <h1 class="mb-0"><i class="uil uil-heart-break align-middle"></i></h1>
                                </div>
                                <div class="mt-4">
                                    <h4>Your wishlist is empty.</h4>
                                    <p class="text-muted">Create your first wishlist request...</p>
                                    <div class="mt-4">
                                        <a href="javascript:void(0)" class="btn btn-outline-primary">+ Create new
                                            wishlist</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <li class="list-inline-item mb-0">
                    <div class="dropdown dropdown-primary" ngbDropdown>
                        <button type="button" class="btn btn-icon btn-pills btn-primary dropdown-toggle"
                                ngbDropdownToggle
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                class="uil uil-user align-middle icons"></i></button>
                        <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-3"
                             ngbDropdownMenu [style]="{'width': '200px'}">
                            <a class="dropdown-item text-dark" href="javascript:void(0)"><i
                                    class="uil uil-user align-middle me-1"></i> Account</a>
                            <a class="dropdown-item text-dark" href="javascript:void(0)"><i
                                    class="uil uil-clipboard-notes align-middle me-1"></i>
                                Order History</a>
                            <a class="dropdown-item text-dark" href="javascript:void(0)"><i
                                    class="uil uil-arrow-circle-down align-middle me-1"></i>
                                Download</a>
                            <div class="dropdown-divider my-3 border-top"></div>
                            <a class="dropdown-item text-dark" href="javascript:void(0)"><i
                                    class="uil uil-sign-out-alt align-middle me-1"></i>
                                Logout</a>
                        </div>
                    </div>
                </li>
            </ul>

            <ul class="buy-button list-inline mb-0" *ngIf="buttonList == true">
                <li class="list-inline-item mb-0 ms-1">
                    <div class="dropdown">
                        <button type="button" class="btn btn-link text-decoration-none dropdown-toggle p-0 pe-2"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="uil uil-search text-muted"></i>
                        </button>
                        <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
                             [style]="{'width': '300px'}">
                            <form>
                                <input type="text" id="text" name="name" class="form-control border bg-white"
                                       placeholder="Search...">
                            </form>
                        </div>
                    </div>
                </li>
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-facebook-f icons"></i></a>
                </li>
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-github icons"></i></a>
                </li>
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-twitter icons"></i></a>
                </li>
                <li class="list-inline-item mb-0 ms-1">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-linkedin-alt icons"></i></a>
                </li>
            </ul>

            <ul class="buy-button list-inline mb-0" *ngIf="isdeveloper == true">
                <li class="list-inline-item mb-0">
                    <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle p-0" data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <i class="uil uil-search text-dark fs-5 align-middle pe-2"></i>
                        </button>
                        <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
                             [style]="{'width': '300px'}">
                            <form>
                                <input type="text" id="text2" name="name" class="form-control border bg-white"
                                       placeholder="Search...">
                            </form>
                        </div>
                    </div>
                </li>
                <li class="list-inline-item mb-0 pe-2">
                    <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                       aria-controls="offcanvasRight" (click)="openright(content)">
                        <div class="btn btn-icon btn-soft-primary" id="settingbtn">
                            <i-feather name="settings"
                                       class="fea icon-sm"></i-feather>
                        </div>
                    </a>
                </li>
                <li class="list-inline-item mb-0">
                    <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i
                            class="uil uil-github icons"></i></a>
                </li>
                <!-- <li class="list-inline-item mb-0 pe-1">
                  <a href="javascript:void(0)" class="btn btn-icon btn-soft-primary"><i class="mdi mdi-stack-overflow mdi-18px icons"></i></a>
                </li>
                <li class="list-inline-item mb-0">
                  <a href="javascript:void(0)" class="btn btn-icon btn-soft-primary" (click)="developerModal(content)"
                    data-bs-toggle="modal" data-bs-target="#loginform"><i class="uil uil-user icons"></i></a>
                </li> -->
                <ng-template #content let-modal>
                    <div class="modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content rounded shadow-lg border-0 overflow-hidden position-relative">
                            <button type="button" class="btn-close position-absolute top-0 end-0 mt-2 me-2"
                                    (click)="modal.dismiss('Cross click')" [style]="{'z-index': 1}"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <div class="modal-body p-0">
                                <div class="container-fluid px-0">
                                    <div class="row align-items-center g-0">
                                        <div class="col-lg-6 col-md-5">
                                            <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="">
                                        </div>
                                        <!--end col-->

                                        <div class="col-lg-6 col-md-7">
                                            <form class="login-form p-4">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Your Email <span
                                                                    class="text-danger">*</span></label>
                                                            <div class="form-icon position-relative">
                                                                <i-feather name="user"
                                                                           class="fea icon-sm icons"></i-feather>
                                                                <input type="email" class="form-control ps-5"
                                                                       placeholder="Email" name="email"
                                                                       required="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--end col-->

                                                    <div class="col-lg-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Password <span
                                                                    class="text-danger">*</span></label>
                                                            <div class="form-icon position-relative">
                                                                <i-feather name="key"
                                                                           class="fea icon-sm icons"></i-feather>
                                                                <input type="password" class="form-control ps-5"
                                                                       placeholder="Password" required="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--end col-->

                                                    <div class="col-lg-12">
                                                        <div class="d-flex justify-content-between">
                                                            <div class="mb-3">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input type="checkbox" class="custom-control-input"
                                                                           id="customCheck1">
                                                                    <label class="custom-control-label ms-1"
                                                                           for="customCheck1">Remember me</label>
                                                                </div>
                                                            </div>
                                                            <p class="forgot-pass mb-0"><a
                                                                    routerLink="/auth-re-password"
                                                                    class="text-dark fw-bold">Forgot password ?</a></p>
                                                        </div>
                                                    </div>
                                                    <!--end col-->

                                                    <div class="col-lg-12 mb-0">
                                                        <div class="d-grid">
                                                            <button type='button' class="btn btn-primary">Sign in
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!--end col-->

                                                    <div class="col-12 text-center">
                                                        <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an
                                                            account ?</small> <a
                                                                routerLink="/auth-signup" class="text-dark fw-bold">Sign
                                                            Up</a></p>
                                                    </div>
                                                    <!--end col-->
                                                </div>
                                                <!--end row-->
                                            </form>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end row-->
                                </div>
                                <!--end container-->
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ul>

            <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
<!--                ****** dark logo ******-->
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 40 40"
                     height="40"
                     width="40"
                     id="svg3"
                     class="l-dark"
                >
                    <g transform="matrix(0.3333333,0,0,-0.3333333,0,39.333333)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 588.75,1180 c -79.84,0 -156.25,-15.43 -229.18,-46.25 -39.84,-16.87 -77.07,-37.62 -111.761,-62.3 h 44.031 c 24.488,15.15 50.23,28.43 77.219,39.84 69.921,29.57 143.132,44.33 219.691,44.33 76.559,0 149.77,-14.76 219.691,-44.33 26.989,-11.41 52.731,-24.69 77.219,-39.84 h 44.031 C 895,1096.13 857.73,1116.88 817.93,1133.75 745,1164.57 668.59,1180 588.75,1180 Z"/>
                            <path id="path16"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 135.738,138.238 H 1041.76 V 1044.26 H 135.738 V 961.879 H 959.379 V 220.621 H 135.738 Z"/>
                            <path id="path18"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 1177.5,591.25 c 0,79.84 -15.43,156.25 -46.29,229.18 -16.83,39.8 -37.58,77.07 -62.26,111.761 V 888.16 c 15.15,-24.488 28.43,-50.23 39.84,-77.219 29.57,-69.921 44.33,-143.132 44.33,-219.691 0,-76.559 -14.76,-149.77 -44.33,-219.691 -11.41,-26.989 -24.69,-52.731 -39.84,-77.219 v -44.031 c 24.68,34.691 45.43,71.961 62.26,111.761 30.86,72.93 46.29,149.34 46.29,229.18 z"/>
                            <path id="path20"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 0,591.25 C 0,511.41 15.4297,435 46.25,362.07 63.1211,322.27 83.8711,285 108.551,250.309 V 294.34 C 93.3984,318.828 80.1211,344.57 68.7109,371.559 39.1406,441.48 24.3398,514.691 24.3398,591.25 c 0,76.559 14.8008,149.77 44.3711,219.691 11.4102,26.989 24.6875,52.688 39.8401,77.219 v 44.031 C 83.8711,897.5 63.1211,860.23 46.25,820.43 15.4297,747.5 0,671.09 0,591.25 Z"/>
                            <path id="path22"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,632.34 c 30.242,0.121 709.184,0.121 741.211,0.121 V 879.488 H 135.738 V 797.109 H 794.609 V 714.73 H 135.738 Z"/>
                            <path id="path24"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,302.93 c 30.242,0.082 709.184,0.082 741.211,0.082 V 550.039 H 135.738 v -82.34 H 794.609 V 385.27 H 135.738 Z"/>
                            <path id="path26"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 817.93,48.7891 C 745,17.9297 668.59,2.5 588.75,2.5 508.91,2.5 432.5,17.9297 359.57,48.7891 319.73,65.6211 282.5,86.3711 247.809,111.051 h 44.031 c 24.488,-15.1526 50.23,-28.4299 77.219,-39.8401 69.921,-29.5703 143.132,-44.332 219.691,-44.332 76.559,0 149.77,14.7617 219.691,44.332 26.989,11.4102 52.731,24.6875 77.219,39.8401 h 44.031 C 895,86.3711 857.73,65.6211 817.93,48.7891 Z"/>
                        </g>
                    </g>
                </svg>
                <svg xmlns:svg="http://www.w3.org/2000/svg"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 253 22"
                     height="22"
                     width="253"
                     id="svg4"
                     class="l-dark ml-2"
                >
                    <g transform="matrix(0.8,0,0,-0.8,0,23.5)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 262.578,71.2109 c 0,-5.8593 -1.019,-12.1523 -3.008,-18.8711 -2.031,-6.7187 -5.308,-12.9609 -9.922,-18.7109 -4.57,-5.7383 -10.507,-10.5078 -17.847,-14.3672 -7.352,-3.832 -16.332,-5.7812 -26.961,-5.7812 H 23.9805 c -3.3907,0 -6.5586,0.6211 -9.4922,1.8281 -2.9297,1.2109 -5.46877,2.8906 -7.60939,5.043 C 4.73047,22.5 3.05078,25.0391 1.83984,27.9688 0.621094,30.8984 0,34.0586 0,37.5 v 214.73 c 0,3.321 0.621094,6.442 1.83984,9.372 1.21094,2.929 2.89063,5.468 5.03907,7.699 2.14062,2.179 4.67969,3.898 7.60939,5.109 2.9336,1.211 6.1015,1.84 9.4922,1.84 H 183.441 c 5.86,0 12.149,-1.051 18.86,-3.129 6.719,-2.07 12.969,-5.391 18.75,-9.961 5.828,-4.609 10.668,-10.539 14.5,-17.89 3.859,-7.301 5.777,-16.29 5.777,-26.911 v -8.98 c 0,-8.438 -1.406,-17.5 -4.219,-27.231 -2.808,-9.687 -7.339,-18.91 -13.558,-27.578 5.359,-3.281 10.398,-7.269 15.121,-11.91 4.687,-4.648 8.828,-10 12.348,-16.101 3.55,-6.129 6.359,-13.039 8.429,-20.739 2.071,-7.6989 3.129,-16.1716 3.129,-25.4684 z m -47.656,9.1407 c 0,6.25 -0.973,11.8398 -2.934,16.7968 -1.949,4.9216 -4.687,9.1796 -8.238,12.7306 -3.52,3.519 -7.809,6.25 -12.809,8.133 -5,1.91 -10.632,2.847 -16.882,2.847 H 66.6797 v 48.012 h 85.9413 c 6.25,0 11.867,0.93 16.867,2.848 5,1.871 9.301,4.609 12.813,8.16 3.558,3.519 6.289,7.781 8.168,12.742 1.91,4.918 2.851,10.5 2.851,16.758 v 8.98 c 0,6.84 -3.32,10.231 -9.879,10.231 H 47.6602 V 61.1289 H 204.84 c 0.859,0 1.922,0.043 3.129,0.1992 1.211,0.1211 2.34,0.4727 3.32,1.0938 0.981,0.5898 1.801,1.6367 2.539,3.0898 0.742,1.4766 1.094,3.5078 1.094,6.0469 z" />
                            <path id="path16"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="M 520.039,120.859 H 369.57 v 48.0117 H 520.039 Z M 541.449,13.4805 H 369.57 c -6.558,0 -13.789,1.1679 -21.601,3.4687 -7.809,2.3516 -15.078,6.1719 -21.719,11.4922 -6.641,5.3086 -12.23,12.1797 -16.68,20.6992 -4.449,8.4805 -6.679,18.9102 -6.679,31.211 V 252.23 c 0,3.321 0.629,6.442 1.839,9.372 1.211,2.929 2.891,5.468 5.04,7.699 2.14,2.179 4.64,3.898 7.609,5.109 2.93,1.211 6.101,1.84 9.5,1.84 h 214.57 V 228.59 H 350.551 V 80.3516 c 0,-6.211 1.64,-10.9727 4.918,-14.2618 3.32,-3.3203 8.121,-4.9609 14.492,-4.9609 h 171.488 z" />
                            <path id="path18"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 850.039,33.8281 c 0,-3.4375 -0.66,-6.5976 -1.949,-9.5273 -1.25,-2.9297 -2.969,-5.4727 -5.121,-7.6211 -2.149,-2.1094 -4.649,-3.7891 -7.617,-5.0391 -2.93,-1.2109 -6.012,-1.83982 -9.332,-1.83982 -2.93,0 -5.899,0.58982 -8.872,1.67972 -3.007,1.0976 -5.668,2.8593 -7.968,5.3203 L 635.078,198.551 V 13.4805 H 587.461 V 255.898 c 0,4.883 1.367,9.332 4.098,13.282 2.781,3.98 6.293,6.949 10.55,8.91 4.532,1.832 9.18,2.262 13.942,1.359 4.769,-0.898 8.828,-3.16 12.269,-6.679 L 802.379,91.1719 V 276.25 h 47.66 z" />
                            <path id="path20"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1157.85,90.8203 c 0,-9.4101 -1.17,-17.8906 -3.55,-25.3906 -2.39,-7.5 -5.55,-14.1406 -9.46,-19.8789 -3.9,-5.75 -8.47,-10.6289 -13.75,-14.6524 -5.23,-4.0195 -10.66,-7.3476 -16.29,-9.8789 -5.62,-2.5781 -11.32,-4.5 -17.14,-5.7109 -5.78,-1.207 -11.25,-1.8281 -16.37,-1.8281 H 897.5 v 47.6484 h 183.79 c 9.14,0 16.25,2.6992 21.33,8.0508 5.08,5.3906 7.61,12.582 7.61,21.6406 0,4.3789 -0.66,8.4414 -2.03,12.0707 -1.32,3.668 -3.28,6.879 -5.78,9.531 -2.5,2.699 -5.54,4.769 -9.14,6.25 -3.59,1.449 -7.62,2.187 -11.99,2.187 H 971.68 c -7.7,0 -15.981,1.371 -24.918,4.141 -8.91,2.73 -17.192,7.148 -24.813,13.281 -7.648,6.098 -13.98,14.098 -19.058,23.989 -5.082,9.921 -7.621,21.992 -7.621,36.281 0,14.301 2.539,26.371 7.621,36.179 5.078,9.84 11.41,17.848 19.058,24.02 7.621,6.172 15.903,10.629 24.813,13.359 8.937,2.77 17.218,4.141 24.918,4.141 h 162.19 V 228.59 H 971.68 c -9.02,0 -16.09,-2.731 -21.129,-8.238 -5.082,-5.473 -7.621,-12.774 -7.621,-21.801 0,-9.172 2.539,-16.403 7.621,-21.711 5.039,-5.32 12.109,-7.969 21.129,-7.969 h 109.96 c 5.12,-0.121 10.55,-0.82 16.29,-2.109 5.74,-1.293 11.48,-3.242 17.15,-5.942 5.66,-2.699 11.09,-6.09 16.21,-10.199 5.16,-4.062 9.69,-8.98 13.67,-14.723 3.95,-5.738 7.11,-12.347 9.42,-19.808 2.34,-7.461 3.47,-15.86 3.47,-25.2697 z" />
                            <path id="path22"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1413.48,120.859 h -150.43 v 48.0117 h 150.43 z m 21.44,-107.3785 h -171.87 c -6.6,0 -13.83,1.1679 -21.64,3.4687 -7.82,2.3516 -15.04,6.1719 -21.72,11.4922 -6.64,5.3086 -12.19,12.1797 -16.64,20.6992 -4.5,8.4805 -6.72,18.9102 -6.72,31.211 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 214.57 V 228.59 H 1243.98 V 80.3516 c 0,-6.211 1.64,-10.9727 4.97,-14.2618 3.28,-3.3203 8.12,-4.9609 14.45,-4.9609 h 171.52 z" />
                            <path id="path24"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1743.48,187.93 c 0,-10.86 -1.33,-20.59 -4.03,-29.141 -2.69,-8.559 -6.33,-16.09 -10.9,-22.617 -4.57,-6.563 -9.88,-12.152 -15.85,-16.762 -5.98,-4.64 -12.23,-8.469 -18.79,-11.48 -6.53,-2.969 -13.13,-5.16 -19.77,-6.481 -6.68,-1.371 -12.93,-2.0388 -18.79,-2.0388 l 99.49,-85.9297 h -73.67 l -99.29,85.9297 h -34.26 V 147.07 h 107.73 c 5.98,0.469 11.45,1.719 16.41,3.75 4.92,2.032 9.22,4.731 12.81,8.16 3.59,3.399 6.41,7.54 8.36,12.348 1.95,4.844 2.93,10.352 2.93,16.602 v 30.039 c 0,2.691 -0.35,4.73 -1.02,6.133 -0.66,1.41 -1.52,2.457 -2.57,3.128 -1.02,0.661 -2.15,1.051 -3.29,1.211 -1.17,0.11 -2.22,0.149 -3.2,0.149 H 1528.55 V 13.4805 H 1480.9 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.22,2.929 2.89,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 180.86 c 10.63,0 19.61,-1.91 26.92,-5.781 7.34,-3.828 13.28,-8.668 17.89,-14.449 4.57,-5.821 7.85,-12.071 9.88,-18.79 1.99,-6.718 3.01,-13.011 3.01,-18.871 z" />
                            <path id="path26"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2172.62,37.5 c 0,-3.4414 -0.63,-6.6016 -1.84,-9.5312 -1.21,-2.9297 -2.89,-5.4688 -5.04,-7.6172 -2.15,-2.1524 -4.65,-3.8321 -7.58,-5.043 -2.93,-1.207 -6.13,-1.8281 -9.53,-1.8281 h -171.87 c -4.42,0 -9.14,0.539 -14.22,1.5586 -5.08,1.0507 -10.08,2.6601 -15.04,4.8398 -4.92,2.2305 -9.65,5.043 -14.18,8.5625 -4.53,3.4688 -8.52,7.6875 -11.99,12.6094 -3.48,4.9687 -6.29,10.7109 -8.36,17.2305 -2.07,6.5195 -3.13,13.9101 -3.13,22.0703 V 209.379 c 0,4.371 0.55,9.141 1.57,14.172 1.05,5.078 2.65,10.078 4.88,15.039 2.19,4.961 5.04,9.691 8.59,14.18 3.56,4.531 7.78,8.558 12.74,12.031 4.96,3.48 10.66,6.25 17.15,8.32 6.44,2.078 13.78,3.129 21.99,3.129 h 193.67 v -47.66 h -193.67 c -6.25,0 -11.02,-1.641 -14.3,-4.918 -3.32,-3.32 -4.96,-8.203 -4.96,-14.692 V 80.3516 c 0,-6.0899 1.68,-10.8203 5.04,-14.1797 3.36,-3.3633 8.08,-5.043 14.22,-5.043 H 2125 v 59.7301 h -126.84 v 48.012 h 150.47 c 3.4,0 6.6,-0.633 9.53,-1.922 2.93,-1.289 5.43,-3.039 7.58,-5.23 2.15,-2.188 3.83,-4.731 5.04,-7.617 1.21,-2.852 1.84,-5.942 1.84,-9.223 z" />
                            <path id="path28"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2528.09,13.4805 h -48.01 V 169.961 L 2395.62,21.3711 c -2.07,-3.793 -5,-6.6406 -8.71,-8.6016 -3.71,-1.9882 -7.73,-2.96872 -11.99,-2.96872 -4.18,0 -8.04,0.98052 -11.64,2.96872 -3.59,1.961 -6.44,4.8086 -8.51,8.6016 L 2269.92,169.961 V 13.4805 h -47.65 V 255.898 c 0,5.512 1.64,10.391 4.88,14.692 3.24,4.25 7.46,7.109 12.73,8.59 2.54,0.621 5.12,0.82 7.7,0.621 2.54,-0.153 5.04,-0.699 7.42,-1.641 2.38,-0.89 4.53,-2.18 6.48,-3.82 1.96,-1.68 3.6,-3.641 4.97,-5.981 L 2374.92,79.8008 2483.4,268.359 c 2.81,4.653 6.68,7.93 11.64,9.922 4.92,1.949 10.08,2.231 15.47,0.899 5.11,-1.481 9.33,-4.34 12.65,-8.59 3.29,-4.301 4.93,-9.18 4.93,-14.692 z" />
                            <path id="path30"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2845.66,71.2109 c 0,-5.8593 -1.01,-12.1523 -3.04,-18.8711 -2,-6.7187 -5.32,-12.9609 -9.89,-18.7109 -4.57,-5.7383 -10.54,-10.5078 -17.89,-14.3672 -7.3,-3.832 -16.29,-5.7812 -26.91,-5.7812 h -180.86 c -3.4,0 -6.6,0.6211 -9.53,1.8281 -2.93,1.2109 -5.47,2.8906 -7.62,5.043 -2.11,2.1484 -3.79,4.6875 -5.04,7.6172 -1.21,2.9296 -1.79,6.0898 -1.79,9.5312 v 214.73 c 0,3.321 0.58,6.442 1.79,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.13,1.84 9.53,1.84 h 159.41 c 5.86,0 12.15,-1.051 18.87,-3.129 6.72,-2.07 13.01,-5.391 18.79,-9.961 5.82,-4.609 10.63,-10.539 14.49,-17.89 3.83,-7.301 5.75,-16.29 5.75,-26.911 v -8.98 c 0,-8.438 -1.41,-17.5 -4.18,-27.231 -2.82,-9.687 -7.35,-18.91 -13.6,-27.578 5.39,-3.281 10.43,-7.269 15.12,-11.91 4.73,-4.648 8.83,-10 12.38,-16.101 3.56,-6.129 6.37,-13.039 8.44,-20.739 2.07,-7.6989 3.12,-16.1716 3.12,-25.4684 z m -47.65,9.1407 c 0,6.25 -0.98,11.8398 -2.93,16.7968 -1.96,4.9216 -4.73,9.1796 -8.24,12.7306 -3.56,3.519 -7.82,6.25 -12.86,8.133 -5,1.91 -10.62,2.847 -16.83,2.847 h -107.38 v 48.012 h 85.93 c 6.25,0 11.84,0.93 16.88,2.848 5,1.871 9.26,4.609 12.81,8.16 3.56,3.519 6.25,7.781 8.16,12.742 1.88,4.918 2.82,10.5 2.82,16.758 v 8.98 c 0,6.84 -3.28,10.231 -9.89,10.231 H 2630.7 V 61.1289 h 157.23 c 0.86,0 1.87,0.043 3.12,0.1992 1.22,0.1211 2.31,0.4727 3.29,1.0938 0.97,0.5898 1.83,1.6367 2.57,3.0898 0.75,1.4766 1.1,3.5078 1.1,6.0469 z" />
                            <path id="path32"
                                  style="fill:#003a70;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2935.08,168.871 h 167.26 V 276.25 H 3150 V 13.4805 h -47.66 V 120.859 H 2935.08 V 13.4805 h -47.66 V 276.25 h 47.66 z" />
                        </g>
                    </g>
                </svg>

<!--                ****** light logo ******-->
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 40 40"
                     height="40"
                     width="40"
                     id="svg3"
                     class="l-light"
                >
                    <g transform="matrix(0.3333333,0,0,-0.3333333,0,39.333333)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 588.75,1180 c -79.84,0 -156.25,-15.43 -229.18,-46.25 -39.84,-16.87 -77.07,-37.62 -111.761,-62.3 h 44.031 c 24.488,15.15 50.23,28.43 77.219,39.84 69.921,29.57 143.132,44.33 219.691,44.33 76.559,0 149.77,-14.76 219.691,-44.33 26.989,-11.41 52.731,-24.69 77.219,-39.84 h 44.031 C 895,1096.13 857.73,1116.88 817.93,1133.75 745,1164.57 668.59,1180 588.75,1180 Z"/>
                            <path id="path16"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 135.738,138.238 H 1041.76 V 1044.26 H 135.738 V 961.879 H 959.379 V 220.621 H 135.738 Z"/>
                            <path id="path18"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 1177.5,591.25 c 0,79.84 -15.43,156.25 -46.29,229.18 -16.83,39.8 -37.58,77.07 -62.26,111.761 V 888.16 c 15.15,-24.488 28.43,-50.23 39.84,-77.219 29.57,-69.921 44.33,-143.132 44.33,-219.691 0,-76.559 -14.76,-149.77 -44.33,-219.691 -11.41,-26.989 -24.69,-52.731 -39.84,-77.219 v -44.031 c 24.68,34.691 45.43,71.961 62.26,111.761 30.86,72.93 46.29,149.34 46.29,229.18 z"/>
                            <path id="path20"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 0,591.25 C 0,511.41 15.4297,435 46.25,362.07 63.1211,322.27 83.8711,285 108.551,250.309 V 294.34 C 93.3984,318.828 80.1211,344.57 68.7109,371.559 39.1406,441.48 24.3398,514.691 24.3398,591.25 c 0,76.559 14.8008,149.77 44.3711,219.691 11.4102,26.989 24.6875,52.688 39.8401,77.219 v 44.031 C 83.8711,897.5 63.1211,860.23 46.25,820.43 15.4297,747.5 0,671.09 0,591.25 Z"/>
                            <path id="path22"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,632.34 c 30.242,0.121 709.184,0.121 741.211,0.121 V 879.488 H 135.738 V 797.109 H 794.609 V 714.73 H 135.738 Z"/>
                            <path id="path24"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="m 135.738,302.93 c 30.242,0.082 709.184,0.082 741.211,0.082 V 550.039 H 135.738 v -82.34 H 794.609 V 385.27 H 135.738 Z"/>
                            <path id="path26"
                                  style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                  d="M 817.93,48.7891 C 745,17.9297 668.59,2.5 588.75,2.5 508.91,2.5 432.5,17.9297 359.57,48.7891 319.73,65.6211 282.5,86.3711 247.809,111.051 h 44.031 c 24.488,-15.1526 50.23,-28.4299 77.219,-39.8401 69.921,-29.5703 143.132,-44.332 219.691,-44.332 76.559,0 149.77,14.7617 219.691,44.332 26.989,11.4102 52.731,24.6875 77.219,39.8401 h 44.031 C 895,86.3711 857.73,65.6211 817.93,48.7891 Z"/>
                        </g>
                    </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 253 22"
                     height="22"
                     width="253"
                     id="svg4"
                     class="l-light ml-2"
                >
                    <g transform="matrix(0.8,0,0,-0.8,0,23.5)" id="g10">
                        <g transform="scale(0.1)" id="g12">
                            <path id="path14"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 262.578,71.2109 c 0,-5.8593 -1.019,-12.1523 -3.008,-18.8711 -2.031,-6.7187 -5.308,-12.9609 -9.922,-18.7109 -4.57,-5.7383 -10.507,-10.5078 -17.847,-14.3672 -7.352,-3.832 -16.332,-5.7812 -26.961,-5.7812 H 23.9805 c -3.3907,0 -6.5586,0.6211 -9.4922,1.8281 -2.9297,1.2109 -5.46877,2.8906 -7.60939,5.043 C 4.73047,22.5 3.05078,25.0391 1.83984,27.9688 0.621094,30.8984 0,34.0586 0,37.5 v 214.73 c 0,3.321 0.621094,6.442 1.83984,9.372 1.21094,2.929 2.89063,5.468 5.03907,7.699 2.14062,2.179 4.67969,3.898 7.60939,5.109 2.9336,1.211 6.1015,1.84 9.4922,1.84 H 183.441 c 5.86,0 12.149,-1.051 18.86,-3.129 6.719,-2.07 12.969,-5.391 18.75,-9.961 5.828,-4.609 10.668,-10.539 14.5,-17.89 3.859,-7.301 5.777,-16.29 5.777,-26.911 v -8.98 c 0,-8.438 -1.406,-17.5 -4.219,-27.231 -2.808,-9.687 -7.339,-18.91 -13.558,-27.578 5.359,-3.281 10.398,-7.269 15.121,-11.91 4.687,-4.648 8.828,-10 12.348,-16.101 3.55,-6.129 6.359,-13.039 8.429,-20.739 2.071,-7.6989 3.129,-16.1716 3.129,-25.4684 z m -47.656,9.1407 c 0,6.25 -0.973,11.8398 -2.934,16.7968 -1.949,4.9216 -4.687,9.1796 -8.238,12.7306 -3.52,3.519 -7.809,6.25 -12.809,8.133 -5,1.91 -10.632,2.847 -16.882,2.847 H 66.6797 v 48.012 h 85.9413 c 6.25,0 11.867,0.93 16.867,2.848 5,1.871 9.301,4.609 12.813,8.16 3.558,3.519 6.289,7.781 8.168,12.742 1.91,4.918 2.851,10.5 2.851,16.758 v 8.98 c 0,6.84 -3.32,10.231 -9.879,10.231 H 47.6602 V 61.1289 H 204.84 c 0.859,0 1.922,0.043 3.129,0.1992 1.211,0.1211 2.34,0.4727 3.32,1.0938 0.981,0.5898 1.801,1.6367 2.539,3.0898 0.742,1.4766 1.094,3.5078 1.094,6.0469 z" />
                            <path id="path16"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="M 520.039,120.859 H 369.57 v 48.0117 H 520.039 Z M 541.449,13.4805 H 369.57 c -6.558,0 -13.789,1.1679 -21.601,3.4687 -7.809,2.3516 -15.078,6.1719 -21.719,11.4922 -6.641,5.3086 -12.23,12.1797 -16.68,20.6992 -4.449,8.4805 -6.679,18.9102 -6.679,31.211 V 252.23 c 0,3.321 0.629,6.442 1.839,9.372 1.211,2.929 2.891,5.468 5.04,7.699 2.14,2.179 4.64,3.898 7.609,5.109 2.93,1.211 6.101,1.84 9.5,1.84 h 214.57 V 228.59 H 350.551 V 80.3516 c 0,-6.211 1.64,-10.9727 4.918,-14.2618 3.32,-3.3203 8.121,-4.9609 14.492,-4.9609 h 171.488 z" />
                            <path id="path18"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 850.039,33.8281 c 0,-3.4375 -0.66,-6.5976 -1.949,-9.5273 -1.25,-2.9297 -2.969,-5.4727 -5.121,-7.6211 -2.149,-2.1094 -4.649,-3.7891 -7.617,-5.0391 -2.93,-1.2109 -6.012,-1.83982 -9.332,-1.83982 -2.93,0 -5.899,0.58982 -8.872,1.67972 -3.007,1.0976 -5.668,2.8593 -7.968,5.3203 L 635.078,198.551 V 13.4805 H 587.461 V 255.898 c 0,4.883 1.367,9.332 4.098,13.282 2.781,3.98 6.293,6.949 10.55,8.91 4.532,1.832 9.18,2.262 13.942,1.359 4.769,-0.898 8.828,-3.16 12.269,-6.679 L 802.379,91.1719 V 276.25 h 47.66 z" />
                            <path id="path20"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1157.85,90.8203 c 0,-9.4101 -1.17,-17.8906 -3.55,-25.3906 -2.39,-7.5 -5.55,-14.1406 -9.46,-19.8789 -3.9,-5.75 -8.47,-10.6289 -13.75,-14.6524 -5.23,-4.0195 -10.66,-7.3476 -16.29,-9.8789 -5.62,-2.5781 -11.32,-4.5 -17.14,-5.7109 -5.78,-1.207 -11.25,-1.8281 -16.37,-1.8281 H 897.5 v 47.6484 h 183.79 c 9.14,0 16.25,2.6992 21.33,8.0508 5.08,5.3906 7.61,12.582 7.61,21.6406 0,4.3789 -0.66,8.4414 -2.03,12.0707 -1.32,3.668 -3.28,6.879 -5.78,9.531 -2.5,2.699 -5.54,4.769 -9.14,6.25 -3.59,1.449 -7.62,2.187 -11.99,2.187 H 971.68 c -7.7,0 -15.981,1.371 -24.918,4.141 -8.91,2.73 -17.192,7.148 -24.813,13.281 -7.648,6.098 -13.98,14.098 -19.058,23.989 -5.082,9.921 -7.621,21.992 -7.621,36.281 0,14.301 2.539,26.371 7.621,36.179 5.078,9.84 11.41,17.848 19.058,24.02 7.621,6.172 15.903,10.629 24.813,13.359 8.937,2.77 17.218,4.141 24.918,4.141 h 162.19 V 228.59 H 971.68 c -9.02,0 -16.09,-2.731 -21.129,-8.238 -5.082,-5.473 -7.621,-12.774 -7.621,-21.801 0,-9.172 2.539,-16.403 7.621,-21.711 5.039,-5.32 12.109,-7.969 21.129,-7.969 h 109.96 c 5.12,-0.121 10.55,-0.82 16.29,-2.109 5.74,-1.293 11.48,-3.242 17.15,-5.942 5.66,-2.699 11.09,-6.09 16.21,-10.199 5.16,-4.062 9.69,-8.98 13.67,-14.723 3.95,-5.738 7.11,-12.347 9.42,-19.808 2.34,-7.461 3.47,-15.86 3.47,-25.2697 z" />
                            <path id="path22"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1413.48,120.859 h -150.43 v 48.0117 h 150.43 z m 21.44,-107.3785 h -171.87 c -6.6,0 -13.83,1.1679 -21.64,3.4687 -7.82,2.3516 -15.04,6.1719 -21.72,11.4922 -6.64,5.3086 -12.19,12.1797 -16.64,20.6992 -4.5,8.4805 -6.72,18.9102 -6.72,31.211 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 214.57 V 228.59 H 1243.98 V 80.3516 c 0,-6.211 1.64,-10.9727 4.97,-14.2618 3.28,-3.3203 8.12,-4.9609 14.45,-4.9609 h 171.52 z" />
                            <path id="path24"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 1743.48,187.93 c 0,-10.86 -1.33,-20.59 -4.03,-29.141 -2.69,-8.559 -6.33,-16.09 -10.9,-22.617 -4.57,-6.563 -9.88,-12.152 -15.85,-16.762 -5.98,-4.64 -12.23,-8.469 -18.79,-11.48 -6.53,-2.969 -13.13,-5.16 -19.77,-6.481 -6.68,-1.371 -12.93,-2.0388 -18.79,-2.0388 l 99.49,-85.9297 h -73.67 l -99.29,85.9297 h -34.26 V 147.07 h 107.73 c 5.98,0.469 11.45,1.719 16.41,3.75 4.92,2.032 9.22,4.731 12.81,8.16 3.59,3.399 6.41,7.54 8.36,12.348 1.95,4.844 2.93,10.352 2.93,16.602 v 30.039 c 0,2.691 -0.35,4.73 -1.02,6.133 -0.66,1.41 -1.52,2.457 -2.57,3.128 -1.02,0.661 -2.15,1.051 -3.29,1.211 -1.17,0.11 -2.22,0.149 -3.2,0.149 H 1528.55 V 13.4805 H 1480.9 V 252.23 c 0,3.321 0.62,6.442 1.83,9.372 1.22,2.929 2.89,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.09,1.84 9.53,1.84 h 180.86 c 10.63,0 19.61,-1.91 26.92,-5.781 7.34,-3.828 13.28,-8.668 17.89,-14.449 4.57,-5.821 7.85,-12.071 9.88,-18.79 1.99,-6.718 3.01,-13.011 3.01,-18.871 z" />
                            <path id="path26"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2172.62,37.5 c 0,-3.4414 -0.63,-6.6016 -1.84,-9.5312 -1.21,-2.9297 -2.89,-5.4688 -5.04,-7.6172 -2.15,-2.1524 -4.65,-3.8321 -7.58,-5.043 -2.93,-1.207 -6.13,-1.8281 -9.53,-1.8281 h -171.87 c -4.42,0 -9.14,0.539 -14.22,1.5586 -5.08,1.0507 -10.08,2.6601 -15.04,4.8398 -4.92,2.2305 -9.65,5.043 -14.18,8.5625 -4.53,3.4688 -8.52,7.6875 -11.99,12.6094 -3.48,4.9687 -6.29,10.7109 -8.36,17.2305 -2.07,6.5195 -3.13,13.9101 -3.13,22.0703 V 209.379 c 0,4.371 0.55,9.141 1.57,14.172 1.05,5.078 2.65,10.078 4.88,15.039 2.19,4.961 5.04,9.691 8.59,14.18 3.56,4.531 7.78,8.558 12.74,12.031 4.96,3.48 10.66,6.25 17.15,8.32 6.44,2.078 13.78,3.129 21.99,3.129 h 193.67 v -47.66 h -193.67 c -6.25,0 -11.02,-1.641 -14.3,-4.918 -3.32,-3.32 -4.96,-8.203 -4.96,-14.692 V 80.3516 c 0,-6.0899 1.68,-10.8203 5.04,-14.1797 3.36,-3.3633 8.08,-5.043 14.22,-5.043 H 2125 v 59.7301 h -126.84 v 48.012 h 150.47 c 3.4,0 6.6,-0.633 9.53,-1.922 2.93,-1.289 5.43,-3.039 7.58,-5.23 2.15,-2.188 3.83,-4.731 5.04,-7.617 1.21,-2.852 1.84,-5.942 1.84,-9.223 z" />
                            <path id="path28"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2528.09,13.4805 h -48.01 V 169.961 L 2395.62,21.3711 c -2.07,-3.793 -5,-6.6406 -8.71,-8.6016 -3.71,-1.9882 -7.73,-2.96872 -11.99,-2.96872 -4.18,0 -8.04,0.98052 -11.64,2.96872 -3.59,1.961 -6.44,4.8086 -8.51,8.6016 L 2269.92,169.961 V 13.4805 h -47.65 V 255.898 c 0,5.512 1.64,10.391 4.88,14.692 3.24,4.25 7.46,7.109 12.73,8.59 2.54,0.621 5.12,0.82 7.7,0.621 2.54,-0.153 5.04,-0.699 7.42,-1.641 2.38,-0.89 4.53,-2.18 6.48,-3.82 1.96,-1.68 3.6,-3.641 4.97,-5.981 L 2374.92,79.8008 2483.4,268.359 c 2.81,4.653 6.68,7.93 11.64,9.922 4.92,1.949 10.08,2.231 15.47,0.899 5.11,-1.481 9.33,-4.34 12.65,-8.59 3.29,-4.301 4.93,-9.18 4.93,-14.692 z" />
                            <path id="path30"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2845.66,71.2109 c 0,-5.8593 -1.01,-12.1523 -3.04,-18.8711 -2,-6.7187 -5.32,-12.9609 -9.89,-18.7109 -4.57,-5.7383 -10.54,-10.5078 -17.89,-14.3672 -7.3,-3.832 -16.29,-5.7812 -26.91,-5.7812 h -180.86 c -3.4,0 -6.6,0.6211 -9.53,1.8281 -2.93,1.2109 -5.47,2.8906 -7.62,5.043 -2.11,2.1484 -3.79,4.6875 -5.04,7.6172 -1.21,2.9296 -1.79,6.0898 -1.79,9.5312 v 214.73 c 0,3.321 0.58,6.442 1.79,9.372 1.25,2.929 2.93,5.468 5.04,7.699 2.15,2.179 4.69,3.898 7.62,5.109 2.93,1.211 6.13,1.84 9.53,1.84 h 159.41 c 5.86,0 12.15,-1.051 18.87,-3.129 6.72,-2.07 13.01,-5.391 18.79,-9.961 5.82,-4.609 10.63,-10.539 14.49,-17.89 3.83,-7.301 5.75,-16.29 5.75,-26.911 v -8.98 c 0,-8.438 -1.41,-17.5 -4.18,-27.231 -2.82,-9.687 -7.35,-18.91 -13.6,-27.578 5.39,-3.281 10.43,-7.269 15.12,-11.91 4.73,-4.648 8.83,-10 12.38,-16.101 3.56,-6.129 6.37,-13.039 8.44,-20.739 2.07,-7.6989 3.12,-16.1716 3.12,-25.4684 z m -47.65,9.1407 c 0,6.25 -0.98,11.8398 -2.93,16.7968 -1.96,4.9216 -4.73,9.1796 -8.24,12.7306 -3.56,3.519 -7.82,6.25 -12.86,8.133 -5,1.91 -10.62,2.847 -16.83,2.847 h -107.38 v 48.012 h 85.93 c 6.25,0 11.84,0.93 16.88,2.848 5,1.871 9.26,4.609 12.81,8.16 3.56,3.519 6.25,7.781 8.16,12.742 1.88,4.918 2.82,10.5 2.82,16.758 v 8.98 c 0,6.84 -3.28,10.231 -9.89,10.231 H 2630.7 V 61.1289 h 157.23 c 0.86,0 1.87,0.043 3.12,0.1992 1.22,0.1211 2.31,0.4727 3.29,1.0938 0.97,0.5898 1.83,1.6367 2.57,3.0898 0.75,1.4766 1.1,3.5078 1.1,6.0469 z" />
                            <path id="path32"
                                  style="fill:#FFFFFF;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                  d="m 2935.08,168.871 h 167.26 V 276.25 H 3150 V 13.4805 h -47.66 V 120.859 H 2935.08 V 13.4805 h -47.66 V 276.25 h 47.66 z" />
                        </g>
                    </g>
                </svg>
            </a>
        </div>

        <!-- <span *ngIf="!buttonList && !isdeveloper && !shopPages && !Settingicon && Menuoption != 'cryptocurrency'">
          <div class="buy-button">
            <ng-template [ngIf]="navClass === 'nav-light'">
              <a routerLink="/cases" class="btn btn-primary login-btn-primary">Jetzt buchen</a>
              <a routerLink="/cases" class="btn btn-light login-btn-light">Jetzt buchen</a>
            </ng-template>
            <ng-template [ngIf]="navClass !== 'nav-light'">
              <a routerLink="/cases" class="btn btn-primary">Jetzt buchen</a>
            </ng-template>
          </div>
        </span> -->
        <!--end login button-->


        <!--Login button Start-->
        <ul class="buy-button list-inline mb-0" *ngIf="Settingicon == true && navClass === 'nav-light'">
            <li class="list-inline-item mb-0 login-btn-primary">
                <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                   aria-controls="offcanvasRight" (click)="openright(content)">
                    <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn">
                        <i-feather name="settings"
                                   class="fea icon-sm"></i-feather>
                    </div>
                </a>
            </li>

            <li class="list-inline-item mb-0 login-btn-light">
                <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                   aria-controls="offcanvasRight" (click)="openright(content)">
                    <div class="btn btn-icon btn-pills btn-light" id="settingbtn">
                        <i-feather name="settings"
                                   class="fea icon-sm"></i-feather>
                    </div>
                </a>
            </li>

            <li class="list-inline-item ps-2 mb-0 login-btn-primary">
                <a rel='noopener' href="https://1.envato.market/landrick" target="_blank">
                    <div class="btn btn-icon btn-pills btn-primary" id="settingbtn">
                        <i-feather name="shopping-cart"
                                   class="fea icon-sm"></i-feather>
                    </div>
                </a>
            </li>

            <li class="list-inline-item ps-2 mb-0 login-btn-light">
                <a rel='noopener' href="https://1.envato.market/landrick" target="_blank">
                    <div class="btn btn-icon btn-pills btn-light" id="settingbtn">
                        <i-feather name="shopping-cart"
                                   class="fea icon-sm"></i-feather>
                    </div>
                </a>
            </li>
        </ul>

        <ul class="buy-button list-inline mb-0" *ngIf="Settingicon == true && navClass !== 'nav-light'">
            <li class="list-inline-item mb-0">
                <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                   aria-controls="offcanvasRight" (click)="openright(content)">
                    <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn">
                        <i-feather name="settings"
                                   class="fea icon-sm"></i-feather>
                    </div>
                </a>
            </li>

            <li class="list-inline-item ps-2 mb-0">
                <a rel='noopener' href="https://1.envato.market/landrick" target="_blank">
                    <div class="btn btn-icon btn-pills btn-primary" id="settingbtn">
                        <i-feather name="shopping-cart"
                                   class="fea icon-sm"></i-feather>
                    </div>
                </a>
            </li>
        </ul>
        <!--Login button End-->

        <!--Login button Start-->
        <ul class="buy-button list-inline mb-0" *ngIf="Menuoption == 'cryptocurrency'">
            <li class="list-inline-item mb-0">
                <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                   aria-controls="offcanvasRight">
                    <div class="login-btn-primary"><span class="btn btn-icon btn-pills btn-soft-primary"><i-feather
                            name="settings" class="fea icon-sm"></i-feather></span></div>
                    <div class="login-btn-light"><span class="btn btn-icon btn-pills btn-light"><i-feather
                            name="settings" class="fea icon-sm"></i-feather></span></div>
                </a>
            </li>

            <li class="list-inline-item ps-1 mb-0">
                <a rel='noopener' href="https://1.envato.market/landrick" target="_blank">
                    <div class="btn btn-icon btn-pills btn-primary">
                        <i-feather name="user" class="fea icon-sm"></i-feather>
                    </div>
                </a>
            </li>
        </ul>
        <!--Login button End-->

        <ul class="buy-button list-inline mb-0 app-header" *ngIf="appicons == true">
            <li class="list-inline-item mb-0">
                <a href="javascript:void(0)" class="btn btn-icon btn-light">
                    <img src="assets/images/app/app-store.png" class="avatar avatar-ex-small p-1" alt="">
                </a>
            </li>

            <li class="list-inline-item mb-0 ps-2">
                <a href="javascript:void(0)" class="btn btn-icon btn-light">
                    <img src="assets/images/app/play-store.png" class="avatar avatar-ex-small p-1" alt="">
                </a>
            </li>
        </ul>

        <ul class="buy-button list-inline mb-0" *ngIf="Nfticons == true">
            <li class="list-inline-item mb-0">
                <div class="dropdown">
                    <button type="button" class="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                        <!-- <i class="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                              <i class="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i> -->
                        <i class="uil uil-search text-dark fs-5 align-middle"></i>
                    </button>
                    <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0"
                         [style]="{'width': '300px'}">
                        <div class="search-bar">
                            <div id="itemSearch" class="menu-search mb-0">
                                <form role="search" method="get" id="searchItemform" class="searchform">
                                    <input type="text" class="form-control border rounded" name="s" id="searchItem"
                                           placeholder="Search...">
                                    <input type="submit" id="searchItemsubmit" value="Search">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li class="list-inline-item ps-1 mb-0">
                <a rel='noopener' href="https://1.envato.market/landrick" target="_blank"
                   class="btn btn-icon btn-pills btn-primary"><i
                        class="uil uil-wallet"></i></a>
            </li>
        </ul><!--end login button-->

        <div id="navigation" *ngIf="Menuoption == 'corporate'">
            <!-- Navigation Menu-->
            <ul class="navigation-menu justify-content-end" [class]="navClass">
                <li><a routerLink="/" class="nav-link-ref">Home</a></li>
                <!-- <li><a routerLink="/page-maintenance" class="nav-link-ref">Über uns</a></li> -->
                <li><a routerLink="/services" class="nav-link-ref">Leistungen</a></li>
                <li><a routerLink="/cases" class="nav-link-ref"> Kurse & Schulungen</a></li>
                <!-- <li class="has-submenu parent-menu-item">
                  <a href="javascript:void(0)">Seiten</a><span class="menu-arrow"></span>
                  <ul class="submenu">
                    <li><a routerLink="/page-maintenance" class="nav-link-ref"> Team</a></li>
                    <li><a routerLink="/plan-calculator" class="nav-link-ref">Preise</a></li>
                    <li><a routerLink="/page-maintenance" class="nav-link-ref">Unternehmensgeschichte</a></li>
                    <hr class='hr'>
                    <li><a routerLink="/auth-login" class="nav-link-ref">Login</a></li>
                  </ul>
                </li> -->
            </ul><!--end navigation menu-->
        </div><!--end navigation-->

    </div>
    <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->

<!-- Offcanvas Start -->
<ng-template #content let-offcanvas>
    <div class="offcanvas-header p-4 border-bottom">
        <h5 id="offcanvasRightLabel" class="mb-0">
            <img src="assets/images/logo-dark.png" class="light-version" alt="">
            <img src="assets/images/logo-light.png" class="dark-version" alt="">
        </h5>
        <button type="button" class="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas"
                aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="uil uil-times fs-4"></i>
        </button>
    </div>
    <div class="offcanvas-body p-4">
        <div class="row">
            <div class="col-12">
                <img src="assets/images/contact.svg" class="img-fluid d-block mx-auto" [style]="{'max-height': '256px'}"
                     alt="">
                <div class="card border-0 mt-5" [style]="{'z-index': 1}">
                    <div class="card-body p-0">
                        <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                            <p id="error-msg" class="mb-0"></p>
                            <div id="simple-msg"></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input name="name" id="name" type="text" class="form-control ps-5"
                                                   placeholder="Name :">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input name="email" id="email" type="email" class="form-control ps-5"
                                                   placeholder="Email :">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-3">
                                        <label class="form-label">Subject</label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                            <input name="subject" id="subject" class="form-control ps-5"
                                                   placeholder="subject :">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-3">
                                        <label class="form-label">Comments <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="message-circle"
                                                       class="fea icon-sm icons clearfix"></i-feather>
                                            <i-feather name="message-circle"
                                                       class="fea icon-sm icons clearfix"></i-feather>
                                            <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                                      placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-grid">
                                        <button type="submit" id="submit" name="send" class="btn btn-primary">Send
                                            Message
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="offcanvas-footer p-4 border-top text-center">
        <ul class="list-unstyled social-icon social mb-0 d-flex justify-content-center gap-1">
            <li class="list-inline-item mb-0"><a rel='noopener' href="https://1.envato.market/landrick" target="_blank"
                                                 class="rounded"><i
                    class="uil uil-shopping-cart align-middle" title="Buy Now"></i></a></li>
            <li class="list-inline-item mb-0"><a rel='noopener' href="https://dribbble.com/shreethemes" target="_blank"
                                                 class="rounded"><i
                    class="uil uil-dribbble align-middle" title="dribbble"></i></a></li>
            <li class="list-inline-item mb-0"><a rel='noopener' href="https://www.behance.net/shreethemes"
                                                 target="_blank" class="rounded"><i
                    class="uil uil-behance align-middle" title="behance"></i></a></li>
            <li class="list-inline-item mb-0"><a rel='noopener' href="https://www.facebook.com/shreethemes"
                                                 target="_blank"
                                                 class="rounded"><i class="uil uil-facebook-f align-middle"
                                                                    title="facebook"></i></a></li>
            <li class="list-inline-item mb-0"><a rel='noopener' href="https://www.instagram.com/shreethemes/"
                                                 target="_blank"
                                                 class="rounded"><i class="uil uil-instagram align-middle"
                                                                    title="instagram"></i></a></li>
            <li class="list-inline-item mb-0"><a rel='noopener' href="https://twitter.com/shreethemes" target="_blank"
                                                 class="rounded"><i
                    class="uil uil-twitter align-middle" title="twitter"></i></a></li>
            <li class="list-inline-item mb-0"><a href="mailto:support@shreethemes.in" class="rounded"><i
                    class="uil uil-envelope align-middle" title="email"></i></a></li>
            <li class="list-inline-item mb-0"><a rel='noopener' href="https://shreethemes.in" target="_blank"
                                                 class="rounded"><i
                    class="uil uil-globe align-middle" title="website"></i></a></li>
        </ul><!--end icon-->
    </div>
</ng-template>
<!-- Offcanvas End -->