<!-- Hero Start -->
<section
    class="bg-half-170 bg-light d-table w-100"
    id="home"
>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0">AGB</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav
                aria-label="breadcrumb"
                class="d-inline-block"
            >
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">SIB-Brandschutz</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0)">Seiten</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0)">AGB</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Allgemein :</h5>
                        <p class="text-muted">
                            Die Allgeme Geschäftsbedingungen (AGB) sind zurzeit in Bearbeitung.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a
    href="javascript: void(0);"
    class="btn btn-icon btn-primary back-to-top"
    id="back-to-top"
    [ngxScrollTo]="'#home'"
>
    <i-feather
        name="arrow-up"
        class="icons"
    ></i-feather>
</a>
<!-- Back to top -->
