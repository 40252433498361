import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './core/components/index/index.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageContactDetailComponent } from './core/components/page-contact-detail/page-contact-detail.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { PageThankyouComponent } from './core/components/page-thankyou/page-thankyou.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { CorporateAboutComponent } from './core/components/page-about/corporate-about.component';
import { CorporateServicesComponent } from './core/components/page-services/corporate-services.component';
import { CorporateTeamComponent } from './core/components/page-team/corporate-team.component';
import { CorporatePricingComponent } from './core/components/page-pricing/corporate-pricing.component';
import { PageImpressumComponent } from './core/components/page-impressum/page-impressum.component';
import { PageCasesComponent } from './core/components/page-cases/page-cases.component';
// import { PageCaseDetailComponent } from './core/components/page-case-detail/page-case-detail.component';
import { PagePlanCalculatorComponent } from './core/components/page-plan-calculator/page-plan-calculator.component';

const routes: Routes = [
    {
        path: '',
        component: MasterPageComponent,
        children: [
            { path: '', component: IndexComponent },
            { path: 'index', component: IndexComponent },
            {
                path: 'contact-detail',
                component: PageContactDetailComponent,
            },
            { path: 'privacy', component: PagePrivacyComponent },
            { path: 'terms', component: PageTermsComponent },
            { path: 'impressum', component: PageImpressumComponent},
            { path: 'aboutus', component: CorporateAboutComponent },
            { path: 'services', component: CorporateServicesComponent},
            { path: 'team', component: CorporateTeamComponent },
            { path: 'pricing', component: CorporatePricingComponent },
            { path: 'cases', component: PageCasesComponent },
            // { path: 'case-detail', component: PageCaseDetailComponent },
            { path: 'plan-calculator', component: PagePlanCalculatorComponent },
            { path: 'contact-details', component: PageContactDetailComponent },
        ],
    },
    { path: 'page-thankyou', component: PageThankyouComponent },
    { path: 'page-maintenance', component: PageMaintenanceComponent },

    { path: '**', component: PageErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            scrollOffset: [0, 0],
            // Enable scrolling to anchors
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
