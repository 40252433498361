<!-- Hero Start -->
<section
    class="bg-half-170 d-table w-100 background-image"
    id="home"
>
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h2 class="text-white title-dark">Über uns</h2>
                    <p class="text-white-50 para-desc mb-0 mx-auto">
                        Herzlich Willkommen auf unserer Homepage - hier erfahren
                        Sie alles Wichtige und Interessante über uns und unser
                        Unternehmen.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav
                aria-label="breadcrumb"
                class="d-inline-block"
            >
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item">
                        <a routerLink="/index">SIB Brandschutz</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/index-corporate">Business</a>
                    </li>
                    <li
                        class="breadcrumb-item active"
                        aria-current="page"
                    >
                        Über uns
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div
                            class="card work-container work-primary work-modern overflow-hidden rounded border-0 shadow-md"
                        >
                            <div class="card-body p-0">
                                <img
                                    src="assets/images/course/online/ab01.jpg"
                                    class="img-fluid"
                                    alt="work-image"
                                />
                                <div class="overlay-work"></div>
                                <div class="content">
                                    <a
                                        href="javascript:void(0)"
                                        class="title text-white d-block fw-bold"
                                        >Web & App Entwicklung</a
                                    >
                                    <small class="text-white-50"
                                        >IT & Software</small
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 col-6">
                        <div class="row">
                            <div
                                class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0"
                            >
                                <div
                                    class="card work-container work-primary work-modern overflow-hidden rounded border-0 shadow-md"
                                >
                                    <div class="card-body p-0">
                                        <img
                                            src="assets/images/course/online/ab02.jpg"
                                            class="img-fluid"
                                            alt="work-image"
                                        />
                                        <div class="overlay-work"></div>
                                        <div class="content">
                                            <a
                                                href="javascript:void(0)"
                                                class="title text-white d-block fw-bold"
                                                >Ausbildung</a
                                            >
                                            <small class="text-white-50"
                                                >Ingenieurwesen
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->

                            <div class="col-lg-12 col-md-12 mt-4 pt-2">
                                <div
                                    class="card work-container work-primary work-modern overflow-hidden rounded border-0 shadow-md"
                                >
                                    <div class="card-body p-0">
                                        <img
                                            src="assets/images/course/online/ab03.jpg"
                                            class="img-fluid"
                                            alt="work-image"
                                        />
                                        <div class="overlay-work"></div>
                                        <div class="content">
                                            <a
                                                href="javascript:void(0)"
                                                class="title text-white d-block fw-bold"
                                            >
                                                Tech. Zeichnungen
                                            </a>
                                            <small class="text-white-50"
                                                >Pläne jeglicher Art</small
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!-- end row -->
                    </div>
                    <!-- end col -->
                </div>
                <!--end row-->
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                <div class="ms-lg-4">
                    <div class="section-title">
                        <span class="badge bg-soft-primary rounded-pill fw-bold"
                            >Über uns</span
                        >
                        <h4 class="title mb-4 mt-3">
                            Unternehmensbeschreibung.
                        </h4>
                        <p class="text-muted para-desc">
                            Wir sind die
                            <span class="text-primary fw-bold"
                                >SIB Brandschutz GmbH</span
                            >, eines der innovativsten Medienunternehmen in
                            Deutschland. Als führender Anbieter multimedialer
                            Fachinformationslösungen bieten wir unseren Kunden
                            im Business-to-Business- und
                            Business-to-Government-Bereich praxisorientierte
                            Lösungen für ihre tägliche Arbeit. <br />
                            Unser breit gefächertes Produktportfolio richtet
                            sich speziell an Fach- und Führungskräfte in den
                            Bereichen Management, Bau und Technik.
                        </p>
                    </div>

                    <div class="mt-4 pt-2">
                        <a
                            rel="noopener"
                            href="/services"
                            class="btn btn-primary m-1"
                            >Zu den Leistungen
                            <i class="uil uil-angle-right-b align-middle"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div
                    class="video-solution-cta position-relative"
                    style="z-index: 1"
                >
                    <div class="position-relative">
                        <img
                            src="assets/images/cta-bg.jpg"
                            class="img-fluid rounded-md shadow-lg"
                            alt=""
                        />
                        <div class="play-icon">
                            <a
                                href="#!"
                                data-type="youtube"
                                data-id="yba7hPeTSjk"
                                class="play-btn lightbox border-0"
                            >
                                <i
                                    class="mdi mdi-play text-primary rounded-circle shadow-lg"
                                ></i>
                            </a>
                        </div>
                    </div>
                    <div class="content mt-md-4 pt-md-2">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 text-center">
                                <div class="row align-items-center">
                                    <div class="col-md-6 mt-4 pt-2">
                                        <div
                                            class="section-title text-md-start"
                                        >
                                            <h6 class="text-white-50">Team</h6>
                                            <h4
                                                class="title text-white title-dark mb-0"
                                            >
                                                Erfahrungen sammeln <br />
                                                mit dem Team
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-12 mt-4 pt-md-2">
                                        <div
                                            class="section-title text-md-start"
                                        >
                                            <p class="text-white-50 para-desc">
                                                Erfahren Sie mehr über unser
                                                Team und lernen Sie die
                                                Mitarbeiter kennen, die hinter
                                                dem Erfolg von
                                                <span class="fw-bold"
                                                    >SIB Brandschutz GmbH</span
                                                >
                                                stehen.
                                            </p>
                                            <a
                                                href="/team"
                                                class="text-white title-dark"
                                                >Mehr erfahren
                                                <i-feather
                                                    name="arrow-right"
                                                    class="fea icon-sm"
                                                ></i-feather
                                            ></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row -->
        <div class="feature-posts-placeholder bg-primary bg-gradient"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section">
    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Unsere Kunden sagen</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        Arbeiten Sie mit
                        <span class="text-primary fw-bold"
                            >SIB Brandschutz GmbH</span
                        >
                        zusammen, das Ihnen alles bietet, was Sie brauchen, um
                        Aufmerksamkeit zu erregen, den Verkehr anzukurbeln und
                        Verbindungen herzustellen.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <owl-carousel-o
                    id="customer-testi"
                    [options]="customOptions"
                >
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/01.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " It seems that only fragments of the
                                        original text remain in the Lorem Ipsum
                                        texts used today. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Thomas Israel
                                        <small class="text-muted">C.E.O</small>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/02.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star-half text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " One disadvantage of Lorum Ipsum is
                                        that in Latin certain letters appear
                                        more frequently than others. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Barbara McIntosh
                                        <small class="text-muted">M.D</small>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/03.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " The most well-known dummy text is the
                                        'Lorem Ipsum', which is said to have
                                        originated in the 16th century. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Carl Oliver
                                        <small class="text-muted">P.A</small>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/04.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " According to most sources, Lorum Ipsum
                                        can be traced back to a text composed by
                                        Cicero. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Christa Smith
                                        <small class="text-muted"
                                            >Manager</small
                                        >
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/05.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " There is now an abundance of readable
                                        dummy texts. These are usually used when
                                        a text is required. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Dean Tolle
                                        <small class="text-muted"
                                            >Developer</small
                                        >
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="d-flex client-testi m-2">
                                <img
                                    src="assets/images/client/06.jpg"
                                    class="avatar avatar-small client-image rounded shadow"
                                    alt=""
                                />
                                <div
                                    class="card flex-1 content p-3 shadow rounded position-relative"
                                >
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                class="mdi mdi-star text-warning"
                                            ></i>
                                        </li>
                                    </ul>
                                    <p class="text-muted mt-2">
                                        " Thus, Lorem Ipsum has only limited
                                        suitability as a visual filler for
                                        German texts. "
                                    </p>
                                    <h6 class="text-primary">
                                        - Jill Webb
                                        <small class="text-muted"
                                            >Designer</small
                                        >
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
        <!--end col-->
    </div>
    <!--end row-->
</section>
<!--end section-->

<!-- Back to top -->
<a
    href="javascript: void(0);"
    class="btn btn-icon btn-primary back-to-top"
    id="back-to-top"
    [ngxScrollTo]="'#home'"
>
    <i-feather
        name="arrow-up"
        class="icons"
    ></i-feather>
</a>
