<div *ngIf="!hideFooter">
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-py-60">
                            <div class="row">
                                <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <a href="javascript:void(0)" class="logo-footer">
                                        <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 294 160"
                                                height="160"
                                                width="294"
                                                id="svg2"
                                        >
                                            <g transform="matrix(0.9333,0,0,-0.935,0,160)" id="g10">
                                                <g transform="scale(0.1)" id="g12">
                                                    <path id="path14"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 494.262,65.3086 c -0.434,4.3828 -1.992,7.6211 -4.731,9.7695 -2.73,2.1133 -6.832,3.1602 -12.301,3.1602 -10.082,0 -15.121,-3.4375 -15.121,-10.3477 0,-2.4609 1.051,-4.5312 3.121,-6.2109 2.11,-1.6797 5.469,-3.0508 10.079,-4.1797 5.472,-1.25 10.203,-2.4609 14.222,-3.5508 3.989,-1.1406 7.66,-2.4297 11.02,-3.8711 1.871,-0.7383 3.629,-1.7187 5.308,-2.8476 1.641,-1.1797 3.051,-2.6211 4.219,-4.3008 1.133,-1.7188 2.07,-3.75 2.731,-6.168 0.711,-2.3828 1.019,-5.2812 1.019,-8.7109 0,-4.0703 -0.816,-7.6992 -2.539,-10.8594 -1.719,-3.1719 -4.019,-5.8203 -6.91,-8.01171 -2.899,-2.19141 -6.328,-3.82813 -10.238,-5 -3.942,-1.12891 -8.09,-1.71875 -12.422,-1.71875 -12.379,0 -21.68,2.3789 -27.93,7.14844 -6.211,4.80082 -9.527,11.87112 -9.84,21.28902 h 17.141 c 0.082,-4.3789 1.84,-7.8086 5.32,-10.3086 3.43,-2.539 7.77,-3.789 13,-3.789 5.629,0 9.891,1.0078 12.781,3.0781 2.93,2.0313 4.368,4.8125 4.368,8.3203 0,1.3711 -0.149,2.6211 -0.508,3.75 -0.352,1.1719 -1.012,2.2305 -1.992,3.1719 -0.969,0.9297 -2.34,1.7578 -4.02,2.5 -1.719,0.6992 -3.949,1.3594 -6.68,1.9492 -6.48,1.3711 -12.07,2.7695 -16.839,4.1797 -4.719,1.4102 -8.629,3.1602 -11.719,5.2305 -3.039,2.1093 -5.313,4.6484 -6.75,7.6211 -1.492,3.0078 -2.192,6.7968 -2.192,11.4101 0,3.5078 0.621,6.7891 1.911,9.8789 1.289,3.0508 3.25,5.7383 5.902,8.0078 2.656,2.2227 5.898,4.0235 9.808,5.3516 3.86,1.3281 8.43,1.9883 13.75,1.9883 5.11,0 9.719,-0.6602 13.821,-1.9883 4.109,-1.3281 7.621,-3.1992 10.551,-5.6289 2.968,-2.4219 5.238,-5.3906 6.878,-8.8203 1.598,-3.4805 2.5,-7.3125 2.692,-11.4922 z"/>
                                                    <path id="path16"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 656.211,71.3281 h -0.16 L 645.199,37.5391 H 666.84 Z M 677.301,5.39063 671.949,22.9297 H 640.59 L 634.57,5.39063 H 615.738 L 646.09,90.4297 h 20.59 L 696.641,5.39063 Z"/>
                                                    <path id="path18"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 823.629,41.25 V 5.39063 h -17.77 V 90.4297 H 866.41 V 75.5898 H 823.629 V 56.25 h 37.391 v -15 z"/>
                                                    <path id="path20"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 969.379,5.39063 V 90.4297 h 62.501 V 75.5898 h -45.2 V 57.3789 h 41.6 v -14.957 h -41.6 V 20.5117 h 47.23 V 5.39063 Z"/>
                                                    <path id="path22"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 1160.62,5.39063 V 75.5898 H 1135 v 14.8399 h 69.14 V 75.5898 h -25.7 V 5.39063 Z"/>
                                                    <path id="path24"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 1340,5.39063 V 37.5391 l -29.45,52.8906 h 20.74 l 17.93,-34.5703 16.52,34.5703 h 20.47 L 1357.77,37.2695 V 5.39063 Z"/>
                                                    <path id="path26"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 1599.02,5.39063 V 90.4297 h 62.5 V 75.5898 h -45.19 V 57.3789 h 41.6 v -14.957 h -41.6 V 20.5117 h 47.22 V 5.39063 Z"/>
                                                    <path id="path28"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 1821.45,5.39063 1805.16,34.6094 1788.01,5.39063 h -20.74 l 27.53,43.43747 -26.01,41.6016 h 21.01 l 15.36,-27.5391 15.5,27.5391 h 20.63 l -25.86,-40.8594 27.66,-44.17967 z"/>
                                                    <path id="path30"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 1986.84,50.7383 c 5.11,0 8.82,1.1719 11.09,3.5234 2.23,2.3399 3.36,5.3867 3.36,9.1367 0,3.5899 -1.13,6.5235 -3.36,8.8321 -2.27,2.3008 -5.98,3.4687 -11.09,3.4687 h -16.92 V 50.7383 Z M 1952.15,5.39063 V 90.4297 h 37.5 c 4.18,0 7.93,-0.6992 11.29,-2.1094 3.32,-1.4101 6.17,-3.3984 8.55,-5.8984 2.39,-2.5 4.22,-5.4336 5.51,-8.793 1.29,-3.3203 1.91,-6.9101 1.91,-10.7383 0,-3.832 -0.62,-7.3789 -1.91,-10.6992 -1.29,-3.2812 -3.12,-6.1016 -5.51,-8.4414 -2.38,-2.3398 -5.23,-4.1797 -8.55,-5.5117 -3.36,-1.3281 -7.11,-1.9883 -11.29,-1.9883 h -19.73 V 5.39063 Z"/>
                                                    <path id="path32"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 2120.66,5.39063 V 90.4297 h 62.46 V 75.5898 h -45.19 V 57.3789 h 41.64 v -14.957 h -41.64 V 20.5117 h 47.27 V 5.39063 Z"/>
                                                    <path id="path34"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 2307.97,75.6992 V 53.0508 h 22.26 c 4.77,0 8.17,1.0508 10.12,3.1992 1.95,2.1094 2.93,4.8008 2.93,8.0508 0,3.3203 -0.94,6.0508 -2.85,8.1992 -1.95,2.1484 -5.12,3.1992 -9.57,3.1992 z M 2340.23,5.39063 V 27.3008 c 0,4.1094 -0.93,7.039 -2.81,8.75 -1.87,1.7578 -5.39,2.6601 -10.51,2.6601 h -18.94 V 5.39063 h -17.31 V 90.4297 h 43.17 c 3.9,0 7.5,-0.6289 10.7,-1.8789 3.17,-1.211 5.94,-2.9297 8.24,-5.1094 2.31,-2.1914 4.07,-4.7695 5.32,-7.8203 1.25,-3 1.87,-6.2813 1.87,-9.8008 0,-4.6094 -1.09,-8.6719 -3.28,-12.2305 -2.19,-3.5117 -5.31,-6.0117 -9.41,-7.4609 3.75,-1.207 6.4,-3.0391 8,-5.5078 1.57,-2.5 2.39,-6.2109 2.39,-11.1328 V 5.39063 Z"/>
                                                    <path id="path36"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 2490.55,5.39063 V 75.5898 h -25.59 v 14.8399 h 69.14 V 75.5898 h -25.74 V 5.39063 Z"/>
                                                    <path id="path38"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 2686.48,65.3086 c -0.43,4.3828 -2.03,7.6211 -4.72,9.7695 -2.74,2.1133 -6.84,3.1602 -12.31,3.1602 -10.07,0 -15.11,-3.4375 -15.11,-10.3477 0,-2.4609 1.05,-4.5312 3.12,-6.2109 2.11,-1.6797 5.47,-3.0508 10.08,-4.1797 5.47,-1.25 10.19,-2.4609 14.22,-3.5508 3.98,-1.1406 7.65,-2.4297 10.97,-3.8711 1.88,-0.7383 3.68,-1.7187 5.32,-2.8476 1.68,-1.1797 3.08,-2.6211 4.25,-4.3008 1.14,-1.7188 2.08,-3.75 2.74,-6.168 0.7,-2.3828 1.01,-5.2812 1.01,-8.7109 0,-4.0703 -0.85,-7.6992 -2.53,-10.8594 -1.72,-3.1719 -4.03,-5.8203 -6.92,-8.01171 -2.93,-2.19141 -6.33,-3.82813 -10.23,-5 -3.95,-1.12891 -8.09,-1.71875 -12.42,-1.71875 -12.39,0 -21.72,2.3789 -27.93,7.14844 -6.25,4.80082 -9.54,11.87112 -9.85,21.28902 h 17.15 c 0.08,-4.3789 1.84,-7.8086 5.31,-10.3086 3.44,-2.539 7.78,-3.789 13.01,-3.789 5.63,0 9.88,1.0078 12.77,3.0781 2.93,2.0313 4.38,4.8125 4.38,8.3203 0,1.3711 -0.2,2.6211 -0.51,3.75 -0.35,1.1719 -1.01,2.2305 -1.99,3.1719 -0.98,0.9297 -2.34,1.7578 -4.02,2.5 -1.72,0.6992 -3.95,1.3594 -6.68,1.9492 -6.49,1.3711 -12.11,2.7695 -16.84,4.1797 -4.73,1.4102 -8.63,3.1602 -11.72,5.2305 -3.08,2.1093 -5.31,4.6484 -6.8,7.6211 -1.44,3.0078 -2.14,6.7968 -2.14,11.4101 0,3.5078 0.62,6.7891 1.91,9.8789 1.29,3.0508 3.24,5.7383 5.9,8.0078 2.62,2.2227 5.9,4.0235 9.76,5.3516 3.91,1.3281 8.48,1.9883 13.79,1.9883 5.12,0 9.73,-0.6602 13.83,-1.9883 4.1,-1.3281 7.62,-3.1992 10.55,-5.6289 2.97,-2.4219 5.23,-5.3906 6.87,-8.8203 1.6,-3.4805 2.5,-7.3125 2.66,-11.4922 z"/>
                                                    <path id="path40"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 262.578,244.301 c 0,-5.86 -1.019,-12.153 -3.008,-18.871 -2.031,-6.719 -5.308,-12.969 -9.922,-18.711 -4.57,-5.739 -10.507,-10.508 -17.847,-14.379 -7.352,-3.82 -16.332,-5.781 -26.961,-5.781 H 23.9805 c -3.3907,0 -6.5586,0.632 -9.4922,1.839 -2.9297,1.25 -5.46877,2.93 -7.60939,5.043 -2.14844,2.149 -3.82813,4.68 -5.03907,7.61 C 0.621094,203.98 0,207.148 0,210.59 v 214.762 c 0,3.277 0.621094,6.41 1.83984,9.339 1.21094,2.93 2.89063,5.508 5.03907,7.688 2.14062,2.191 4.67969,3.91 7.60939,5.121 2.9336,1.211 6.1015,1.84 9.4922,1.84 H 183.441 c 5.86,0 12.149,-1.02 18.86,-3.129 6.719,-2.07 12.969,-5.391 18.75,-9.961 5.828,-4.609 10.668,-10.551 14.5,-17.891 3.859,-7.308 5.777,-16.289 5.777,-26.91 v -8.988 c 0,-8.441 -1.406,-17.5 -4.219,-27.231 -2.808,-9.679 -7.339,-18.902 -13.558,-27.57 5.359,-3.281 10.398,-7.269 15.121,-11.879 4.687,-4.652 8.828,-10.043 12.348,-16.133 3.55,-6.128 6.359,-13.007 8.429,-20.699 2.071,-7.699 3.129,-16.219 3.129,-25.469 z m -47.656,9.179 c 0,6.211 -0.973,11.79 -2.934,16.75 -1.949,4.93 -4.687,9.18 -8.238,12.739 -3.52,3.551 -7.809,6.25 -12.809,8.16 -5,1.883 -10.632,2.82 -16.882,2.82 H 66.6797 v 48 h 85.9413 c 6.25,0 11.867,0.981 16.867,2.852 5,1.879 9.301,4.609 12.813,8.168 3.558,3.511 6.289,7.769 8.168,12.73 1.91,4.922 2.851,10.512 2.851,16.762 v 8.988 c 0,6.832 -3.32,10.27 -9.879,10.27 H 47.6602 v -167.5 H 204.84 c 0.859,0 1.922,0.082 3.129,0.191 1.211,0.121 2.34,0.469 3.32,1.102 0.981,0.617 1.801,1.636 2.539,3.117 0.742,1.449 1.094,3.48 1.094,6.019 z"/>
                                                    <path id="path42"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="M 520.039,293.949 H 369.57 v 48 h 150.469 z m 21.41,-107.39 H 369.57 c -6.558,0 -13.789,1.171 -21.601,3.519 -7.809,2.301 -15.078,6.133 -21.719,11.442 -6.641,5.32 -12.23,12.23 -16.68,20.71 -4.449,8.469 -6.679,18.899 -6.679,31.25 v 171.872 c 0,3.277 0.629,6.41 1.839,9.339 1.211,2.93 2.891,5.508 5.04,7.688 2.14,2.191 4.64,3.91 7.609,5.121 2.93,1.211 6.101,1.84 9.5,1.84 h 214.57 V 401.719 H 350.551 V 253.48 c 0,-6.25 1.64,-11.019 4.918,-14.3 3.32,-3.321 8.121,-4.961 14.492,-4.961 h 171.488 z"/>
                                                    <path id="path44"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 850.039,206.91 c 0,-3.39 -0.66,-6.601 -1.949,-9.531 -1.25,-2.93 -2.969,-5.469 -5.121,-7.578 -2.149,-2.141 -4.649,-3.821 -7.617,-5.031 -2.93,-1.25 -6.012,-1.84 -9.332,-1.84 -2.93,0 -5.899,0.55 -8.872,1.64 -3.007,1.09 -5.668,2.852 -7.968,5.309 L 635.078,371.641 V 186.559 H 587.461 V 428.98 c 0,4.93 1.367,9.34 4.098,13.321 2.781,3.949 6.293,6.918 10.55,8.871 4.532,1.84 9.18,2.308 13.942,1.367 4.769,-0.898 8.828,-3.129 12.269,-6.68 L 802.379,264.262 V 449.34 h 47.66 z"/>
                                                    <path id="path46"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 1157.85,263.91 c 0,-9.422 -1.17,-17.859 -3.55,-25.39 -2.39,-7.5 -5.55,-14.141 -9.46,-19.891 -3.9,-5.738 -8.47,-10.617 -13.75,-14.649 -5.23,-4.019 -10.66,-7.339 -16.29,-9.878 -5.62,-2.582 -11.32,-4.454 -17.14,-5.704 -5.78,-1.207 -11.25,-1.839 -16.37,-1.839 H 897.5 v 47.66 h 183.79 c 9.14,0 16.25,2.691 21.33,8.051 5.08,5.39 7.61,12.609 7.61,21.64 0,4.41 -0.66,8.43 -2.03,12.11 -1.32,3.628 -3.28,6.832 -5.78,9.531 -2.5,2.648 -5.54,4.719 -9.14,6.211 -3.59,1.476 -7.62,2.187 -11.99,2.187 H 971.68 c -7.7,0 -15.981,1.36 -24.918,4.141 -8.91,2.73 -17.192,7.18 -24.813,13.281 -7.648,6.09 -13.98,14.098 -19.058,23.981 -5.082,9.918 -7.621,21.988 -7.621,36.289 0,14.3 2.539,26.371 7.621,36.211 5.078,9.808 11.41,17.808 19.058,23.988 7.621,6.172 15.903,10.621 24.813,13.39 8.937,2.739 17.218,4.11 24.918,4.11 h 162.19 V 401.719 H 971.68 c -9.02,0 -16.09,-2.77 -21.129,-8.278 -5.082,-5.472 -7.621,-12.742 -7.621,-21.8 0,-9.141 2.539,-16.411 7.621,-21.719 5.039,-5.313 12.109,-7.973 21.129,-7.973 h 109.96 c 5.12,-0.109 10.55,-0.82 16.29,-2.109 5.74,-1.25 11.48,-3.238 17.15,-5.93 5.66,-2.699 11.09,-6.101 16.21,-10.16 5.16,-4.102 9.69,-9.02 13.67,-14.77 3.95,-5.742 7.11,-12.339 9.42,-19.8 2.34,-7.418 3.47,-15.86 3.47,-25.27 z"/>
                                                    <path id="path48"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 1413.48,293.949 h -150.43 v 48 h 150.43 z m 21.44,-107.39 h -171.87 c -6.6,0 -13.83,1.171 -21.64,3.519 -7.82,2.301 -15.04,6.133 -21.72,11.442 -6.64,5.32 -12.19,12.23 -16.64,20.71 -4.5,8.469 -6.72,18.899 -6.72,31.25 v 171.872 c 0,3.277 0.62,6.41 1.83,9.339 1.25,2.93 2.93,5.508 5.04,7.688 2.15,2.191 4.69,3.91 7.62,5.121 2.93,1.211 6.09,1.84 9.53,1.84 h 214.57 V 401.719 H 1243.98 V 253.48 c 0,-6.25 1.64,-11.019 4.97,-14.3 3.28,-3.321 8.12,-4.961 14.45,-4.961 h 171.52 z"/>
                                                    <path id="path50"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 1743.48,361.02 c 0,-10.86 -1.33,-20.59 -4.03,-29.141 -2.69,-8.559 -6.33,-16.098 -10.9,-22.617 -4.57,-6.532 -9.88,-12.114 -15.85,-16.762 -5.98,-4.648 -12.23,-8.48 -18.79,-11.449 -6.53,-3 -13.13,-5.192 -19.77,-6.52 -6.68,-1.332 -12.93,-2.031 -18.79,-2.031 l 99.49,-85.941 h -73.67 l -99.29,85.941 h -34.26 v 47.66 h 107.73 c 5.98,0.5 11.45,1.75 16.41,3.75 4.92,2.031 9.22,4.719 12.81,8.16 3.59,3.442 6.41,7.539 8.36,12.379 1.95,4.813 2.93,10.352 2.93,16.571 v 30.031 c 0,2.699 -0.35,4.769 -1.02,6.179 -0.66,1.399 -1.52,2.418 -2.57,3.079 -1.02,0.711 -2.15,1.101 -3.29,1.211 -1.17,0.121 -2.22,0.199 -3.2,0.199 h -157.23 v -215.16 h -47.65 v 238.793 c 0,3.277 0.62,6.41 1.83,9.339 1.22,2.93 2.89,5.508 5.04,7.688 2.15,2.191 4.69,3.91 7.62,5.121 2.93,1.211 6.09,1.84 9.53,1.84 h 180.86 c 10.63,0 19.61,-1.918 26.92,-5.789 7.34,-3.821 13.28,-8.672 17.89,-14.449 4.57,-5.821 7.85,-12.071 9.88,-18.793 1.99,-6.719 3.01,-13.008 3.01,-18.86 z"/>
                                                    <path id="path52"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 2172.62,210.59 c 0,-3.442 -0.63,-6.61 -1.84,-9.539 -1.21,-2.93 -2.89,-5.461 -5.04,-7.61 -2.15,-2.113 -4.65,-3.793 -7.58,-5.043 -2.93,-1.207 -6.13,-1.839 -9.53,-1.839 h -171.87 c -4.42,0 -9.14,0.55 -14.22,1.562 -5.08,1.059 -10.08,2.66 -15.04,4.891 -4.92,2.187 -9.65,5.039 -14.18,8.508 -4.53,3.48 -8.52,7.699 -11.99,12.621 -3.48,4.961 -6.29,10.699 -8.36,17.23 -2.07,6.559 -3.13,13.899 -3.13,22.109 v 128.981 c 0,4.418 0.55,9.141 1.57,14.219 1.05,5.039 2.65,10.082 4.88,15 2.19,4.961 5.04,9.691 8.59,14.218 3.56,4.493 7.78,8.512 12.74,11.993 4.96,3.48 10.66,6.25 17.15,8.32 6.44,2.109 13.78,3.129 21.99,3.129 h 193.67 v -47.621 h -193.67 c -6.25,0 -11.02,-1.68 -14.3,-4.957 -3.32,-3.321 -4.96,-8.211 -4.96,-14.653 V 253.48 c 0,-6.14 1.68,-10.859 5.04,-14.218 3.36,-3.364 8.08,-5.043 14.22,-5.043 H 2125 v 59.73 h -126.84 v 48 h 150.47 c 3.4,0 6.6,-0.621 9.53,-1.91 2.93,-1.289 5.43,-3.008 7.58,-5.238 2.15,-2.18 3.83,-4.723 5.04,-7.571 1.21,-2.89 1.84,-5.98 1.84,-9.261 z"/>
                                                    <path id="path54"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 2528.09,186.559 h -48.01 v 156.492 l -84.46,-148.602 c -2.07,-3.789 -5,-6.64 -8.71,-8.59 -3.71,-1.949 -7.73,-2.929 -11.99,-2.929 -4.18,0 -8.04,0.98 -11.64,2.929 -3.59,1.95 -6.44,4.801 -8.51,8.59 l -84.85,148.602 V 186.559 h -47.65 V 428.98 c 0,5.508 1.64,10.399 4.88,14.692 3.24,4.258 7.46,7.148 12.73,8.598 2.54,0.621 5.12,0.82 7.7,0.66 2.54,-0.2 5.04,-0.739 7.42,-1.68 2.38,-0.898 4.53,-2.191 6.48,-3.828 1.96,-1.641 3.6,-3.633 4.97,-5.973 L 2374.92,252.93 2483.4,441.449 c 2.81,4.641 6.68,7.961 11.64,9.922 4.92,1.949 10.08,2.258 15.47,0.899 5.11,-1.45 9.33,-4.34 12.65,-8.598 3.29,-4.293 4.93,-9.184 4.93,-14.692 z"/>
                                                    <path id="path56"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 2845.66,244.301 c 0,-5.86 -1.01,-12.153 -3.04,-18.871 -2,-6.719 -5.32,-12.969 -9.89,-18.711 -4.57,-5.739 -10.54,-10.508 -17.89,-14.379 -7.3,-3.82 -16.29,-5.781 -26.91,-5.781 h -180.86 c -3.4,0 -6.6,0.632 -9.53,1.839 -2.93,1.25 -5.47,2.93 -7.62,5.043 -2.11,2.149 -3.79,4.68 -5.04,7.61 -1.21,2.929 -1.79,6.097 -1.79,9.539 v 214.762 c 0,3.277 0.58,6.41 1.79,9.339 1.25,2.93 2.93,5.508 5.04,7.688 2.15,2.191 4.69,3.91 7.62,5.121 2.93,1.211 6.13,1.84 9.53,1.84 h 159.41 c 5.86,0 12.15,-1.02 18.87,-3.129 6.72,-2.07 13.01,-5.391 18.79,-9.961 5.82,-4.609 10.63,-10.551 14.49,-17.891 3.83,-7.308 5.75,-16.289 5.75,-26.91 v -8.988 c 0,-8.441 -1.41,-17.5 -4.18,-27.231 -2.82,-9.679 -7.35,-18.902 -13.6,-27.57 5.39,-3.281 10.43,-7.269 15.12,-11.879 4.73,-4.652 8.83,-10.043 12.38,-16.133 3.56,-6.128 6.37,-13.007 8.44,-20.699 2.07,-7.699 3.12,-16.219 3.12,-25.469 z m -47.65,9.179 c 0,6.211 -0.98,11.79 -2.93,16.75 -1.96,4.93 -4.73,9.18 -8.24,12.739 -3.56,3.551 -7.82,6.25 -12.86,8.16 -5,1.883 -10.62,2.82 -16.83,2.82 h -107.38 v 48 h 85.93 c 6.25,0 11.84,0.981 16.88,2.852 5,1.879 9.26,4.609 12.81,8.168 3.56,3.511 6.25,7.769 8.16,12.73 1.88,4.922 2.82,10.512 2.82,16.762 v 8.988 c 0,6.832 -3.28,10.27 -9.89,10.27 H 2630.7 v -167.5 h 157.23 c 0.86,0 1.87,0.082 3.12,0.191 1.22,0.121 2.31,0.469 3.29,1.102 0.97,0.617 1.83,1.636 2.57,3.117 0.75,1.449 1.1,3.48 1.1,6.019 z"/>
                                                    <path id="path58"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                                          d="m 2935.08,341.949 h 167.26 V 449.34 H 3150 V 186.559 h -47.66 v 107.39 h -167.26 v -107.39 h -47.66 V 449.34 h 47.66 z"/>
                                                    <path id="path60"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                                          d="m 1614.61,1710 c -79.38,0 -155.31,-15.31 -227.81,-45.98 -39.61,-16.75 -76.64,-37.38 -111.1,-61.95 h 43.75 c 24.34,15.08 49.93,28.28 76.76,39.65 69.49,29.37 142.27,44.06 218.4,44.06 76.09,0 148.87,-14.69 218.36,-44.06 26.8,-11.37 52.42,-24.57 76.76,-39.65 h 43.75 c -34.46,24.57 -71.49,45.2 -111.1,61.95 -72.5,30.67 -148.43,45.98 -227.77,45.98 z"/>
                                                    <path id="path62"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                                          d="m 1164.3,674.449 h 900.58 V 1575.08 H 1164.3 v -81.92 h 818.71 V 756.371 H 1164.3 Z"/>
                                                    <path id="path64"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                                          d="m 2199.84,1124.77 c 0,79.37 -15.35,155.31 -46.01,227.81 -16.76,39.57 -37.38,76.6 -61.92,111.09 v -43.75 c 15.08,-24.37 28.29,-49.96 39.61,-76.8 29.38,-69.45 44.1,-142.26 44.1,-218.35 0,-76.1 -14.72,-148.911 -44.1,-218.36 -11.32,-26.84 -24.53,-52.43 -39.61,-76.801 v -43.75 c 24.54,34.493 45.16,71.52 61.92,111.09 30.66,72.5 46.01,148.441 46.01,227.821 z"/>
                                                    <path id="path66"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                                          d="m 1029.38,1124.77 c 0,-79.38 15.31,-155.321 45.97,-227.821 16.76,-39.57 37.38,-76.597 61.92,-111.09 v 43.75 c -15.08,24.371 -28.29,49.961 -39.61,76.801 -29.42,69.449 -44.11,142.26 -44.11,218.36 0,76.09 14.69,148.9 44.11,218.35 11.32,26.84 24.53,52.43 39.61,76.8 v 43.75 c -24.54,-34.49 -45.16,-71.52 -61.92,-111.09 -30.66,-72.5 -45.97,-148.44 -45.97,-227.81 z"/>
                                                    <path id="path68"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                                          d="m 1164.3,1165.59 c 30.08,0.11 704.96,0.11 736.79,0.11 v 245.59 H 1164.3 v -81.88 h 654.92 V 1247.5 H 1164.3 Z"/>
                                                    <path id="path70"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                                          d="m 1164.3,838.16 c 30.08,0.078 704.96,0.078 736.79,0.078 V 1083.83 H 1164.3 v -81.92 h 654.92 V 920.039 H 1164.3 Z"/>
                                                    <path id="path72"
                                                          style="fill:#ffffff;fill-opacity:1;fill-rule:evenodd;stroke:none"
                                                          d="m 1842.38,585.551 c -72.5,-30.711 -148.43,-46.02 -227.77,-46.02 -79.38,0 -155.31,15.309 -227.81,46.02 -39.61,16.719 -76.64,37.379 -111.1,61.91 h 43.75 c 24.34,-15.082 49.93,-28.281 76.76,-39.652 69.49,-29.368 142.27,-44.059 218.4,-44.059 76.09,0 148.87,14.691 218.36,44.059 26.8,11.371 52.42,24.57 76.8,39.652 h 43.71 c -34.46,-24.531 -71.49,-45.191 -111.1,-61.91 z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                    <p class="mt-4 slogan-font-size">
                                        Passgenaue Lösungen auch für Ihr Unternehmen
                                    </p>
                                    <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li class="list-inline-item ms-1">
                                            <a href="javascript:void(0)"
                                               class="rounded">
                                                <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                            </a>
                                        </li>
                                        <li class="list-inline-item ms-1">
                                            <a href="javascript:void(0)"
                                               class="rounded">
                                                <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                            </a>
                                        </li>
                                        <li class="list-inline-item ms-1">
                                            <a href="javascript:void(0)"
                                               class="rounded">
                                                <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                            </a>
                                        </li>
                                        <li class="list-inline-item ms-1">
                                            <a href="javascript:void(0)"
                                               class="rounded">
                                                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                            </a>
                                        </li>
                                    </ul>
                                    <!--end icon-->
                                </div>
                                <!--end col-->

                                <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Unternehmen</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <!-- <li>
                                            <a
                                                routerLink="/aboutus"
                                                class="text-foot"
                                                ><i
                                                    class="uil uil-angle-right-b me-1"
                                                ></i>
                                                Über uns</a
                                            >
                                        </li> -->
                                        <li>
                                            <a routerLink="/services" class="text-foot">
                                                <i class="uil uil-angle-right-b me-1"></i>Leistungen
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/contact-details" class="text-foot">
                                                <i class="uil uil-angle-right-b me-1"></i>
                                                Kontakt
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a
                                                routerLink="/team"
                                                class="text-foot"
                                                ><i
                                                    class="uil uil-angle-right-b me-1"
                                                ></i>
                                                Team</a
                                            >
                                        </li>
                                        <li>
                                            <a
                                                routerLink="/pricing"
                                                class="text-foot"
                                                ><i
                                                    class="uil uil-angle-right-b me-1"
                                                ></i>
                                                Preise</a
                                            >
                                        </li> -->
                                    </ul>
                                </div>
                                <!--end col-->

                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">Nützliche Links</h5>
                                    <ul class="list-unstyled footer-list mt-4">
                                        <li>
                                            <a routerLink="/terms" class="text-foot">
                                                <i class="uil uil-angle-right-b me-1"></i>
                                                AGB
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/privacy" class="text-foot">
                                                <i class="uil uil-angle-right-b me-1"></i>
                                                Datenschutz
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/impressum" class="text-foot">
                                                <i class="uil uil-angle-right-b me-1"></i>
                                                Impressum
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/impressum" class="text-foot">
                                                <i class="uil uil-angle-right-b me-1"></i>
                                                Bildquellen
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <!--end col-->

                                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 class="footer-head">
                                        Newsletter
                                    </h5>
                                    <p class="mt-4">
                                        Melden Sie sich an und erhalten Sie die
                                        neuesten Tipps per E-Mail.
                                    </p>
                                    <form [formGroup]="newsForm"
                                          (ngSubmit)="onSubmit()"
                                    >
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="foot-subscribe foot-white mb-3">
                                                    <label class="form-label">
                                                        Schreiben Sie Ihre E-Mail Adresse<span
                                                            class="text-danger">*</span>
                                                    </label>
                                                    <div class="form-icon position-relative">
                                                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                        <input type="email"
                                                               name="email"
                                                               id="emailsubscribe"
                                                               class="form-control ps-5 rounded"
                                                               placeholder="Ihre E-Mail : "
                                                               required
                                                               formControlName="email"
                                                               [ngClass]="{'is-invalid':submitted && form['email'].errors}"
                                                        />
                                                        <div *ngIf="submitted && form['email'].errors"
                                                             class="invalid-feedback"
                                                        >
                                                            <div *ngIf="form[ 'email' ].errors ">
                                                                Bitte E-Mail eingeben.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                            <div class="col-lg-12">
                                                <div class="d-grid">
                                                    <input type="submit"
                                                           id="submitsubscribe"
                                                           name="send"
                                                           class="btn btn-soft-primary not-allowed"
                                                           value="Abonnieren"
                                                           disabled
                                                    />
                                                </div>
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end row-->
                                    </form>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end container-->
            <!--end footer-->
            <div class="footer-py-30 footer-bar">
                <div class="container text-center">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <div class="text-sm-start">
                                <p class="mb-0">
                                    ©
                                    <script type="text/javascript"
                                            id="www-widgetapi-script"
                                            src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                                            async="">
                                    </script>
                                    <script id="iframe_api"
                                            src="https://www.youtube.com/iframe_api">
                                    </script>
                                    {{ year }} BENSER GmbH. Created by
                                    <a href="javascript:void(0);" class="text-reset">Michael Ratke</a>.
                                </p>
                            </div>
                        </div>
                        <!--end col-->
                        <!--
                                                <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                                    <ul class="list-unstyled text-sm-end mb-0">
                                                        <li class="list-inline-item">
                                                            <a href="javascript:void(0)"
                                                                ><img
                                                                    src="assets/images/payments/american-ex.png"
                                                                    class="avatar avatar-ex-sm"
                                                                    title="American Express"
                                                                    alt=""
                                                            /></a>
                                                        </li>
                                                        <li class="list-inline-item ms-1">
                                                            <a href="javascript:void(0)"
                                                                ><img
                                                                    src="assets/images/payments/discover.png"
                                                                    class="avatar avatar-ex-sm"
                                                                    title="Discover"
                                                                    alt=""
                                                            /></a>
                                                        </li>
                                                        <li class="list-inline-item ms-1">
                                                            <a href="javascript:void(0)"
                                                                ><img
                                                                    src="assets/images/payments/master-card.png"
                                                                    class="avatar avatar-ex-sm"
                                                                    title="Master Card"
                                                                    alt=""
                                                            /></a>
                                                        </li>
                                                        <li class="list-inline-item ms-1">
                                                            <a href="javascript:void(0)"
                                                                ><img
                                                                    src="assets/images/payments/paypal.png"
                                                                    class="avatar avatar-ex-sm"
                                                                    title="Paypal"
                                                                    alt=""
                                                            /></a>
                                                        </li>
                                                        <li class="list-inline-item ms-1">
                                                            <a href="javascript:void(0)"
                                                                ><img
                                                                    src="assets/images/payments/visa.png"
                                                                    class="avatar avatar-ex-sm"
                                                                    title="Visa"
                                                                    alt=""
                                                            /></a>
                                                        </li>
                                                    </ul>
                                                </div> -->
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </div>
            <!--end footer-->
        </footer>
    </ng-template>

    <div *ngIf="footerVariant; else execute_block">
        <div *ngIf="footerVariant == 'footer-five'">
            <!-- Footer Start -->
            <footer class="footer">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="footer-py-60 text-center">
                                <div class="row py-5">
                                    <div class="col-md-4">
                                        <div class="card border-0 text-center features feature-primary feature-clean bg-transparent">
                                            <div class="icons text-center mx-auto">
                                                <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                                            </div>
                                            <div class="content mt-4">
                                                <h5 class="footer-head">Telefon</h5>
                                                <p class="text-muted">
                                                    Beginnen Sie die Zusammenarbeit mit der BENSER GmbH GmbH,
                                                    die Ihnen alles bieten kann
                                                </p>
                                                <a href="tel:+4923321718844"
                                                   class="text-foot">+49 (0)2332 1718844</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 text-center features feature-primary feature-clean bg-transparent">
                                            <div class="icons text-center mx-auto">
                                                <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
                                            </div>
                                            <div class="content mt-4">
                                                <h5 class="footer-head">Email</h5>
                                                <p class="text-muted">Schreiben Sie uns eine E-Mail, wenn Sie Fragen
                                                    haben</p>
                                                <a href="mailto:info@benser-gmbh.de"
                                                   class="text-foot">info@benser-gmbh.de</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div class="card border-0 text-center features feature-primary feature-clean bg-transparent">
                                            <div class="icons text-center mx-auto">
                                                <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
                                            </div>
                                            <div class="content mt-4">
                                                <h5 class="footer-head">Standort</h5>
                                                <p class="text-muted">
                                                    Siedlerstr. 26,
                                                    NRW 58285, <br/>Gevelsberg,
                                                    Deutschland
                                                </p>
                                                <a href="https://www.google.de/maps/place/SIB-Brandschutz+GmbH/@51.1758057,10.4541194,6z/data=!4m6!3m5!1s0x86b6569b921e9b43:0xd7ae5954b5a7309c!8m2!3d51.1758057!4d10.4541194!16s%2Fg%2F11t000njjv"
                                                   data-type="iframe"
                                                   class="video-play-icon text-foot lightbox"
                                                >Auf Google-Karte anzeigen</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->

                <div class="footer-py-30 footer-bar bg-footer">
                    <div class="container text-center">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-3 col-md-2 col-sm-3">
                                <div class="text-sm-start">
                                    <a href="javascript:void(0)"
                                       class="logo-footer">
                                        <img src="assets/images/logo-full.png"
                                             height="80"
                                             alt=""
                                        />
                                    </a>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-lg-6 col-md-6 col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <ul class="list-unstyled footer-list terms-service mb-0">
                                    <li class="list-inline-item mb-0">
                                        <a routerLink="/privacy"
                                           href="javascript:void(0)"
                                           class="text-foot me-2">Datenschutz</a>
                                    </li>
                                    <li class="list-inline-item mb-0">
                                        <a routerLink="/terms"
                                           href="javascript:void(0)"
                                           class="text-foot me-2">AGB</a>
                                    </li>
                                    <li class="list-inline-item mb-0">
                                        <a routerLink="/impressum"
                                           href="javascript:void(0)"
                                           class="text-foot me-2">Impressum</a>
                                    </li>
                                    <li class="list-inline-item mb-0">
                                        <a routerLink="/impressum"
                                           href="javascript:void(0)"
                                           class="text-foot me-2">Bildquellen</a>
                                    </li>
                                    <li class="list-inline-item mb-0">
                                        <a routerLink="/contact-details"
                                           href="javascript:void(0)"
                                           class="text-foot">Kontakt</a>
                                    </li>
                                </ul>
                            </div>
                            <!--end col-->

                            <div
                                    class="col-lg-3 col-md-4 col-sm-3 mt-4 mt-sm-0 pt-2 pt-sm-0"
                            >
                                <div class="text-sm-end">
                                    <p class="mb-0 text-foot">
                                        ©
                                        {{ year }}
                                        <a href="javascript:void(0)" class="text-reset">BENSER GmbH</a>.
                                    </p>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end container-->
                </div>
            </footer>
            <!-- Footer End -->
        </div>

        <div *ngIf="footerVariant == 'bg-light'">
            <!-- Footer Start -->
            <footer class="footer bg-light">
                <div class="container">
                    <div class="row footer-py-60">
                        <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                            <a href="javascript:void(0)"
                               class="logo-footer">
                                <img src="assets/images/logo-dark.png" height="24" alt=""/>
                            </a>
                            <p class="mt-4 text-muted">Passgenaue Weiterbildung auch für Ihr Unternehmen</p>
                            <ul class="list-unstyled social-icon social mb-0 mt-4">
                                <li class="list-inline-item">
                                    <a href="javascript:void(0)" class="rounded">
                                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                    </a>
                                </li>
                                <li class="list-inline-item ms-1">
                                    <a href="javascript:void(0)" class="rounded">
                                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                    </a>
                                </li>
                                <li class="list-inline-item ms-1">
                                    <a href="javascript:void(0)" class="rounded">
                                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                    </a>
                                </li>
                                <li class="list-inline-item ms-1">
                                    <a href="javascript:void(0)" class="rounded">
                                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                    </a>
                                </li>
                            </ul>
                            <!--end icon-->
                        </div>
                        <!--end col-->

                        <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 class="text-dark footer-head">Unternehmen</h5>
                            <ul class="list-unstyled footer-list mt-4">
                                <!-- <li>
                                    <a
                                        routerLink="/aboutus"
                                        class="text-muted"
                                        ><i
                                            class="uil uil-angle-right-b me-1"
                                        ></i>
                                        Über uns</a
                                    >
                                </li> -->
                                <li>
                                    <a routerLink="/services" class="text-muted"><i
                                            class="uil uil-angle-right-b me-1"></i>Leistungen</a>
                                </li>
                                <li>
                                    <a routerLink="/contact-details" class="text-foot"><i
                                            class="uil uil-angle-right-b me-1"></i>Kontakt</a>
                                </li>
                                <!-- <li>
                                    <a
                                        routerLink="/team"
                                        class="text-muted"
                                        ><i
                                            class="uil uil-angle-right-b me-1"
                                        ></i>
                                        Team</a
                                    >
                                </li>
                                <li>
                                    <a
                                        routerLink="/pricing"
                                        class="text-muted"
                                        ><i
                                            class="uil uil-angle-right-b me-1"
                                        ></i>
                                        Preise</a
                                    >
                                </li> -->
                            </ul>
                        </div>
                        <!--end col-->

                        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 class="text-dark footer-head">Nützliche Links</h5>
                            <ul class="list-unstyled footer-list mt-4">
                                <li>
                                    <a routerLink="/terms" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>AGB</a>
                                </li>
                                <li>
                                    <a routerLink="/privacy" class="text-muted"><i
                                            class="uil uil-angle-right-b me-1"></i>Datenschutz</a>
                                </li>
                                <li>
                                    <a routerLink="/impressum" class="text-muted"><i
                                            class="uil uil-angle-right-b me-1"></i>Impressum</a>
                                </li>
                            </ul>
                        </div>
                        <!--end col-->

                        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 class="text-dark footer-head">Newsletter</h5>
                            <p class="mt-4 text-muted">
                                Melden Sie sich an und erhalten Sie die neuesten Tipps per E-Mail.
                            </p>
                            <form [formGroup]="newsForm"
                                  (ngSubmit)="onSubmit()"
                            >
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="foot-subscribe foot-white mb-3">
                                            <label class="form-label">
                                                Schreiben Sie Ihre E-Mail Adresse
                                                <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                <input type="email"
                                                       name="email"
                                                       id="emailsubscribe"
                                                       class="form-control ps-5 rounded"
                                                       placeholder="Ihre E-Mail : "
                                                       required
                                                       formControlName="email"
                                                       [ngClass]="{'is-invalid':submitted && form['email'].errors}"
                                                />
                                                <div *ngIf="submitted && form['email'].errors" class="invalid-feedback">
                                                    <div *ngIf="form['email'].errors">
                                                        Bitte E-Mail eingeben.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->
                                    <div class="col-lg-12">
                                        <div class="d-grid">
                                            <input type="submit"
                                                   id="submitsubscribe"
                                                   name="send"
                                                   class="btn btn-primary not-allowed"
                                                   value="Abonnieren"
                                                   disabled
                                            />
                                        </div>
                                    </div>
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </form>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
                <div class="footer-py-30 bg-footer text-white-50 border-top">
                    <div class="container text-center">
                        <div class="row align-items-center">
                            <div class="col-sm-6">
                                <div class="text-sm-start">
                                    <p class="mb-0">
                                        ©
                                        <script type="text/javascript" id="www-widgetapi-script"
                                                src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                                                async=""></script>
                                        <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script>
                                        {{ year }} BENSER GmbH. Created by
                                        <a href="javascript:void(0);" class="text-reset">Michael Ratke</a>.
                                    </p>
                                </div>
                            </div>
                            <!--end col-->
                            <!--
                                                        <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                                            <ul class="list-unstyled text-sm-end mb-0">
                                                                <li class="list-inline-item">
                                                                    <a href="javascript:void(0)"
                                                                        ><img
                                                                            src="assets/images/payments/american-ex.png"
                                                                            class="avatar avatar-ex-sm"
                                                                            title="American Express"
                                                                            alt=""
                                                                    /></a>
                                                                </li>
                                                                <li class="list-inline-item ms-1">
                                                                    <a href="javascript:void(0)"
                                                                        ><img
                                                                            src="assets/images/payments/discover.png"
                                                                            class="avatar avatar-ex-sm"
                                                                            title="Discover"
                                                                            alt=""
                                                                    /></a>
                                                                </li>
                                                                <li class="list-inline-item ms-1">
                                                                    <a href="javascript:void(0)"
                                                                        ><img
                                                                            src="assets/images/payments/master-card.png"
                                                                            class="avatar avatar-ex-sm"
                                                                            title="Master Card"
                                                                            alt=""
                                                                    /></a>
                                                                </li>
                                                                <li class="list-inline-item ms-1">
                                                                    <a href="javascript:void(0)"
                                                                        ><img
                                                                            src="assets/images/payments/paypal.png"
                                                                            class="avatar avatar-ex-sm"
                                                                            title="Paypal"
                                                                            alt=""
                                                                    /></a>
                                                                </li>
                                                                <li class="list-inline-item ms-1">
                                                                    <a href="javascript:void(0)"
                                                                        ><img
                                                                            src="assets/images/payments/visa.png"
                                                                            class="avatar avatar-ex-sm"
                                                                            title="Visa"
                                                                            alt=""
                                                                    /></a>
                                                                </li>
                                                            </ul>
                                                        </div> -->
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end container-->
                </div>
                <!--end footer-->
            </footer>
            <!-- Footer End -->
        </div>
    </div>
</div>
