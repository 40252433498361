import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-master-page',
    templateUrl: './master-page.component.html',
    styleUrls: ['./master-page.component.css'],
})
export class MasterPageComponent implements OnInit {
    addclass: string = '';
    buttonShow: boolean | undefined;
    TopbarShow: boolean | undefined;
    footerClass: string | undefined;
    developerPage: boolean | undefined;
    hideFooter: boolean | undefined;
    shopPages: boolean | undefined;
    Settingicon: boolean | undefined;
    appicons: boolean | undefined;
    Nfticons: boolean | undefined;
    Menuoption: string | undefined;

    constructor() {}

    ngOnInit(): void {}

    /**
     * Router activation
     */
    onActivate(componentReference: any) {
        this.addclass = componentReference.navClass;
        this.buttonShow = componentReference.buttonList;
        this.TopbarShow = componentReference.sliderTopbar;
        this.footerClass = componentReference.footerVariant;
        this.developerPage = componentReference.isdeveloper;
        this.hideFooter = componentReference.hideFooter;
        this.shopPages = componentReference.shopPages;
        this.Settingicon = componentReference.Settingicon;
        this.appicons = componentReference.appicons;
        this.Nfticons = componentReference.Nfticons;
        this.Menuoption = componentReference.Menuoption;
    }
}
