import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-impressum',
  templateUrl: './page-impressum.component.html',
  styleUrls: ['./page-impressum.component.css']
})

/***
 * Page terms Component
 */
export class PageImpressumComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = false;

  constructor() { }

  ngOnInit(): void {
  }

}
